import { Card } from "antd";
import React from "react";

interface Props {
  children?: React.ReactNode;
  type?: "default" | "secondary";
  title?: React.ReactNode | string;
  className?: string;
  loading?: boolean;
}

const CardBox = ({ ...prop }: Props) => {
  if (prop.type === "secondary") {
    return (
      <Card
        style={{ background: "#FAFAFA" }}
        title={prop.title}
        className={prop.className}
        loading={prop.loading}
      >
        {prop.children}
      </Card>
    );
  }
  return (
    <Card title={prop.title} className={prop.className} loading={prop.loading}>
      {prop.children}
    </Card>
  );
};

export default CardBox;
