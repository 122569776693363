import { UndoOutlined } from "@ant-design/icons";
import { ButtonDs, Form, FormDs, InputDs, SelectDs } from "design-system";
import { User } from "../models/user";
import { useEffect } from "react";
import UserManagementService from "../services/user-management-service";
import { Col, Row } from "antd";
import LocalstorageService from "../../../shared/service/Localstorage.service";

interface Props {
  dataSource: User[] | undefined;
  isLoading: boolean;
  onSearch: (values: User[]) => void;
  onReset: () => void;
}

const FormSearch = ({ dataSource, isLoading, onSearch, onReset }: Props) => {
  const [form] = Form.useForm();
  const currentCompany = LocalstorageService.getCompany();

  useEffect(() => {
    UserManagementService.signalReset.subscribe((signal) => {
      if (signal) {
        form.resetFields();
      }
    });

    return () => {
      UserManagementService.signalReset.next(false);
    };
  }, []);

  const onFinish = (values: any) => {
    const email: string = values.email;
    const role = values.role;
    const status = values.status;
    if (dataSource) {
      let userList = dataSource.map((user) => user);
      if (email && email.length > 0) {
        userList = userList.filter((user) => {
          const fullName = user.firstname + " " + user.lastname;
          return (
            user.email.toLocaleLowerCase().indexOf(email.toLocaleLowerCase()) >= 0 ||
            fullName.toLocaleLowerCase().indexOf(email.toLocaleLowerCase()) >= 0
          );
        });
      }
      if (role && role !== "all") {
        userList = userList.filter((user) => {
          return user.role === role;
        });
      }
      if (status && status !== "all") {
        userList = userList.filter((user) => {
          return user.status.toLowerCase() === status.toLowerCase();
        });
      }
      onSearch(userList);
    }
  };

  const status = [
    {
      label: "ทั้งหมด",
      value: "all",
    },
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
    {
      label: "Unverified",
      value: "Unverified",
    },
  ];

  return (
    <FormDs
      style={{ display: "flex" }}
      form={form}
      initialValues={{ role: "all", status: "all" }}
      onFinish={onFinish}
      autoComplete="off"
      requiredMark={false}
    >
      <Row gutter={16}>
        <Col>
          <Form.Item label="ค้นหา" name="email">
            <InputDs placeholder="ค้นหาจาก ชื่อ, Email" />
          </Form.Item>
        </Col>
        {currentCompany !== "TDM" && (
          <Col>
            <Form.Item label="สิทธิการเข้าถึง" name="role">
              <SelectDs
                options={[{ label: "ทั้งหมด", value: "all" }, ...UserManagementService.roles]}
                style={{ width: 200 }}
              />
            </Form.Item>
          </Col>
        )}
        <Col>
          <Form.Item label="สถานะ" name="status">
            <SelectDs options={status} style={{ width: 200 }} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label={<>&nbsp;</>}>
            <ButtonDs type="primary" htmlType="submit" disabled={isLoading}>
              ค้นหา
            </ButtonDs>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label={<>&nbsp;</>}>
            <ButtonDs
              type="default"
              disabled={isLoading}
              onClick={() => {
                form.resetFields();
                onReset();
              }}
            >
              <UndoOutlined />
              รีเซ็ต
            </ButtonDs>
          </Form.Item>
        </Col>
      </Row>
    </FormDs>
  );
};

export default FormSearch;
