import "./style.scss";
import { Content } from "antd/es/layout/layout";
import { useEffect, useRef, useState } from "react";
import "@tomtom-international/web-sdk-maps/dist/maps.css";
import mapSDK from "@tomtom-international/web-sdk-maps";
import TomtomMapComponent from "../../shared/component/MapComponent/TomtomMapComponent";
import ApiClient, { httpClientTD } from "../../shared/api/ApiClient";
import { SearchParams } from "../TruckMonitor/Slice";
import { GET_DC_INFORMATION, GET_ETA, SEARCH_DRIVER } from "../../shared/api/ApiEndPoint";
import { FormatDate, GetDateTime, GetTimestamp } from "../../utils/GetDateNow";
import * as turf from "@turf/turf";
import { Alert, Button, Card, Col, Collapse, Form, Select, Spin, Image, Tooltip } from "antd";
import { dcLists } from "../../utils/DClists";
import { useForm } from "antd/es/form/Form";
import { renderToStaticMarkup } from "react-dom/server";
import { CloseOutlined, DownCircleOutlined, InfoCircleOutlined, UpCircleOutlined } from "@ant-design/icons";
import { Driver } from "../TruckMonitor/Driver";
import InputRadius from "./components/InputRadius";

import activeTruckMarker from "../../assets/images/icon/active_truck_marker.svg";
import inactiveTruckMarker from "../../assets/images/icon/inactive_truck_marker.svg";
import remainningTaskActiveMarker from "../../assets/images/icon/remainning_task_active_marker.svg";
import remainningTaskInactiveMarker from "../../assets/images/icon/remainning_task_inactive_marker.svg";
import warehouseMarker from "../../assets/images/map/warehouse.svg";
import SpinComponent from "../../shared/component/Spinner";

const { Option } = Select;

type DCInfo = {
  dc_code: string;
  location: {
    lat: string;
    long: string;
  };
};

export default function ReturnEta() {
  const tomTomApiKey = process.env.REACT_APP_TOMTOM_API_KEY as string;
  const mapContainer = useRef<HTMLDivElement>(null);
  const tomtomMap = useRef<mapSDK.Map>({} as mapSDK.Map);
  const markers = useRef<Map<string, mapSDK.Marker>>(new Map());
  const layer = useRef<Map<string, mapSDK.AnyLayer>>(new Map());

  const [form] = useForm();
  const [dcLocations, setDcLocations] = useState({} as any);
  const [dc, setDC] = useState<DCInfo>({} as DCInfo);
  const selectedDriver = useRef<Driver>({} as Driver);
  const selectedMarker = useRef<any>(undefined);
  const [eta, setEta] = useState<string>("");

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isLoadingEta, setIsLoadingEta] = useState<boolean>(false);
  const [isLoadingMarker, setIsLoadingMarker] = useState<boolean>(false);
  const [radius, setRadius] = useState<number>(10);
  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(false);

  useEffect(() => {
    renderMap();
    getDCInformation()
      .then((res) => {
        setDcLocations(res);
      })
      .catch(() => {
        setDcLocations({});
      });
  }, []);

  useEffect(() => {
    if (dc.dc_code && dc.location) {
      setDCMarker(dc.dc_code, "dc-marker", dc.location.lat, dc.location.long);
      addCircleLayer(tomtomMap.current, radius, dc.location.lat, dc.location.long, dc.dc_code);
    }
  }, [dc]);

  useEffect(() => {
    if (dc.dc_code && dc.location) {
      addCircleLayer(tomtomMap.current, radius, dc.location.lat, dc.location.long, dc.dc_code);
    }
  }, [radius]);

  const renderMap = () => {
    const renderMap = mapSDK.map({
      key: tomTomApiKey as string,
      container: mapContainer.current as HTMLDivElement,
      center: [100.523186, 13.736717],
      zoom: 4.8,
    });

    tomtomMap.current = renderMap;
  };

  const addCircleLayer = (map: mapSDK.Map, radius: number, lat: string, long: string, dc_code: string) => {
    if (layer.current.size > 0) {
      layer.current.forEach((layer) => {
        tomtomMap.current.removeLayer(layer.id);
      });
      layer.current.clear();
    }

    const sourceID = `circleData ${Math.floor(Math.random() * 10000)}`;
    const pointCenter = turf.point([Number(long), Number(lat)]);
    const circle = turf.buffer(pointCenter, radius);
    const layerKey = `layer_${radius}_${dc_code}`;

    map.addSource(sourceID, {
      type: "geojson",
      data: circle,
    });

    const layerElement: mapSDK.AnyLayer = {
      id: layerKey,
      type: "fill",
      source: sourceID,
      paint: {
        "fill-color": "green",
        "fill-opacity": 0.1,
        "fill-outline-color": "green",
      },
    };

    map.addLayer(layerElement);

    const llb = new mapSDK.LngLatBounds();
    const allPoints = circle.geometry;
    allPoints.coordinates[0].forEach((point: any) => {
      llb.extend(point);
    });
    tomtomMap.current.fitBounds(llb, {
      padding: 50,
      maxZoom: 12,
      animate: true,
    });

    if (!layer.current.has(layerKey)) {
      layer.current.set(layerKey, layerElement);
    }
  };

  const setDCMarker = (key: string, markerId: string, lat: string, long: string) => {
    const markerMap = new Map<string, mapSDK.Marker>();

    const markerElement = document.createElement("div");
    markerElement.id = markerId;

    const marker = new mapSDK.Marker({
      element: markerElement,
      anchor: "center",
    });

    marker.setLngLat([Number(long), Number(lat)]).addTo(tomtomMap.current);
    markerMap.set(key, marker);

    if (!markers.current.has(key)) {
      markers.current.set(key, marker);
    }
  };

  const setMarker = (key: string, drivers: Driver[], dc: DCInfo) => {
    const markerMap = new Map<string, mapSDK.Marker>();
    const lastTrip = drivers[drivers.length - 1];

    const markerId = lastTrip.status ? "active-truck-marker" : "inactive-truck-marker";
    const completeTask = lastTrip.completed_job === lastTrip.total_job;
    const lat = lastTrip.location.lat;
    const long = lastTrip.location.long;

    const markerElement = document.createElement("div");
    if (completeTask) {
      markerElement.id = markerId;
    } else {
      const staticElement = renderToStaticMarkup(RenderTruckMarker(lastTrip.location.heading));
      markerElement.id = markerId;
      markerElement.innerHTML = staticElement;
    }
    markerElement.addEventListener("click", () => {
      selectedDriver.current = lastTrip;
      onTogglePopup(lastTrip, dc);
    });

    const marker = new mapSDK.Marker({
      element: markerElement,
      rotation: lastTrip.location.heading,
      anchor: "center",
    });

    marker.setLngLat([Number(long), Number(lat)]).addTo(tomtomMap.current);
    markerMap.set(key, marker);

    if (!markers.current.has(key)) {
      markers.current.set(key, marker);
    }
  };

  const setMapCenter = (long: string, lat: string) => {
    const map = tomtomMap.current as any;
    map.flyTo({
      center: [long, lat],
      animate: true,
    });
  };

  const onSubmit = async (value: any) => {
    // Clear state
    setIsLoadingMarker(true);
    deleteMarkerAndLayer();
    clearStatePopup();
    if (selectedMarker.current) {
      selectedMarker.current.getElement().classList.remove("active");
      selectedMarker.current = undefined;
    }

    const searchParam: SearchParams = {
      dc_code: value.dc,
      load: "",
      sla: "",
      truck_type: value.truckType === "all" ? "" : value.truckType,
      truck_plate_number: "",
      sync_status: "",
      store_number: "",
      do_type_list: [],
      planned_arrival: GetTimestamp(),
      task_progress: "potential_truck_turn",
      test_search_error: null,
      current_time: GetTimestamp(),
    };
    const selectedDC = value.dc;
    const dc: DCInfo = {
      dc_code: selectedDC,
      location: {
        lat: dcLocations[selectedDC].Lat,
        long: dcLocations[selectedDC].Long,
      },
    };
    setDC(dc);

    await searchTruckTurn(searchParam).then((data) => {
      if (data.length > 0) {
        const allDrivers = groupingDriver(data, data);

        if (allDrivers.size > 0) {
          allDrivers.forEach((driver, key) => {
            const lastTrip = driver[driver.length - 1];

            if (lastTrip.location.long && lastTrip.location.lat) {
              setMarker(key, driver, dc);
            }
          });
        }
      }
    });
    setIsLoadingMarker(false);
  };

  const deleteMarkerAndLayer = () => {
    if (markers.current.size > 0) {
      markers.current.forEach((marker) => {
        marker.remove();
      });
      markers.current.clear();
    }
    if (layer.current.size > 0) {
      layer.current.forEach((layer) => {
        tomtomMap.current.removeLayer(layer.id);
      });
      layer.current.clear();
    }
  };

  const groupingDriver = (filteredList: Driver[], list: Driver[]): Map<string, Driver[]> => {
    if (filteredList.length !== 0 && list.length !== 0) {
      const completeList = new Map<string, Driver[]>(
        filteredList.map((driver) => [
          driver.truck_plate_number,
          list.filter((a) => driver.truck_plate_number === a.truck_plate_number),
        ]),
      );

      return completeList;
    } else {
      return new Map<string, Driver[]>();
    }
  };

  const RenderTruckMarker = (heading: number) => {
    return (
      <div className="truck-turn-marker" style={{ rotate: heading < 0 ? heading + "deg" : -heading + "deg" }}>
        <span className="stop">{1}</span>
      </div>
    );
  };

  const clearStatePopup = () => {
    setEta("");
    if (selectedMarker.current) {
      selectedMarker.current.getElement().classList.remove("active");
    }
    if (isPopupOpen === true) {
      setIsPopupOpen(false);
    }
  };

  const onTogglePopup = (lastTrip: Driver, dc: DCInfo) => {
    if (selectedMarker.current) {
      selectedMarker.current.getElement().classList.remove("active");
    }

    setIsPopupOpen(true);
    setMapCenter(lastTrip.location.long.toString(), lastTrip.location.lat.toString());
    callApiGetEta(lastTrip, dc);

    const markerKey = lastTrip.truck_plate_number;
    const marker = markers.current.get(markerKey);

    if (marker) {
      marker.getElement().classList.add("active");
      selectedMarker.current = marker;
    }
  };

  const callApiGetEta = async (selectedDriver: Driver, dc: DCInfo) => {
    setIsLoadingEta(true);
    await getETAInformation(
      `${selectedDriver.location.lat},${selectedDriver.location.long}`,
      `${dc.location.lat},${dc.location.long}`,
    )
      .then((res) => {
        setEta(res.eta);
      })
      .catch(() => {
        setEta("");
      })
      .finally(() => {
        setIsLoadingEta(false);
      });
  };

  const driverPopupInfo = (driverInfo: Driver, eta: string) => {
    const remaining_task: number = Number(driverInfo?.total_job) - Number(driverInfo?.completed_job);
    const truckDetailInfo = (info: Driver) => [
      {
        title: "ทะเบียนรถ",
        content: info?.truck_plate_number,
      },
      {
        title: "ประเภทรถ",
        content: info.truck_type,
      },
      {
        title: "เบอร์โทร",
        content: info.driver_mobile_number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
      },
    ];
    const etaDetailInfo = () => [
      {
        title: "เวลาที่กลับมาถึงคลัง",
        child: eta ? FormatDate(eta, "DD-MM-YYYY, HH:mm") : "N/A",
      },
      {
        title: "จำนวนร้านคงเหลือ",
        content: `${remaining_task.toString()} ร้าน`,
      },
    ];

    return (
      <div className="marker-popup">
        <CloseOutlined
          style={{ display: "flex", justifyContent: "end" }}
          onClick={() => {
            selectedDriver.current = {} as Driver;
            setIsPopupOpen(false);
            if (selectedMarker.current) {
              selectedMarker.current.getElement().classList.remove("active");
            }
          }}
        />
        <Spin spinning={isLoadingEta}>
          <div className="return-eta-marker-popup-info-card" data-testid="marker-popup-transport-detail">
            <div className="return-eta-info-sync-status">
              <p className="return-eta-detail-title">Sync Status</p>
              <div className="return-eta-info-sync-status-detail">
                <span
                  className={`popover-status-badge ${driverInfo.status ? "complete" : "incomplete"}`}
                ></span>
                <p>{driverInfo.status ? "Active" : "Inactive"}</p>
                &nbsp;
                <span>
                  <p className="return-eta-last-update-time">
                    {`(Last Update
                  ${
                    driverInfo.last_location_timestamp
                      ? GetDateTime(driverInfo.last_location_timestamp)
                      : "N/A"
                  })`}
                  </p>
                </span>
              </div>
            </div>
            {detailSection("Return ETA", etaDetailInfo())}
            {detailSection("รายละเอียดรถ", truckDetailInfo(driverInfo))}
          </div>
        </Spin>
      </div>
    );
  };
  const detailSection = (
    title: string,
    info: {
      title: string;
      content?: string;
      child?: React.ReactNode;
    }[],
  ) => {
    return (
      <div className="marker-popup-detail-section">
        <p className="detail-title">{title}</p>
        <div className="marker-popup-detail-section-info">
          {info.map((x, index) => (
            <div className="detail-section-info" key={index}>
              <p>{x.title} :&nbsp;</p>
              <p>{x.content ?? x.content}</p>
              {x.child ?? x.child}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const tutorial: MapTutorial[] = [
    {
      picture: [warehouseMarker],
      content: `คลังสินค้า`,
    },
    {
      picture: [activeTruckMarker],
      content: `สถานะรถ Active`,
    },
    {
      picture: [inactiveTruckMarker],
      content: `สถานะรถ Inactive`,
    },
    {
      picture: [remainningTaskInactiveMarker, remainningTaskActiveMarker],
      content: `สถานะคงเหลือจัดส่ง 1 ร้าน`,
    },
    {
      picture: [inactiveTruckMarker, activeTruckMarker],
      content: `สถานะจัดส่งเสร็จแล้ว`,
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <span className="map-tutorial-collapse-title">
          {isCollapseOpen ? `สัญลักษณ์บนแผนที่` : `วิธีการดูแผนที่`}
        </span>
      ),
      children: (
        <div>
          <ul>
            {tutorial.map((x, index) => (
              <li key={index}>
                <div className="map-tutorial" key={index}>
                  <div className="map-tutorial-picture">
                    {x.picture.map((x, index) => (
                      <Image height={30} src={x} key={index} preview={false} />
                    ))}
                  </div>

                  <p className="map-tutorial-content">{x.content}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ),
    },
  ];

  const tooltipTitle = "ค้นหารถที่มีโอกาสกลับมารับ-ส่งสินค้าที่คลังได้รวดเร็วที่สุด";

  return (
    <Content className="management-content">
      <div className="return-eta-title-section">
        <p className="page-title">Return ETA</p>
        <Tooltip title={tooltipTitle} placement="right" color="#fff" overlayInnerStyle={{ color: "#000" }}>
          <InfoCircleOutlined style={{ marginLeft: "8px", color: "#757575" }} />
        </Tooltip>
      </div>
      <Col>
        <Card className="filter-card" style={{ padding: "16px" }}>
          <div className="filter-return-eta">
            <Form form={form} layout="vertical" onFinish={onSubmit}>
              <Form.Item
                className="form-item"
                label="DC"
                name="dc"
                rules={[
                  {
                    required: true,
                    message: "เลือกศูนย์กระจายสินค้า",
                  },
                ]}
              >
                <Select
                  style={{
                    width: 220,
                  }}
                  showSearch
                  placeholder="เลือกศูนย์กระจายสินค้า"
                  optionFilterProp="children"
                  data-testid="select-dc"
                >
                  {dcLists().map((x) => (
                    <Select.Option key={x.value} value={x.value}>
                      {x.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="ประเภทรถ"
                name="truckType"
                className="form-item"
                rules={[{ required: true, message: "กรุณาเลือกประเภทรถ" }]}
              >
                <Select
                  showSearch
                  style={{ width: 220 }}
                  placeholder="เลือกประเภทรถ"
                  data-testid="search-truck_type"
                >
                  <Option value="all">ทั้งหมด</Option>
                  <Option value="10W">10W</Option>
                  <Option value="10WT">10WT</Option>
                  <Option value="6W">6W</Option>
                  <Option value="6WT">6WT</Option>
                  <Option value="4WCR">4WCR</Option>
                  <Option value="4WJ">4WJ</Option>
                  <Option value="4W">4W</Option>
                </Select>
              </Form.Item>
              <Form.Item className="form-item" label=" ">
                <Button type="primary" htmlType="submit" data-testid="search-button">
                  ค้นหา
                </Button>
              </Form.Item>
            </Form>
            <InputRadius onChange={setRadius} />
          </div>
          <SpinComponent
            spinning={isLoadingMarker}
            children={
              <div className="return-eta-map">
                <TomtomMapComponent
                  apiKey={tomTomApiKey}
                  mapContainer={mapContainer}
                  children={
                    isPopupOpen ? (
                      <>{selectedDriver && driverPopupInfo(selectedDriver.current, eta)}</>
                    ) : (
                      <></>
                    )
                  }
                />
              </div>
            }
          />
          <Alert className="map-remark-message" message="รถที่ไม่ได้ Sync จะไม่ได้แสดงบนแผนที่" showIcon />
          <div className="return-eta-map-tutorial-collapse">
            <Collapse
              className="map-tutorial-collapse"
              onChange={(key) => {
                const istoggleActive = key.length > 0;
                setIsCollapseOpen(istoggleActive);
              }}
              ghost
              items={items}
              expandIconPosition="end"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <UpCircleOutlined style={{ fontSize: "22px" }} data-testid="collapse-icon" />
                ) : (
                  <DownCircleOutlined style={{ fontSize: "22px" }} data-testid="collapse-icon" />
                )
              }
            />
          </div>
        </Card>
      </Col>
    </Content>
  );
}

export const searchTruckTurn = async (params: SearchParams): Promise<Driver[]> => {
  const { data } = await ApiClient.post(SEARCH_DRIVER, params);
  return data;
};

export const getDCInformation = async (): Promise<any> => {
  const { data } = await httpClientTD.get(GET_DC_INFORMATION);
  return data;
};

export const getETAInformation = async (from: string, to: string): Promise<any> => {
  const { data } = await ApiClient.get(GET_ETA, { params: { from: from, to: to } });
  return data;
};

type MapTutorial = {
  picture: string[];
  content: string;
};
