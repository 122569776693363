import { Button, Card, Input, message, notification, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import "./style.scss";
import { CopyOutlined } from "@ant-design/icons";
import { NotificationComponent } from "../../shared/component/NotificationComponent";
import { useEffect, useState } from "react";
import ApiClient from "../../shared/api/ApiClient";

const AgentAccessToken = () => {
  const [notificationComponent, contextHolderNoti] = notification.useNotification();
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [tokenInput, setTokenInput] = useState("");

  useEffect(() => {
    getAccessToken();
  }, []);

  const getAccessToken = async () => {
    try {
      const response = await ApiClient.post("/v2/agent/token");
      setTokenInput(response.data.token);
    } catch (err: any) {
      const errMessage = err.response?.data.message ? err.response?.data.message : err.message;
      message.error(`เกิดข้อผิดพลาด:${errMessage}`);
    }
  };

  const diaplayNotification = (message: string, topic?: string) => {
    return NotificationComponent({
      notification: notificationComponent,
      type: "success",
      topic: topic,
      message: message,
    });
  };
  const copyToClipBoard = async (responseText: string) => {
    await navigator.clipboard
      .writeText(responseText)
      .then(() => {
        diaplayNotification(`คัดลอกสำเร็จ`);
      })
      .catch(() => {
        messageApi.error({
          className: "store-master-message",
          content: "คัดลอกไม่สำเร็จ",
        });
      });
  };
  return (
    <Content className="access-token-content">
      <Card className="access-token-card">
        <h3>Your Access Token</h3>
        <Space.Compact style={{ width: "100%" }}>
          <Input.Password
            readOnly={true}
            placeholder="Your Access Token"
            value={tokenInput}
            onChange={(e) => setTokenInput(e.target.value)}
          />
          <Button icon={<CopyOutlined />} onClick={() => copyToClipBoard(tokenInput)}></Button>
        </Space.Compact>
      </Card>
      {contextHolderNoti}
      {contextHolderMessage}
    </Content>
  );
};

export default AgentAccessToken;
