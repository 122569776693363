import { CheckCircleOutlined, FileDoneOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Card, Col, Row, Space, notification } from "antd";
import Modal from "antd/es/modal/Modal";
import { ButtonDs, Form, FormDs, InputDs, SelectDs } from "design-system";
import { useEffect, useState } from "react";
import { CreateUserParam } from "../models/user";
import ConfirmModal from "./ConfirmModal";
import UserManagementService from "../services/user-management-service";
import { User } from "../models/user";
import ModalPermissionDetail from "./ModalPermissionDetail";
import LocalstorageService from "../../../shared/service/Localstorage.service";
import AlertMessage from "../../../shared/component/AlertMessage";

interface Props {
  open: boolean;
  onOk: (data: CreateUserParam) => void;
  onCancel: () => void;
  datasource: User[];
}

const ModalCreateUser = ({ open, onCancel, onOk, datasource }: Props) => {
  const [form] = Form.useForm();
  const currentCompany = LocalstorageService.getCompany();
  const [notificationComponent, contextHolderNoti] = notification.useNotification();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] = useState(false);
  const [createUserParam, setCreateUserParam] = useState<CreateUserParam | null>(null);
  const [alertMessageOpen, setAlertMessageOpen] = useState<number>(0);
  const [listUserEmail, setListUserEmail] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalPermissionDetailOpen, setIsModalPermissionDetailOpen] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("E-mail ของบริษัทเท่านั้น");

  useEffect(() => {
    if (datasource.length > 0) {
      const list = datasource.map((user) => user.email);
      setListUserEmail(list);
    }
  }, [datasource]);

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  const onClickCancle = () => {
    setIsConfirmCancelModalOpen(true);
  };

  const onClickConfirmCancel = () => {
    setIsConfirmCancelModalOpen(false);
  };

  const onClickConfirmOK = () => {
    setAlertMessageOpen(0);
    setIsConfirmCancelModalOpen(false);
    onCancel();
  };

  const onClickConfirmCreateCancel = () => {
    setIsConfirmModalOpen(false);
  };

  const onClickConfirmCreateOK = () => {
    if (createUserParam) {
      setIsLoading(true);
      UserManagementService.createUser(createUserParam)
        .then(() => {
          setAlertMessageOpen(0);
          onClickConfirmCreateCancel();
          onCancel();
          openNotiSuccess();
          setIsLoading(false);
          form.resetFields();
          onOk(createUserParam);
        })
        .catch(() => {
          setAlertMessageOpen(1);
          setIsLoading(false);
        });
    }
  };

  const openNotiSuccess = () => {
    notificationComponent.success({
      message: "เพิ่ม User สำเร็จ",
      description: "เพิ่ม User ใหม่สำเร็จ กรุณาแจ้งให้ผู้ใช้งานทำการตรวจสอบ E-mail เพื่อกำหนดรหัสผ่าน",
      className: "success-notification",
      icon: <CheckCircleOutlined style={{ color: "#41A447" }} />,
      placement: "topRight",
    });
  };

  const onClickSubmit = () => {
    form
      .validateFields()
      .then(() => {
        const firstname = form.getFieldValue("firstname").trim();
        const lastname = form.getFieldValue("lastname").trim();
        const email = form.getFieldValue("email").trim();
        const role = form.getFieldValue("role");

        setAlertMessageOpen(0);
        const param: CreateUserParam = {
          firstname: firstname,
          lastname: lastname,
          email: email,
          role: role,
        };
        setCreateUserParam(param);
        setIsConfirmModalOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const duplicateEmailValidator = () => {
    const email = form.getFieldValue("email");
    if (listUserEmail.includes(email)) {
      setEmailHelperText("E-mail นี้เคยลงทะเบียนแล้ว");
      return Promise.reject("E-mail นี้เคยลงทะเบียนแล้ว");
    }

    setEmailHelperText("E-mail ของบริษัทเท่านั้น");
    return Promise.resolve();
  };

  return (
    <>
      <Modal
        centered
        className="modal-create-user"
        open={open}
        onCancel={onClickCancle}
        title="เพิ่มผู้ใช้งาน"
        width={950}
        closeIcon
        footer={
          <>
            <ButtonDs type="default" onClick={onClickCancle}>
              ยกเลิก
            </ButtonDs>
            <ButtonDs htmlType="submit" type="primary" onClick={onClickSubmit}>
              ยืนยัน
            </ButtonDs>
          </>
        }
      >
        {alertMessageOpen === 1 && (
          <AlertMessage
            type="error"
            message="เกิดข้อผิดพลาด"
            description={`เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง`}
            margin="0 0 8px 0"
          />
        )}
        <FormDs form={form} autoComplete="off" requiredMark={true}>
          <Card style={{ padding: "16px" }}>
            <h3>ข้อมูลทั่วไป</h3>
            <Row>
              <Col span={24} className="col-form-email">
                <Form.Item
                  required
                  name={"email"}
                  help={emailHelperText}
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "กรุณากรอก E-mail ของบริษัท",
                    },
                    { validator: duplicateEmailValidator },
                  ]}
                >
                  <InputDs label="Email" placeholder="กรุณากรอก Email"></InputDs>
                </Form.Item>
              </Col>
              <Col span={24} className="col-form-name">
                <Form.Item
                  name={"firstname"}
                  style={{ margin: "0" }}
                  required
                  help="ระบุชื่อ(ภาษาอังกฤษ), ไม่มีตัวเลขหรืออักขระพิเศษ"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp(/^[a-zA-Z]+$/i),
                    },
                  ]}
                >
                  <InputDs label="ชื่อ" placeholder="กรุณากรอกชื่อ"></InputDs>
                </Form.Item>
                <Form.Item
                  required
                  name={"lastname"}
                  className="form-lastname"
                  help="ระบุนามสกุล(ภาษาอังกฤษ), ไม่มีตัวเลขหรืออักขระพิเศษ"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp(/^[a-zA-Z]+$/i),
                    },
                  ]}
                >
                  <InputDs label="นามสกุล" placeholder="กรุณากรอกนามสกุล"></InputDs>
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card className="card-section-user-role" style={{ padding: "16px" }}>
            <div className="section-user-role">
              <h3>สิทธิ์และการเข้าถึง</h3>
              {currentCompany !== "TDM" && (
                <a onClick={() => setIsModalPermissionDetailOpen(true)}>
                  <Space>
                    <FileDoneOutlined />
                    ดูสิทธิ์ทั้งหมด
                  </Space>
                </a>
              )}
            </div>
            <Row gutter={16}>
              <Col span={12}>
                <div className="section-permission">
                  <Form.Item
                    name="role"
                    label="สิทธิการเข้าถึง"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    style={{ margin: "0" }}
                  >
                    <SelectDs
                      options={
                        currentCompany === "TDM"
                          ? [
                              {
                                label: "Admin",
                                value: "admin",
                              },
                            ]
                          : UserManagementService.roles
                      }
                      style={{ width: 328 }}
                      placeholder="กรุณาเลือก"
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Card>
        </FormDs>
      </Modal>
      <ConfirmModal
        open={isConfirmCancelModalOpen}
        title={
          <Space>
            <QuestionCircleOutlined /> ออกจากหน้านี้หรือไม่ ?
          </Space>
        }
        onClickConfirmCancel={onClickConfirmCancel}
        onClickConfirmOK={onClickConfirmOK}
      >
        <p>หากออกจากหน้านี้ ข้อมูลทั้งหมดจะไม่ถูกบันทึก ต้องการออกจากหน้านี้หรือไม่ ?</p>
      </ConfirmModal>
      <ConfirmModal
        open={isConfirmModalOpen}
        title={
          <Space>
            <QuestionCircleOutlined /> ยืนยันการสร้างบัญชีผู้ใช้
          </Space>
        }
        onClickConfirmCancel={onClickConfirmCreateCancel}
        onClickConfirmOK={onClickConfirmCreateOK}
        loading={isLoading}
      >
        {createUserParam && (
          <div className="confirm-create-user-modal-content">
            <p>
              ยืนยันการสร้างบัญชีสำหรับ {createUserParam.firstname} {createUserParam.lastname}
            </p>
            <p>อีเมล : {createUserParam.email}</p>
            <p>
              สิทธิ์การเข้าถึง :{" "}
              <span style={{ textTransform: "capitalize" }}>{createUserParam.role.replace("_", " ")}</span> (
              {currentCompany})
            </p>
          </div>
        )}
      </ConfirmModal>
      {contextHolderNoti}
      <ModalPermissionDetail
        open={isModalPermissionDetailOpen}
        onCancel={() => setIsModalPermissionDetailOpen(false)}
      />
    </>
  );
};

export default ModalCreateUser;
