import { Divider } from "antd";
import { FleetTypes, Task } from "../../../feature/TruckMonitor/Driver";

const detailTaskTopic = ["D+0", "D+1"];
const detailFleetTopic = ["10W", "10WT", "6W", "6WT", "4WCR", "4WJ", "4W"];

function PopoverDetailSection(topic: string[], list: any) {
  return (
    <>
      {topic.map((topic) => (
        <div key={topic} className="popover-detail-section">
          <p className="popover-detail-section-title">{topic} :</p>
          <p className="popover-detail-section-content">
            {list[topic] || list[topic] === 0 ? list[topic].toString() : "-"}
          </p>
        </div>
      ))}
    </>
  );
}

// For total trip widget dashboard only
function PopoverDetail(task: Task | undefined, fleet_type: FleetTypes | undefined, totalTask: number) {
  return (
    <div className="wrap-popover-content" data-testid="popover-content">
      <div className="popover-first-column">
        <p>Tasks</p>
        {PopoverDetailSection(["All Tasks"], {
          "All Tasks": totalTask,
        })}
        {PopoverDetailSection(detailTaskTopic, task ?? {})}
      </div>
      <Divider type="vertical" style={{ height: "auto" }} />
      <div className="popover-second-column">
        <p>Fleet Types</p>
        {PopoverDetailSection(detailFleetTopic, fleet_type ?? {})}
      </div>
    </div>
  );
}

export { PopoverDetail };
