import { Card, Col, Layout, Row, Tooltip } from "antd";
import DesignSystemTheme from "../../shared/component/DesignSystemTheme";
import "./style.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import FormRequest from "./components/FormRequest";
import FormFilter from "./components/FormFilter";
import DataTable from "./components/DataTable";
import ReturnEtaRequestService from "./services/return-eta-request-service";
import { useEffect, useState } from "react";
import { ReturnEtaDocument } from "./models/return-eta-request.model";

const { Content } = Layout;

const ReturnEtaRequest = () => {
  const [dataSource, setDataSource] = useState<ReturnEtaDocument[]>([]);
  const [dataFilter, setDataFilter] = useState<ReturnEtaDocument[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getDataReturnEtas();

    const interval = setInterval(() => {
      getDataReturnEtas();
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getDataReturnEtas = () => {
    setIsLoading(true);
    ReturnEtaRequestService.getReturnEtas().then((res) => {
      setDataSource(res);
      setIsLoading(false);
    });
  };

  return (
    <DesignSystemTheme>
      <Content className="return-eta-request-content">
        <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
          <h1>Return ETA</h1>
          <Tooltip
            title={`ค้นหารถที่มีโอกาสกลับมารับ-ส่งสินค้าที่คลังได้รวดเร็วที่สุด`}
            placement="right"
            color="#fff"
            overlayInnerStyle={{ color: "#000" }}
          >
            <InfoCircleOutlined style={{ marginLeft: "8px", color: "#757575" }} />
          </Tooltip>
        </div>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card>
              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <h3>Request File</h3>
                </Col>
                <Col span={24}>
                  <FormRequest
                    onRequest={() => {
                      console.log("reload");
                      getDataReturnEtas();
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24}>
            <Card>
              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <h3>รายการคำขอข้อมูล</h3>
                </Col>
                <Col span={24}>
                  <FormFilter
                    dataSource={dataSource}
                    onSearch={(data) => {
                      setIsLoading(true);
                      setTimeout(() => {
                        setDataFilter(data);
                        setIsLoading(false);
                      }, 500);
                    }}
                  />
                </Col>
                <Col span={24}>
                  <DataTable dataSource={dataFilter} isLoading={isLoading} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </DesignSystemTheme>
  );
};

export default ReturnEtaRequest;
