import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { ArgsProps, NotificationInstance } from "antd/es/notification/interface";

interface Props {
  notification: NotificationInstance;
  type: "success" | "error";
  topic?: string;
  message?: string;
}

export const NotificationComponent = ({ notification, type, message, topic }: Props) => {
  const notificationConfig: ArgsProps = {
    message: topic && <h4>{topic}</h4>,
    description: message && <p>{message}</p>,
    className: `notification ${type}`,
    icon:
      type === "error" ? (
        <CloseCircleOutlined className={`notification icon`} />
      ) : (
        <CheckCircleOutlined className={`notification icon`} />
      ),
    placement: "topRight",
    closeIcon: false,
  };

  return type === "success"
    ? notification.success(notificationConfig)
    : notification.error(notificationConfig);
};
