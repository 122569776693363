import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";

interface Props {
  dropdownItems: MenuItems[];
}

interface MenuItems {
  key: string;
  label: string;
  disabled?: boolean;
  onClick?: () => void;
}

const DropdownMenu = ({ dropdownItems }: Props) => {
  const items: MenuProps["items"] = dropdownItems.map((item) => {
    return {
      key: item.key,
      label: item.label,
      disabled: item.disabled,
      onClick: item.onClick,
    };
  });

  return (
    <Dropdown menu={{ items }} placement="bottomRight" arrow={{ pointAtCenter: true }} trigger={["click"]}>
      <a style={{ color: "black" }}>
        <MoreOutlined />
      </a>
    </Dropdown>
  );
};

export default DropdownMenu;
