import React, { useEffect, useState } from "react";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { message, Button, Table } from "antd";
import { Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import { DragOutlined } from "@ant-design/icons";
import { useAppDispatch } from "../../../shared/store/store";
import { useSelector } from "react-redux";
import { getDriverLocation, isReOrderLoading, reOrderStopStore } from "../Slice";
import { useParams } from "react-router-dom";

interface DataType {
  key: string;
  store_number: string;
  do_number: string[];
  store_name: string;
  amphoe: string;
  province: string;
  tracking_number: string[];
  eta_start: string[];
  eta_end: string[];
  status: string[];
  do_type: string[];
}

const columns: ColumnsType<DataType> = [
  {
    title: "",
    dataIndex: "sort",
    width: 30,
    render: () => <DragOutlined rev={""} />,
  },
  {
    title: "รหัสสาขา",
    dataIndex: "store_number",
  },
  {
    title: "ชื่อสาขา",
    dataIndex: "store_name",
  },
];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
  status: string[];
}

const Row = (props: RowProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props["data-row-key"],
    disabled: props.status.includes("success"),
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: props.status.includes("success") ? "not-allowed" : "move",
    opacity: props.status.includes("success") ? 0.3 : 1,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};
interface ModalProps {
  open: boolean;
  onClose: () => void;
  storeList: any;
}

const ModalReOrderStop: React.FC<ModalProps> = ({ open, onClose, storeList }) => {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const dispatch = useAppDispatch();
  const isLoading = useSelector(isReOrderLoading);
  const { tracking_driver_id } = useParams();

  useEffect(() => {
    const formattedData: DataType[] = [];
    const storeNumberSet = new Set();

    storeList?.forEach((store: any) => {
      if (storeNumberSet.has(store.store_number)) return;

      storeNumberSet.add(store.store_number);
      formattedData.push({
        key: store.store_number,
        store_number: store.store_number,
        do_number: [store.do_number],
        store_name: store.store_name,
        amphoe: store.amphoe,
        province: store.province,
        tracking_number: [store.tracking_number],
        eta_start: [store.eta_start],
        eta_end: [store.eta_end],
        status: [store.status],
        do_type: [store.do_type],
      });
    });

    setDataSource(formattedData);
  }, [storeList]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );

  const onDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setDataSource((prev) => {
        const activeIndex = prev.findIndex((data) => data.key === active.id);
        const overIndex = prev.findIndex((data) => data.key === over?.id);
        const newDataSource = arrayMove(prev, activeIndex, overIndex);

        return newDataSource;
      });
    }
  };

  const onClick = async () => {
    const ReOrderStopParam = dataSource.map((data, index) => {
      return {
        store_number: data.store_number,
        stop: `${index + 1}`,
      };
    });

    const params = {
      tracking_driver_id: tracking_driver_id,
      job_list: ReOrderStopParam,
    };

    const response = await dispatch(reOrderStopStore(params));

    if (response.type === "request/reOrderStopStore/fulfilled") {
      message.success("เปลี่ยนแปลงลำดับการจัดส่งสำเร็จ");
      dispatch(getDriverLocation({ trackingDriverID: tracking_driver_id }));
      onClose();
    } else {
      message.error("เปลี่ยนแปลงลำดับการจัดส่งไม่สำเร็จ");
      onClose();
    }
  };

  return (
    <Modal
      style={{ top: 20 }}
      open={open}
      title="แก้ไขลำดับการจัดส่ง"
      className="modal-edit-stop"
      closable={false}
      footer={[
        <Button key="close" name="close" onClick={onClose}>
          Close
        </Button>,
        <Button key="submit" type="primary" name="submit" onClick={onClick} loading={isLoading}>
          OK
        </Button>,
      ]}
    >
      <DndContext onDragEnd={onDragEnd} sensors={sensors} collisionDetection={closestCenter}>
        <SortableContext items={dataSource.map((d) => d.key)} strategy={verticalListSortingStrategy}>
          <Table
            components={{
              body: {
                row: ({ className, "data-row-key": key, ...restProps }: any) => {
                  const index = dataSource.findIndex((data) => data.key === key);
                  return (
                    <Row
                      className={className}
                      status={dataSource[index]?.status}
                      data-row-key={key}
                      {...restProps}
                    />
                  );
                },
              },
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </SortableContext>
      </DndContext>
    </Modal>
  );
};

export default ModalReOrderStop;
