import "./style.scss";
import { Card, Col, Form, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import { useEffect, useRef, useState } from "react";
import SessionstorageService, {
  ConfigEpodReportState,
  EpodReportSorting,
} from "../../shared/service/Sessionstorage.service";
import TooltipComponent from "../../shared/component/TooltipComponent";
import EPODReportFormSearch from "./components/PodReportFormSearch";
import { EpodReport, SearchEpodReportParams } from "./models/epodReport";
import dayjs from "dayjs";
import PodReportService from "./services/pod-report-service";
import EPODReportDataTable from "./components/PodReportDataTable";
import { concatMap, from } from "rxjs";

export default function PodReport() {
  // Form
  const [searchForm] = Form.useForm();
  const [filterForm] = Form.useForm();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mapUnSignUrlList, setMapUnSignUrlList] = useState<Map<string, string[]>>(new Map());
  const concurrent = useRef<number>(0);

  const epodReportList = useRef<EpodReport[]>([]);
  const [searchParam, setSearchParam] = useState<SearchEpodReportParams | undefined>(undefined);
  const stateConfig = useRef<ConfigEpodReportState | null>(null);
  const [dataDisplay, setDataDisplay] = useState<EpodReport[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSizeRef = useRef(15);
  const [sortList, setSortList] = useState<EpodReportSorting[]>([]);
  const [filterText, setFilterText] = useState<string>("");

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isToday, setIsToday] = useState<boolean>(false);
  const [totalTask, setTotalTask] = useState<number>(0);

  useEffect(() => {
    const condition = SessionstorageService.getConfigPodReportState();
    if (condition) {
      stateConfig.current = condition;
      setSearchParam(condition.search);
      setFilterText(condition.filter);
      setSortList(condition.sorting);
      setCurrentPage(condition.activePage);
      pageSizeRef.current = condition.pageSize;

      if (condition.search) {
        getSearchPodReport(condition.search, condition.filter);
        getTotalTask({
          ...condition.search,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (searchParam !== undefined) {
      const plannedDate = dayjs(searchParam.planned_arrival).format("DD-MM-YYYY");
      const today = dayjs().format("DD-MM-YYYY");

      if (today === plannedDate) {
        setIsToday(true);
      } else {
        setIsToday(false);
      }
    }
  }, [searchParam]);

  useEffect(() => {
    const allKey = Array.from(mapUnSignUrlList.keys());
    const chunks: string[][] = [];
    const chunkSize = 1;
    const concurrentLimit = 15;

    for (let i = 0; i < allKey.length; i += chunkSize) {
      chunks.push(allKey.slice(i, i + chunkSize));
    }

    const interval = setInterval(() => {
      if (concurrent.current < concurrentLimit && chunks.length > 0) {
        getSignedUrl(chunks[0]);
        chunks.splice(0, 1);
        concurrent.current++;
      }

      if (chunks.length === 0) {
        concurrent.current = 0;
        clearInterval(interval);
      }
    }, 10);
  }, [mapUnSignUrlList]);

  const getSignedUrl = (keys: string[]) => {
    from(keys)
      .pipe(
        concatMap(async (key) => {
          const imageList = mapUnSignUrlList.get(key);
          if (imageList) {
            const signedUrl = await PodReportService.generateSignedUrl(imageList)
              .then((res) => {
                return res;
              })
              .catch((err) => {
                return err;
              });
            return { key, signedUrl };
          } else {
            return { key, signedUrl: null };
          }
        }),
      )
      .subscribe((data) => {
        if (data.signedUrl.signed_url) {
          const list = dataDisplay.map((x) => x);
          const index = list.findIndex((x) => `${x.trip_id}_${x.store_number}` === data.key);
          const oj = list[index];
          oj.signed_url = data.signedUrl.signed_url;
          list[index] = oj;
          setDataDisplay(list);
        }
      })
      .add(() => {
        concurrent.current--;
      });
  };

  type EpodReportState = {
    search?: SearchEpodReportParams;
    sorting?: EpodReportSorting[];
    activePage?: number;
    pageSize?: number;
    filter?: string;
  };

  const setPodReportState = ({ search, sorting, activePage, pageSize, filter }: EpodReportState) => {
    const config: ConfigEpodReportState = {
      search: search ?? searchParam,
      sorting: sorting ?? sortList,
      activePage: activePage ?? currentPage,
      pageSize: pageSize ?? pageSizeRef.current,
      filter: filter ?? filterText,
    };
    stateConfig.current = config;
    SessionstorageService.setConfigPodReportState(stateConfig.current);
  };

  const getSearchPodReport = async (param: SearchEpodReportParams, searchText?: string) => {
    setIsLoadingData(true);
    const mapUnsignedUrlList: Map<string, string[]> = new Map();

    await PodReportService.searchPodReport(param)
      .then(async (epodRes) => {
        const data: EpodReport[] = epodRes
          .filter((x: EpodReport) => x.dc_code === param.dc_code)
          .map((x: EpodReport, index: number) => {
            return {
              ...x,
              index,
            };
          });

        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const x = data[i];

            if (x.confirm_status === "Read") {
              // Mark read status
              x.report_status = 3;
            } else if (x.confirm_status === "Issue") {
              // Mark issuse status
              x.report_status = 1;
            } else {
              // Mark unread status
              x.report_status = 2;
            }
          }

          let sortedData: EpodReport[] = [];
          sortedData = data
            .sort((a, b) => a.truck_plate_number.localeCompare(b.truck_plate_number))
            .sort((a, b) => {
              return (
                Date.parse(dayjs(b.update_date).format("MM/DD/YYYY HH:mm:ss")) -
                Date.parse(dayjs(a.update_date).format("MM/DD/YYYY HH:mm:ss"))
              );
            })
            .sort((a, b) => a.report_status - b.report_status);

          sortedData.forEach((x) => {
            mapUnsignedUrlList.set(`${x.trip_id}_${x.store_number}`, x.epod_file);
          });

          setMapUnSignUrlList(mapUnsignedUrlList);

          if (sortedData.length >= 0) {
            epodReportList.current = sortedData;

            if (searchText) {
              const result = await filterFromText(sortedData, searchText);
              setDataDisplay(result);
            } else {
              setDataDisplay(sortedData);
            }

            setIsLoadingData(false);
          }
        } else {
          epodReportList.current = [];
          setDataDisplay([]);
          setIsLoadingData(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getTotalTask = async (param: SearchEpodReportParams) => {
    setIsLoadingData(true);
    await PodReportService.getTotalTask(param)
      .then((res) => {
        setTotalTask(res.total_task);
        setIsLoadingData(false);
      })
      .catch((err) => {
        setTotalTask(0);
        console.log("err", err);
      });
  };

  const onSubmit = async (value: SearchEpodReportParams) => {
    PodReportService.signalReset.next(true);
    setSearchParam(value);
    setCurrentPage(1);
    setFilterText("");

    setPodReportState({
      search: value,
      activePage: 1,
      filter: "",
    });

    await getSearchPodReport(value);
    await getTotalTask(value);
  };

  const onSubmitMarkunread = async (value: SearchEpodReportParams) => {
    setSearchParam(value);

    setPodReportState({ search: value });

    await getSearchPodReport(value);
  };

  const onPaginationChange = (newCurrent: number, newPageSize: number) => {
    const pageSizeChange = pageSizeRef.current !== newPageSize;
    if (pageSizeChange) {
      setCurrentPage(1);
    } else {
      setCurrentPage(newCurrent);
    }
    pageSizeRef.current = newPageSize;

    setPodReportState({
      activePage: newCurrent,
      pageSize: newPageSize,
    });
  };

  const onPageChange = (pagination: any, _: any, sorter: any) => {
    const list: EpodReportSorting[] = [];
    if (sorter.length > 0) {
      sorter.forEach(
        (element: { column: any; order: "descend" | "ascend"; field: string; columnKey: string }) => {
          list.push({
            columnKey: element.columnKey,
            value: element.order,
          });
        },
      );
    } else {
      if (sorter.order !== undefined) {
        list.push({
          columnKey: sorter.columnKey,
          value: sorter.order,
        });
      }
    }
    setSortList(list);
    setCurrentPage(pagination.current);
    pageSizeRef.current = pagination.pageSize;
    setPodReportState({
      sorting: list,
      activePage: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const filterFromText = async (data: EpodReport[], text: string) => {
    let epodReport = data.map((report) => report);
    if (text.length > 0) {
      epodReport = epodReport.filter((report) => {
        return (
          report.store_name.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) >= 0 ||
          report.truck_plate_number.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) >= 0
        );
      });
    }
    return epodReport.length > 0 ? epodReport : [];
  };

  const onSearchFilter = async (text: string) => {
    setIsLoadingData(true);
    setFilterText(text);
    setCurrentPage(1);

    const result = await filterFromText(epodReportList.current, text);
    setPodReportState({
      activePage: 1,
      filter: text,
    });
    setDataDisplay(result);
    setIsLoadingData(false);
  };

  return (
    <Content className="epod-report-content">
      <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
        <h1>PoD Report</h1>
        <TooltipComponent title="ตรวจสอบรายงานการจัดส่งสินค้า" />
      </div>
      <Card>
        <Row>
          <Col span={24}>
            <h3>ค้นหาข้อมูล</h3>
            <EPODReportFormSearch
              form={searchForm}
              isLoading={isLoadingData}
              onSearch={onSubmit}
              configState={stateConfig.current}
            />
          </Col>
        </Row>
      </Card>
      <Card
        className="list-epod-report-card"
        styles={{
          body: {
            padding: "16px 24px",
          },
        }}
      >
        <Row>
          <Col span={24}>
            <EPODReportDataTable
              form={filterForm}
              dataSource={epodReportList.current}
              dataDisplay={dataDisplay}
              isLoading={isLoadingData}
              configState={stateConfig.current}
              searchParam={searchParam !== undefined ? searchParam : { dc_code: "", planned_arrival: "" }}
              isToday={isToday}
              totalTask={totalTask}
              pagination={{
                current: currentPage,
                pageSize: pageSizeRef.current,
                onChange: onPaginationChange,
              }}
              onPageChange={onPageChange}
              onSearchFilter={onSearchFilter}
              onSearch={onSubmitMarkunread}
            />
          </Col>
        </Row>
      </Card>
    </Content>
  );
}
