import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GET_FEATURES_FLAGS } from "../../shared/api/ApiEndPoint";
import ApiClient from "../../shared/api/ApiClient";
import { RootState } from "../../shared/store/store";

type FeaturesFlagsState = {
  new_upload_do_template: boolean;
  toggle_tms_sync: boolean;
};

export const getFeaturesFlags = async (): Promise<FeaturesFlagsState> => {
  return ApiClient.get(GET_FEATURES_FLAGS).then((res) => res.data);
};

const initialValues: FeaturesFlagsState = {
  new_upload_do_template: false,
  toggle_tms_sync: false,
};

const featuresFlags = createSlice({
  name: "featuresFlags",
  initialState: initialValues,
  reducers: {
    setFeaturesFlagState(
      state: FeaturesFlagsState,
      action: PayloadAction<Partial<FeaturesFlagsState>>,
    ): FeaturesFlagsState {
      return { ...state, ...action.payload };
    },
  },
});

export const { setFeaturesFlagState } = featuresFlags.actions;

export const enabledNewUploadDoTemplate = (store: RootState) => store.featuresFlags.new_upload_do_template;
export const enabledToggleTMSSync = (store: RootState) => store.featuresFlags.toggle_tms_sync;

export default featuresFlags.reducer;
