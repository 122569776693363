import "./style.scss";

import { Button, Form, Input } from "antd";
import { loadingResetPassword, sentUIDToForgotPassword } from "./Slice";

import ModalComplete from "./ModalComplete";
import ModalConfirm from "./ModalConfirm";
import { useAppDispatch } from "../../shared/store/store";
import { useForm } from "antd/lib/form/Form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

interface FormValues {
  email: string;
}

export default function ForgotPassword() {
  const dispatch = useAppDispatch();
  const [isModalComfirmOpen, setIsModalComfirmOpen] = useState(false);
  const [isModalCompleteOpen, setIsModalCompleteOpen] = useState(false);
  const [form] = useForm();
  const isLoadingForgotPasswordProcessState = useSelector(loadingResetPassword);
  const navigate = useNavigate();

  const onFinish = async (values: FormValues) => {
    console.log("log", values.email);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onConfirmOk = async () => {
    await dispatch(sentUIDToForgotPassword(form.getFieldValue("email")));
    setIsModalComfirmOpen(false);
    setIsModalCompleteOpen(true);
  };

  const onConfirmCancel = () => {
    setIsModalComfirmOpen(false);
  };

  const onCompleteOk = () => {
    setIsModalCompleteOpen(false);
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="wrap-login-page">
      <div className="wrap-form form-forgot-password">
        <h1>Forgot Password?</h1>
        <Form
          form={form}
          name="forgot-password"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please input your email." },
              { type: "email", message: "Please input valid email." },
            ]}
          >
            <Input placeholder="Please input your email" />
          </Form.Item>
          <div className="wrap-login-action">
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                if (form.getFieldValue("email") !== undefined && form.getFieldValue("email") !== "") {
                  setIsModalComfirmOpen(true);
                }
              }}
            >
              Reset Password
            </Button>
          </div>
        </Form>
        <ModalConfirm
          open={isModalComfirmOpen}
          onOk={onConfirmOk}
          onCancel={onConfirmCancel}
          email={form.getFieldValue("email")}
          loading={isLoadingForgotPasswordProcessState}
        />
        <ModalComplete open={isModalCompleteOpen} onOk={onCompleteOk} />
      </div>
    </div>
  );
}
