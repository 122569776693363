import ApiClient from "../../../shared/api/ApiClient";
import { NotificationInfo } from "../models/messaging-status.model";

const getNotifications = (): Promise<NotificationInfo[]> =>
  ApiClient.get(`/v2/notifications`).then((res) => res.data);

const resendNotifications = (storeNumbers: string[]): Promise<any> =>
  ApiClient.post(`/v2/stores/resend-tracking`, storeNumbers).then((res) => res.data);

const messagingStatusService = { getNotifications, resendNotifications };

export default messagingStatusService;
