import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import LocalstorageService from "../shared/service/Localstorage.service";

interface Props {
  children?: ReactNode;
}

const RequireAuth = ({ children }: Props) => {
  const location = useLocation();
  const accessToken = LocalstorageService.getTokens()?.accessToken;

  if (!accessToken) {
    LocalstorageService.clear();
    return location.pathname.includes("agent-access-token") ? (
      <Navigate to="/login?from=agent-access-token" replace></Navigate>
    ) : (
      <Navigate to="/login" replace />
    );
  }

  return <>{children}</>;
};

export default RequireAuth;
