import { BehaviorSubject } from "rxjs";
import { httpClientTD } from "../../../shared/api/ApiClient";
import { CreateUserParam, User, UserInfo } from "../models/user";

const LIST_USER = "/v2/users";
const USER = "/v2/users";

const getUsers = (): Promise<User[]> => httpClientTD.get(LIST_USER).then((res) => res.data);

const createUser = (param: CreateUserParam): Promise<User[]> =>
  httpClientTD.post(USER, param).then((res) => res.data);

const updateUser = (uid: string, param: UserInfo): Promise<any> =>
  httpClientTD.put(`${USER}/${uid}`, param).then((res) => res.data);

const deleteUser = (uid: string): Promise<any> =>
  httpClientTD.delete(`${USER}/${uid}`).then((res) => res.data);

const getUserInfo = (uid: string): Promise<UserInfo> =>
  httpClientTD.get(`${USER}/${uid}`).then((res) => res.data);

const roles = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Manager",
    value: "manager",
  },
  {
    label: "Planner",
    value: "planner",
  },
  {
    label: "Operation",
    value: "operation",
  },
  {
    label: "Call Center",
    value: "call_center",
  },
];

const company = [
  {
    label: "ทั้งหมด",
    value: "all",
  },
  {
    label: "CJ Express",
    value: "CJ",
  },
  {
    label: "TD Tawandang",
    value: "TD",
  },
];

const signalReset = new BehaviorSubject<boolean>(false);
const userUpdated = new BehaviorSubject<UserInfo | null>(null);
const userDeleted = new BehaviorSubject<UserInfo | null>(null);

const userManagementService = {
  getUsers,
  createUser,
  getUserInfo,
  updateUser,
  deleteUser,
  roles,
  signalReset,
  company,
  userUpdated,
  userDeleted,
};

export default userManagementService;
