import { Input, Modal, Space } from "antd";
import { ButtonDs, FormDs, Form } from "design-system";
import BookingRequestService from "../services/booking-request-service";
import { useEffect, useState } from "react";
import AlertMessage from "../../../shared/component/AlertMessage";
import { AxiosError } from "axios";

interface Props {
  bookingId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const ModalConfirmCancel = ({ bookingId, isOpen, onClose, onSuccess }: Props) => {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!isOpen) {
      setErrorMessage(null);
      form.resetFields();
    }
  }, [isOpen]);

  const onClickCancle = () => {
    onClose();
  };

  const onSubmit = (values: any) => {
    form.validateFields().then(() => {
      BookingRequestService.cancelTruck(bookingId, values.reason).then(
        () => {
          onSuccess();
        },
        (err: AxiosError) => {
          if (err.response?.status === 400) {
            setErrorMessage("ไม่สามารถยกเลิกการจองได้ เนื่องจากไม่เป็นไปตามเงื่อนไข");
          } else {
            setErrorMessage("ไม่สามารถดำเนินการได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง");
          }
        },
      );
    });
  };

  return (
    <>
      <Modal
        centered
        open={isOpen}
        onCancel={onClickCancle}
        title={<h3 style={{ fontWeight: "bold" }}>ยืนยันการยกเลิกการจอง</h3>}
        closeIcon
        footer={
          <>
            <ButtonDs type="default" onClick={onClickCancle}>
              ยกเลิก
            </ButtonDs>
            <ButtonDs onClick={form.submit} type="primary">
              ยืนยัน
            </ButtonDs>
          </>
        }
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          {errorMessage && (
            <AlertMessage
              type="error"
              message="เกิดข้อผิดพลาด"
              description={errorMessage}
              margin="0 0 16px 0"
            />
          )}
          <FormDs form={form} onFinish={onSubmit} autoComplete="off" layout="vertical">
            <Form.Item name="reason" label="เหตุผล" rules={[{ required: true }]}>
              <Input.TextArea placeholder="โปรดระบุเหตุผล" size="large" maxLength={250} />
            </Form.Item>
          </FormDs>
        </Space>
      </Modal>
    </>
  );
};

export default ModalConfirmCancel;
