import "./style.scss";

import { Button, Form, Input, Layout, Popover, Spin, Table } from "antd";
import { Map, Overlay, ZoomControl } from "pigeon-maps";
import { geofences, isLoadingGeofences, searchGeofence } from "./Slice";
import { useEffect, useState } from "react";

import type { ColumnsType } from "antd/es/table";
import { GetDateTime } from "../../utils/GetDateNow";
import carIcon from "../../assets/images/icon/icon-car.png";
import dayjs from "dayjs";
import storeIcon from "../../assets/images/icon/icon-store.png";
import { useAppDispatch } from "../../shared/store/store";
import { useForm } from "antd/lib/form/Form";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const { Content } = Layout;

function Geofences() {
  const dispatch = useAppDispatch();
  const [geofencesData, setGeofencesData] = useState<any>(null);
  const geofencesState = useSelector(geofences);
  const isLoading = useSelector(isLoadingGeofences);
  const [form] = useForm();
  const [initialMapCenter, setInitialMapCenter] = useState<string[]>([]);
  const [mapcenter, setMapcenter] = useState<number[]>([]);
  const { driver_mobile_number, truck_plate_number, store_number } = useParams();

  useEffect(() => {
    setGeofencesData(geofencesState);
    if (geofencesState) {
      setInitialMapCenter([geofencesState.lat as string, geofencesState.long as string]);
    }
  }, [geofencesState]);

  useEffect(() => {
    if (driver_mobile_number && truck_plate_number && store_number) {
      form.setFieldsValue({
        driver_mobile_number: driver_mobile_number,
        truck_plate_number: truck_plate_number,
        store_number: store_number,
        arrival_date: dayjs().format("YYYY-MM-DD"),
      });
      dispatch(
        searchGeofence({
          driver_mobile_number: driver_mobile_number,
          truck_plate_number: truck_plate_number,
          store_number: store_number,
          arrival_date: dayjs().format("YYYY-MM-DD"),
        }),
      );
    }
  }, []);

  const popoverContent = (timestamp: string, lat: number, long: number) => {
    return (
      <div>
        <p>{GetDateTime(timestamp) ? GetDateTime(timestamp) : timestamp}</p>
        <a
          style={{ display: "block", textAlign: "center" }}
          target="_blank"
          href={`https://www.google.com/maps/dir/${lat}, ${long}`}
          rel="noreferrer"
        >
          เปิดใน Google Maps
        </a>
      </div>
    );
  };

  const popoverContentStore = (storeName: string, lat: number, long: number) => {
    return (
      <div>
        <p>{storeName}</p>
        <a
          style={{ display: "block", textAlign: "center" }}
          target="_blank"
          href={`https://www.google.com/maps/dir/${lat}, ${long}`}
          rel="noreferrer"
        >
          เปิดใน Google Maps
        </a>
      </div>
    );
  };

  const onFinish = (values: any) => {
    dispatch(
      searchGeofence({
        driver_mobile_number: values.driver_mobile_number,
        truck_plate_number: values.truck_plate_number,
        store_number: values.store_number,
        arrival_date: values.arrival_date,
      }),
    );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  interface DataType {
    key: string;
    timestamp: string;
    status: string;
    latLong: string;
    lat: string;
    long: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "ลำดับ",
      dataIndex: "order",
      key: "order",
      render: (_, data, index) => <span>{index + 1}</span>,
      width: 30,
      align: "center",
    },
    {
      title: "เวลา",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (_, data) => <span>{GetDateTime(data.timestamp)}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, data) => (
        <a
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            setInitialMapCenter(["", ""]);
            setMapcenter([Number(data.lat), Number(data.long)]);
          }}
          style={{ textDecoration: "underline", color: "#000000" }}
        >
          {data.status}
        </a>
      ),
    },
    {
      title: "พิกัด",
      dataIndex: "coord",
      key: "coord",
      render: (_, data) => (
        <a
          target="_blank"
          href={`https://www.google.com/maps/dir/${data.lat},${data.long}`}
          rel="noreferrer"
        >{`${data.lat}, ${data.long}`}</a>
      ),
    },
  ];

  return (
    <Content className="management-content">
      <div className="wrap-form-search-geofence">
        <Form
          form={form}
          name="search-truck"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{ dc: "ALL" }}
          layout="vertical"
        >
          <Form.Item label="เบอร์โทรศัพท์ พขร" name="driver_mobile_number">
            <Input
              required={true}
              style={{ width: "120px" }}
              onChange={(e) => {
                form.setFieldValue("driver_mobile_number", e.target.value.trim());
              }}
            />
          </Form.Item>

          <Form.Item label="ป้ายทะเบียน" name="truck_plate_number">
            <Input
              required={true}
              style={{ width: "120px" }}
              onChange={(e) => {
                form.setFieldValue("truck_plate_number", e.target.value.trim());
              }}
            />
          </Form.Item>

          <Form.Item label="รหัสร้านค้า" name="store_number">
            <Input
              required={true}
              style={{ width: "120px" }}
              onChange={(e) => {
                form.setFieldValue("store_number", e.target.value.trim());
              }}
            />
          </Form.Item>

          <Form.Item label="วันที่จัดส่ง" name="arrival_date">
            <Input
              type="date"
              required={true}
              style={{ width: "120px" }}
              onChange={(e) => {
                form.setFieldValue("arrival_date", e.target.value.trim());
              }}
            />
          </Form.Item>

          <Form.Item label=" ">
            <Button type="primary" htmlType="submit">
              ค้นหา
            </Button>
          </Form.Item>
        </Form>
      </div>
      {geofencesData && geofencesData.geofences && (
        <>
          <div style={{ height: "500px", marginBottom: 24 }}>
            <Map
              animate={true}
              center={[
                initialMapCenter[0] ? Number(initialMapCenter[0]) : mapcenter[0],
                initialMapCenter[1] ? Number(initialMapCenter[1]) : mapcenter[1],
              ]}
              zoom={mapcenter[0] ? 17 : 11}
              zoomSnap={false}
            >
              <ZoomControl />

              {geofencesData &&
                geofencesData.geofences?.map((x: any, i: string) => (
                  <Overlay anchor={[Number(x.lat), Number(x.long)]} key={`${x.store_number}-${i + 1}`}>
                    <Popover
                      content={popoverContent(x.timestamp, Number(x.lat), Number(x.long))}
                      trigger="hover"
                      key={`${x.store_number}-${i + 1}`}
                    >
                      <div className="wrap-geofence-marker">
                        <span className="badge">{x.status}</span>
                        <img src={carIcon} width={35} height={35} alt={`store-marker-${i}`} />
                      </div>
                    </Popover>
                  </Overlay>
                ))}

              <Overlay anchor={[Number(geofencesData.lat), Number(geofencesData.long)]}>
                <Popover
                  content={popoverContentStore(
                    geofencesData.store_number,
                    Number(geofencesData.lat),
                    Number(geofencesData.long),
                  )}
                  trigger="hover"
                >
                  <div className="wrap-geofence-marker">
                    <span className="badge">{geofencesData.store_name}</span>
                    <img src={storeIcon} width={35} height={35} alt="store-maker" />
                  </div>
                </Popover>
              </Overlay>
            </Map>
          </div>
          <Table rowKey={(x) => x.timestamp} columns={columns} dataSource={geofencesData?.geofences} />
        </>
      )}

      {isLoading && <Spin />}
    </Content>
  );
}

export default Geofences;
