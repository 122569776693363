import { UserAuthInfo } from "../type/UserAuthInfo";
import { httpClientTD } from "./ApiClient";

export const getUserAuthInfo = (uid: string): Promise<UserAuthInfo> => {
  return httpClientTD.get<UserAuthInfo>(`/v2/users/${uid}/permissions`).then(({ data }) => data);
};

export const getExternalUserAuthInfo = (uid: string): Promise<UserAuthInfo> => {
  return httpClientTD.get<UserAuthInfo>(`/external/${uid}/permissions`).then(({ data }) => data);
};

export const getEnabledOneFront = (): Promise<{ one_front_on: boolean }> => {
  return httpClientTD.get<{ one_front_on: boolean }>(`/feature-flags`).then(({ data }) => data);
};
