import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiClient from "../../shared/api/ApiClient";
import { RootState } from "../../shared/store/store";

type Geofences = {
  isLoading: boolean;
  geofences: GeofencesData;
};

const initialValues: Geofences = {
  isLoading: false,
  geofences: {
    store_number: null,
    store_name: null,
    lat: null,
    long: null,
  },
};

type RequestParams = {
  driver_mobile_number: string;
  truck_plate_number: string;
  store_number: string;
  arrival_date: string;
};

type GeofencesData = {
  store_number: string | null;
  store_name: string | null;
  lat: string | null;
  long: string | null;
};

export const searchGeofence = createAsyncThunk(
  "request/searchGeofence",
  async (params: RequestParams, { rejectWithValue }) => {
    try {
      const response = await ApiClient.post(`/search/geofence`, params);
      return response.data;
    } catch (err) {
      if (err instanceof Error) {
        return rejectWithValue(err.message);
      }
    }
  },
);

const geofencesSlice = createSlice({
  name: "geofencesSlice",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchGeofence.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(searchGeofence.fulfilled, (state, action) => {
      state.geofences = action.payload;
      state.isLoading = false;
    });

    builder.addCase(searchGeofence.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const geofences = (store: RootState) => store.geofences.geofences;
export const isLoadingGeofences = (store: RootState) => store.geofences.isLoading;
export default geofencesSlice.reducer;
