import * as ApiEndPoint from "../../shared/api/ApiEndPoint";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiClient from "../../shared/api/ApiClient";
import { RootState } from "../../shared/store/store";

type EtasListState = {
  loading: boolean;
  data: string[];
};

const initialValues: EtasListState = {
  loading: false,
  data: [],
};

export const fetchEtasList = createAsyncThunk(
  "request/getEtasList",
  async (dataLogin: any, { rejectWithValue }) => {
    try {
      const response = await ApiClient.get(ApiEndPoint.GET_ETAS, dataLogin);
      return response.data;
    } catch (err) {
      if (err instanceof Error) {
        return rejectWithValue(err.message);
      }
    }
  },
);

const etasList = createSlice({
  name: "etasList",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEtasList.pending, (state) => {
      state.loading = true;
      state.data = [];
    });

    builder.addCase(fetchEtasList.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchEtasList.rejected, (state) => {
      state.loading = false;
      state.data = [];
    });
  },
});

export const etasListState = (store: RootState) => store.etaList.data;
export default etasList.reducer;
