import { Breadcrumb, Col, Layout, Row, Space, notification } from "antd";
import DesignSystemTheme from "../../shared/component/DesignSystemTheme";
import { ButtonDs } from "design-system";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import BookingRequestService from "./services/booking-request-service";
import { AddressInfo, BookingInfo } from "./models/booking";
import dayjs from "dayjs";
import ModalAssignTruck from "./components/ModalAssignTruck";
import CardBox from "../../shared/component/CardBox";
import StatusTag from "../../shared/component/StatusTag";
import React from "react";
import StateService from "../../shared/service/State.service";
import ModalConfirmCancel from "./components/ModalConfirmCancel";
import { CheckCircleOutlined } from "@ant-design/icons";

const Content = Layout.Content;

const BookingDetail = () => {
  const { booking_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [bookingInfo, setBookingInfo] = useState<BookingInfo>();
  const [isModalAssignTruckOpen, setIsModalAssignTruckOpen] = useState(false);
  const [isModalConfirmCancelOpen, setIsModalConfirmCancelOpen] = useState(false);
  const [notificationApi, contextHolderNoti] = notification.useNotification();

  useEffect(() => {
    StateService.initializeState();
    fetchBookingInfo();
  }, [booking_id]);

  const fetchBookingInfo = () => {
    if (booking_id) {
      setIsLoading(true);
      BookingRequestService.getBookingDetail(booking_id).then(
        (res) => {
          // simulate loading
          setTimeout(() => {
            setBookingInfo(res.data);
            setIsLoading(false);
          }, 1);
        },
        (err) => {
          console.log(err);
          setIsLoading(false);
        },
      );
    }
  };

  const renderContentDetail = (title: string, content: string) => {
    return (
      <div style={{ lineHeight: 1.5 }}>
        <div style={{ fontSize: "12px", color: "#272A31" }}>{title}</div>
        <div style={{ fontSize: "16px" }}>{content}</div>
      </div>
    );
  };

  const joinAddressInfo = (addressInfo: AddressInfo | undefined): string => {
    if (addressInfo) {
      const fullAddress = `${addressInfo.sub_district} ${addressInfo.district} ${addressInfo.province} ${addressInfo.postcode}`;
      if (fullAddress.trim() === "") {
        return "-";
      }
      return fullAddress;
    }
    return "-";
  };

  return (
    <DesignSystemTheme>
      <Content className="booking-requests-content">
        <Breadcrumb
          style={{ marginBottom: "16px" }}
          separator=">"
          items={[
            {
              title: <Link to="/booking-request">Booking Requests</Link>,
            },
            {
              title: <div style={{ color: "#006D2E" }}>รายละเอียดการจอง</div>,
            },
          ]}
        />
        <CardBox>
          <Row
            gutter={[16, 16]}
            style={{ paddingBottom: "20px" }}
            className={bookingInfo?.status !== "canceled" ? "fix-content" : ""}
          >
            <Col span={24} style={{ display: "flex" }}>
              <Space>
                <div className="detail-title">รายละเอียดการจอง</div>
                <div className="update-at">
                  (Last update : {dayjs(bookingInfo?.last_update).format("DD-MM-YYYY HH:mm")})
                </div>
              </Space>
            </Col>
            <Col span={18}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <div>รหัสงานขนส่ง : {bookingInfo?.booking_id}</div>
                </Col>
                <Col span={12}>
                  <div>ลูกค้า : {bookingInfo?.booking_by?.company_name}</div>
                </Col>
                <Col span={12}>
                  <div>
                    สถานะ : <StatusTag status={bookingInfo?.status || ""} />
                  </div>
                </Col>
                {bookingInfo?.status === "canceled" && (
                  <Col span={12} style={{ overflowWrap: "break-word" }}>
                    <div>เหตุผลการยกเลิก/ปฏิเสธ : {bookingInfo?.cancel_reason}</div>
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={6} style={{ display: "flex", justifyContent: "flex-end" }}>
              {bookingInfo?.status === "pending" &&
                dayjs(bookingInfo?.pickup_info?.pickup_date).isAfter(dayjs().startOf("day")) && (
                  <Space>
                    {/* <ButtonDs type="primary" danger>
                      ปฏิเสธคำขอ
                    </ButtonDs> */}
                    <ButtonDs type="primary" onClick={() => setIsModalAssignTruckOpen(true)}>
                      มอบหมายงาน
                    </ButtonDs>
                  </Space>
                )}
              {bookingInfo?.status === "confirmed" &&
                dayjs(bookingInfo?.pickup_info?.pickup_date).isAfter(dayjs().startOf("day")) && (
                  <Space>
                    <ButtonDs type="primary" danger onClick={() => setIsModalConfirmCancelOpen(true)}>
                      ยกเลิกการจอง
                    </ButtonDs>
                    <ButtonDs type="primary" onClick={() => setIsModalAssignTruckOpen(true)}>
                      ปรับปรุงข้อมูล
                    </ButtonDs>
                  </Space>
                )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <CardBox type="secondary" loading={isLoading}>
                <h2>ข้อมูลรถ</h2>
                <Row gutter={[16, 16]}>
                  {!bookingInfo?.truck_list && (
                    <Col span={12}>{renderContentDetail("ประเภทรถ", bookingInfo?.truck_type || "-")}</Col>
                  )}
                  {bookingInfo?.truck_list && (
                    <>
                      {bookingInfo?.truck_list?.map((truckInfo, index) => (
                        <React.Fragment key={index}>
                          <hr style={{ width: "100%" }} />
                          <Col span={6}>
                            {renderContentDetail(
                              "ประเภทขนส่ง",
                              truckInfo.truck_owner === "own_fleet"
                                ? "Own Fleet"
                                : truckInfo.subby_name || "-",
                            )}
                          </Col>
                          <Col span={6}>{renderContentDetail("ประเภทรถ", truckInfo.truck_type)}</Col>
                          <Col span={6}>
                            {renderContentDetail("ป้ายทะเบียนหัว", truckInfo.truck_plate || "-")}
                          </Col>
                          <Col span={6}>
                            {renderContentDetail("ป้ายทะเบียนหาง", truckInfo.trailer_plate || "-")}
                          </Col>
                          <Col span={6}>
                            {renderContentDetail("พนักงานขับรถ", truckInfo.driver_name || "-")}
                          </Col>
                          <Col span={18}>
                            {renderContentDetail("เบอร์โทรศัพท์", truckInfo.driver_mobile || "-")}
                          </Col>
                          {bookingInfo.truck_list?.length - 1 === index && <hr style={{ width: "100%" }} />}
                        </React.Fragment>
                      ))}
                      <Col span={24}>{renderContentDetail("หมายเหตุ", bookingInfo?.remark || "-")}</Col>
                    </>
                  )}
                </Row>
              </CardBox>
            </Col>
            <Col span={24}>
              <CardBox type="secondary" loading={isLoading}>
                <h2>จุดรับสินค้า</h2>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    {renderContentDetail(
                      "วันเวลาที่เข้ารับ",
                      dayjs(bookingInfo?.pickup_info.pickup_date).format("DD-MM-YYYY HH:mm"),
                    )}
                  </Col>
                  <Col span={12}>
                    {renderContentDetail(
                      "สถานที่เข้ารับ",
                      bookingInfo?.pickup_info.address_info.address_name || "-",
                    )}
                  </Col>
                  <Col span={12}>
                    {renderContentDetail(
                      "ที่อยู่",
                      joinAddressInfo(bookingInfo?.pickup_info.address_info) || "-",
                    )}
                  </Col>
                  <Col span={12}>
                    {renderContentDetail(
                      "ชื่อผู้ติดต่อ",
                      bookingInfo?.pickup_info.address_info.contact_name || "-",
                    )}
                  </Col>
                  <Col span={12}>
                    {renderContentDetail(
                      "เบอร์ผู้ติดต่อ",
                      bookingInfo?.pickup_info.address_info.contact_mobile || "-",
                    )}
                  </Col>
                  <Col span={24}>
                    {renderContentDetail("หมายเหตุ", bookingInfo?.pickup_info.remark || "-")}
                  </Col>
                </Row>
              </CardBox>
            </Col>
            {bookingInfo?.dropoff_list.map((item, index) => (
              <Col span={24} key={index}>
                <CardBox type="secondary" loading={isLoading}>
                  <h2>จุดส่งสินค้า {bookingInfo?.dropoff_list.length > 1 && `จุดที่ ${index + 1}`}</h2>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      {renderContentDetail("วันที่ส่งสินค้า", dayjs(item.dropoff_date).format("DD-MM-YYYY"))}
                    </Col>
                    <Col span={12}>
                      {renderContentDetail("เวลาที่ส่งสินค้า", dayjs(item.dropoff_date).format("HH:mm"))}
                    </Col>
                    <Col span={12}>
                      {renderContentDetail("สถานที่ส่งสินค้า", item.address_info.address_name)}
                    </Col>
                    <Col span={12}>{renderContentDetail("ที่อยู่", joinAddressInfo(item.address_info))}</Col>
                    <Col span={12}>{renderContentDetail("หมายเลข PO/รหัสอ้างอิง", item.ref_no || "-")}</Col>
                    <Col span={12}>
                      {renderContentDetail("จำนวนลัง", `${item.unit_amount === 0 ? "-" : item.unit_amount}`)}
                    </Col>
                    <Col span={12}>
                      {renderContentDetail(
                        "จำนวนพาเลท",
                        `${item.pallet_amount === 0 ? "-" : item.pallet_amount}`,
                      )}
                    </Col>
                    <Col span={12}>
                      {renderContentDetail("ชื่อผู้ติดต่อ", item.address_info.contact_name || "-")}
                    </Col>
                    <Col span={12}>
                      {renderContentDetail("เบอร์โทรผู้ติดต่อ", item.address_info.contact_mobile || "-")}
                    </Col>
                    <Col span={24}>{renderContentDetail("หมายเหตุ", item.remark)}</Col>
                  </Row>
                </CardBox>
              </Col>
            ))}
            <Col span={24}>
              <CardBox type="secondary" loading={isLoading}>
                <h2>บริการเสริม</h2>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    {renderContentDetail(
                      "ค่าบริการตักสินค้า",
                      `${
                        bookingInfo?.add_on_services?.picking_fee ?? -1 > 0
                          ? `${bookingInfo?.add_on_services.picking_fee.toLocaleString()} บาท`
                          : "-"
                      }`,
                    )}
                  </Col>
                  <Col span={24}>
                    {renderContentDetail(
                      "พนักงานยกสินค้า",
                      bookingInfo?.add_on_services?.helper ?? -1 > 0
                        ? `${bookingInfo?.add_on_services.helper} คน`
                        : "-",
                    )}
                  </Col>
                </Row>
              </CardBox>
            </Col>
          </Row>
        </CardBox>
      </Content>
      <ModalAssignTruck
        bookingId={booking_id ?? ""}
        bookingInfo={bookingInfo}
        open={isModalAssignTruckOpen}
        onCancel={() => setIsModalAssignTruckOpen(false)}
        onSuccess={() => setIsModalAssignTruckOpen(false)}
      />
      <ModalConfirmCancel
        bookingId={booking_id ?? ""}
        isOpen={isModalConfirmCancelOpen}
        onClose={() => setIsModalConfirmCancelOpen(false)}
        onSuccess={() => {
          fetchBookingInfo();
          setIsModalConfirmCancelOpen(false);
          notificationApi.success({
            message: "ยกเลิกการจองสำเร็จ !",
            description: "การยกเลิกการจองเสร็จสิ้น",
            className: "success-notification",
            icon: <CheckCircleOutlined style={{ color: "#41A447" }} />,
            placement: "topRight",
          });
        }}
      />
      {contextHolderNoti}
    </DesignSystemTheme>
  );
};

export default BookingDetail;
