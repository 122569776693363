import { PaginationProps, Table, TableProps } from "antd";
import { ButtonDs } from "design-system";
import LocalstorageService from "../../../shared/service/Localstorage.service";
import StatusTag from "../../../shared/component/StatusTag";
import { Booking } from "../models/booking";
import { FormatDate } from "../../../utils/GetDateNow";
import TooltipComponent from "../../../shared/component/TooltipComponent";
import { useNavigate } from "react-router-dom";

interface Props {
  dataSource: Booking[] | undefined;
  isLoading: boolean;
  onChangePage: (page: number, pageSize: number) => void;
  currentPage: number;
  pageSize: number;
}

const DataTable = ({ dataSource, isLoading, onChangePage, currentPage, pageSize }: Props) => {
  const userRole = LocalstorageService.getCurrentRole();
  const navigate = useNavigate();

  const columns: TableProps<Booking>["columns"] = [
    {
      title: "ลำดับ",
      key: "no",
      align: "center",
      render: (_, __, index) => <>{(currentPage - 1) * pageSize + (index + 1)}</>,
      width: 50,
    },
    {
      title: "รหัสงานขนส่ง",
      dataIndex: "booking_id",
      key: "booking_id",
    },
    {
      title: "ลูกค้า",
      dataIndex: "booking_by",
      key: "booking_by",
      render: (_, record) => {
        return <>{record.booking_by.company_name}</>;
      },
    },
    {
      title: "ประทับเวลาจอง",
      dataIndex: "booking_date",
      key: "booking_date",
      render: (text) => {
        return <>{FormatDate(text, "DD-MM-YYYY, HH:mm")}</>;
      },
    },
    {
      title: "วันที่เข้ารับสินค้า",
      dataIndex: "pickup_date",
      key: "pickup_date",
      render: (text) => {
        return <>{FormatDate(text, "DD-MM-YYYY, HH:mm")}</>;
      },
    },
    {
      title: "ประเภทรถ",
      dataIndex: "truck_type",
      key: "truck_type",
    },
    {
      title: "จุดรับสินค้า",
      dataIndex: "pickup",
      key: "pickup",
    },
    {
      title: "จุดส่งสินค้า",
      dataIndex: "dropoff",
      key: "dropoff",
      render: (_, record) => {
        return (
          <>
            {`${record.dropoffs ? record.dropoffs.length.toString() : "0"} จุดส่ง`}
            <TooltipComponent
              placement="bottomRight"
              title={
                <>
                  <h4 style={{ fontWeight: "bold" }}>รายละเอียดจุดส่ง</h4>
                  {record.dropoffs != null
                    ? record.dropoffs.map((item, index) => (
                        <li key={index} className="dropoff-point-text">
                          <span>
                            {index + 1}. {item.address_name}
                          </span>
                        </li>
                      ))
                    : ""}
                </>
              }
            />
          </>
        );
      },
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, record) => {
        return <StatusTag status={record.status} />;
      },
    },
    userRole && userRole.includes("manager")
      ? {}
      : {
          title: "Action",
          key: "action",
          fixed: "right",
          align: "center",
          render: (record) => (
            <ButtonDs
              size="middle"
              type="default"
              onClick={() => {
                navigate(`/booking-request/${record.booking_id}`);
              }}
            >
              รายละเอียด
            </ButtonDs>
          ),
          width: 150,
        },
  ];

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    onChangePage(page, pageSize);
  };

  return (
    <Table
      scroll={{ x: 1200 }}
      loading={isLoading}
      rowKey={(row) => row.booking_id}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ["15", "30", "50", "100"],
        pageSize: pageSize,
        current: currentPage,
        onChange: onChange,
      }}
      onRow={(record) => {
        return {
          onClick: () => {
            navigate(`/booking-request/${record.booking_id}`);
          },
        };
      }}
    />
  );
};

export default DataTable;
