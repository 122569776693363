import { FormDs, Form, SelectDs } from "design-system";
import { dcLists } from "../../../utils/DClists";
import { ReturnEtaDocument } from "../models/return-eta-request.model";
import { useEffect, useState } from "react";

interface Props {
  dataSource: ReturnEtaDocument[];
  onSearch: (data: ReturnEtaDocument[]) => void;
}

const FormFilter = ({ dataSource, onSearch }: Props) => {
  const [form] = Form.useForm();
  const [dcCode, setDcCode] = useState<string>("all");
  const [truckType, setTruckType] = useState<string>("all");

  useEffect(() => {
    if (dataSource.length > 0) {
      const filteredData = dataSource.filter((data) => {
        if (dcCode === "all" && truckType === "all") {
          return true;
        } else if (dcCode !== "all" && truckType === "all") {
          return data.dc_code === dcCode;
        } else if (dcCode === "all" && truckType !== "all") {
          return data.truck_type === truckType;
        } else {
          return data.dc_code === dcCode && data.truck_type === truckType;
        }
      });
      onSearch(filteredData);
    }
  }, [dcCode, truckType, dataSource]);

  return (
    <>
      <FormDs
        style={{ display: "flex" }}
        form={form}
        initialValues={{ dc_code: "all", truck_type: "all" }}
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item label="ศูนย์กระจายสินค้า" name="dc_code">
          <SelectDs
            options={[{ label: "ทั้งหมด", value: "all" }, ...dcLists()]}
            style={{ width: 256 }}
            onChange={(value) => setDcCode(value)}
            data-test-id="form_filter_dc_code"
          />
        </Form.Item>
        <Form.Item label="ประเภทรถ" name="truck_type">
          <SelectDs
            options={[
              { label: "ทั้งหมด", value: "all" },
              { label: "10W", value: "10W" },
              { label: "10WT", value: "10WT" },
              { label: "6W", value: "6W" },
              { label: "6WT", value: "6WT" },
              { label: "4WCR", value: "4WCR" },
              { label: "4WJ", value: "4WJ" },
              { label: "4W", value: "4W" },
            ]}
            style={{ width: 256 }}
            onChange={(value) => setTruckType(value)}
            data-test-id="form_filter_truck_type"
          />
        </Form.Item>
      </FormDs>
    </>
  );
};

export default FormFilter;
