import {
  EDIT_TRACKING_DRIVER,
  GET_DRIVER_LOCATION,
  GetDriverLocationParams,
  RE_ORDER_STOP,
} from "../../shared/api/ApiEndPoint";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiClient from "../../shared/api/ApiClient";
import { Driver } from "./Driver";
import { RootState } from "../../shared/store/store";
import { ErrorResponse } from "../../shared/type/ErrorType";
import { AxiosError } from "axios";

type GpsTrackingState = {
  // Params
  params: GetDriverLocationParams | null;

  // Get driver location
  driver: Driver | null;
  isLoading: boolean;
  getDriverLocationError: ErrorResponse | null;

  // Re-order stop store
  isReOrderLoading: boolean;
  isReOrderFinished: boolean;
  reOrderStopStoreError: ErrorResponse | null;

  // Edit tracking driver
  isEditLoading: boolean;
  isEditFinished: boolean;
  editTrackingDriverError: ErrorResponse | null;
};

const initialValues: GpsTrackingState = {
  params: null,

  driver: null,
  isLoading: false,
  getDriverLocationError: null,

  isReOrderLoading: false,
  isReOrderFinished: false,
  reOrderStopStoreError: null,

  isEditLoading: false,
  isEditFinished: false,
  editTrackingDriverError: null,
};

export const getDriverLocation = createAsyncThunk<
  Driver,
  any,
  {
    rejectValue: ErrorResponse;
  }
>("request/getDriver", async (params: any, { rejectWithValue }) => {
  try {
    const response = await ApiClient.get(GET_DRIVER_LOCATION(params));
    return response.data;
  } catch (err) {
    if (err instanceof AxiosError) {
      const errorResponse: ErrorResponse = {
        status: err.response?.status || 500,
        message: err.response?.data,
      };
      return rejectWithValue(errorResponse);
    }
  }
});

export const getDriverDetail = (tracking_driver_id: string): Promise<Driver> =>
  ApiClient.get<Driver>(GET_DRIVER_LOCATION({ trackingDriverID: tracking_driver_id })).then(
    (res) => res.data,
  );

export const editTrackingDriver = createAsyncThunk<
  Driver,
  any,
  {
    rejectValue: ErrorResponse;
  }
>("request/editTrackingDriver", async (params: any, { rejectWithValue }) => {
  try {
    const requestBody = {
      truck_plate_number: params.truck_plate_number,
      mobile_phone: params.driver_mobile_number,
      truck_type: params.truck_type,
      sla: params.sla,
      planned_load_date: params.planned_date,
      planned_load_time: params.planned_time,
    };

    const response = await ApiClient.put(EDIT_TRACKING_DRIVER(params.tracking_driver_id), requestBody);
    return response.data;
  } catch (err) {
    if (err instanceof AxiosError) {
      return rejectWithValue(err.response?.data);
    }
  }
});

export const reOrderStopStore = createAsyncThunk<
  Driver,
  any,
  {
    rejectValue: ErrorResponse;
  }
>("request/reOrderStopStore", async (params: any, { rejectWithValue }) => {
  try {
    const requestBody = { job_list: params.job_list };
    const response = await ApiClient.put(RE_ORDER_STOP(params.tracking_driver_id), requestBody);
    return response.data;
  } catch (err) {
    if (err instanceof AxiosError) {
      return rejectWithValue(err.response?.data);
    }
  }
});

const gpsTrackingSlice = createSlice({
  name: "gpsTrackingSlice",
  initialState: initialValues,
  reducers: {
    updateGpsTrackingParams: (state: GpsTrackingState, action: PayloadAction<GetDriverLocationParams>) => {
      state.params = action.payload;
    },
    clearStateGpsTracking: (state: GpsTrackingState) => {
      state.getDriverLocationError = null;
    },
  },
  extraReducers: (builder) => {
    // Get Driver Location
    builder.addCase(getDriverLocation.pending, (state) => {
      state.isLoading = true;
      state.driver = null;
      state.getDriverLocationError = null;
    });

    builder.addCase(getDriverLocation.fulfilled, (state, action) => {
      state.driver = action.payload;
      state.isLoading = false;
    });

    builder.addCase(getDriverLocation.rejected, (state, action) => {
      state.getDriverLocationError = action.payload ? action.payload : null;

      state.isLoading = false;
    });

    // Edit Tracking Driver
    builder.addCase(editTrackingDriver.pending, (state) => {
      state.isEditLoading = true;
    });

    builder.addCase(editTrackingDriver.fulfilled, (state) => {
      state.isEditLoading = false;
      state.isEditFinished = true;
    });

    builder.addCase(editTrackingDriver.rejected, (state, action) => {
      state.editTrackingDriverError = action.payload ? action.payload : null;

      state.isEditLoading = false;
    });

    // Re Order Stop Store
    builder.addCase(reOrderStopStore.pending, (state) => {
      state.isReOrderLoading = true;
    });

    builder.addCase(reOrderStopStore.fulfilled, (state) => {
      state.isReOrderLoading = false;
      state.isReOrderFinished = true;
    });

    builder.addCase(reOrderStopStore.rejected, (state, action) => {
      state.driver = null;
      state.reOrderStopStoreError = action.payload ? action.payload : null;

      state.isReOrderLoading = false;
    });
  },
});

export const { updateGpsTrackingParams, clearStateGpsTracking } = gpsTrackingSlice.actions;
export const getDriverLocationParams = (store: RootState) => store.gpsTracking.params;

// Loading State
export const isLoadingDriverDetail = (store: RootState) => store.gpsTracking.isLoading;
export const isEditLoading = (store: RootState) => store.gpsTracking.isEditLoading;
export const isReOrderLoading = (store: RootState) => store.gpsTracking.isReOrderLoading;
export const isReOrderFinished = (store: RootState) => store.gpsTracking.isReOrderFinished;
export const isEditFinished = (store: RootState) => store.gpsTracking.isEditFinished;

// Response
export const driver = (store: RootState) => store.gpsTracking.driver;

export const getDriverLocationError = (store: RootState) => store.gpsTracking.getDriverLocationError;
export const editTrackingDriverError = (store: RootState) => store.gpsTracking.editTrackingDriverError;
export const reOrderStopStoreError = (store: RootState) => store.gpsTracking.reOrderStopStoreError;

export default gpsTrackingSlice.reducer;
