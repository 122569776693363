import "./style.scss";

import { Layout } from "antd";

const { Content } = Layout;

export default function PermissionDenied() {
  return (
    <Content className="management-content irrelevant-page">
      <div>คุณไม่สามารถเข้าถึงหน้านี้ได้</div>
    </Content>
  );
}
