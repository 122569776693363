import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import HeaderComponent from "../shared/component/HeaderComponent/HeaderComponent";

const PublicLayout = () => {
  return (
    <Layout className="management-layout">
      <HeaderComponent />
      <Layout>
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default PublicLayout;
