import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiClient from "../../shared/api/ApiClient";
import { RootState } from "../../shared/store/store";
import { UPDATE_TRACKING_STATUS } from "../../shared/api/ApiEndPoint";
import { ErrorResponse } from "../../shared/type/ErrorType";
import { AxiosError } from "axios";

type ChangeTrackingStatus = {
  isLoading: boolean;
  isSuccess: boolean | null;
  changeStatusError: ErrorResponse | null;
};

export type TrackingStatusData = {
  status: string;
  reason: string;
  note: string;
  driver_mobile_number: string | undefined;
  truck_plate_number: string | undefined;
  tracking_driver_id: string | undefined;
};

type ParamStatusData = {
  trackingStatusID: string;
  dataUpdate: TrackingStatusData;
};

const initialValues: ChangeTrackingStatus = {
  isLoading: false,
  isSuccess: null,
  changeStatusError: null,
};

export const changeTrackingStatus = createAsyncThunk<any, ParamStatusData, { rejectValue: ErrorResponse }>(
  "request/changeTrackingStatus",
  async (param: ParamStatusData, { rejectWithValue }) => {
    try {
      const response = await ApiClient.post(UPDATE_TRACKING_STATUS(param.trackingStatusID), param.dataUpdate);
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorResponse: ErrorResponse = {
          status: error.response?.status || 500,
          message: error.response?.data,
        };
        return rejectWithValue(errorResponse);
      }
    }
  },
);

const changeTrackingStatusSlice = createSlice({
  name: "changeTrackingStatusSlice",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changeTrackingStatus.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = null;
    });

    builder.addCase(changeTrackingStatus.fulfilled, (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    });

    builder.addCase(changeTrackingStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.changeStatusError = action.payload ? action.payload : null;
    });
  },
});

export const isLoadingUpdateTrackingStatus = (store: RootState) => store.updateTrackingStatus.isLoading;
export const isChangeStatusSuccess = (store: RootState) => store.updateTrackingStatus.isSuccess;
export const changeStatusError = (store: RootState) => store.updateTrackingStatus.changeStatusError;
export default changeTrackingStatusSlice.reducer;
