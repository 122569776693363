import { FormDs, Form, InputDs } from "design-system";
import { useEffect } from "react";
import PodReportService from "../services/pod-report-service";
import { FormInstance } from "antd";

interface Props {
  form: FormInstance<any>;
  onSearch: (searchText: string) => void;
  searchData: string;
}

const PodReportFormFilter = ({ form, onSearch, searchData }: Props) => {
  useEffect(() => {
    PodReportService.signalReset.subscribe((isReset) => {
      if (isReset) {
        form.setFieldValue("search_box", "");
      }
    });
  }, []);

  useEffect(() => {
    if (searchData.length > 0) {
      form.setFieldValue("search_box", searchData);
    }
  }, [searchData]);

  const onFilterData = (value: string) => {
    onSearch(value);
  };

  return (
    <>
      <FormDs className="search-form-search" form={form} autoComplete="off" requiredMark={false}>
        <Form.Item className="form-item" label="ค้นหาป้ายทะเบียน หรือ ชื่อร้านค้า" name="search_box">
          <InputDs
            placeholder="ค้นหาป้ายทะเบียน หรือ ชื่อร้านค้า"
            size="large"
            width="280px"
            onBlur={(e) => {
              const value = e.target.value;
              onFilterData(value);
            }}
            onPressEnter={(event) => {
              event.currentTarget.blur();
            }}
          />
        </Form.Item>
      </FormDs>
    </>
  );
};

export default PodReportFormFilter;
