import ApiClient from "../../../shared/api/ApiClient";
import { RequestReturnEta, ReturnEtaDocument } from "../models/return-eta-request.model";

const getReturnEtas = (): Promise<ReturnEtaDocument[]> =>
  ApiClient.get(`/v2/return-eta/requests`).then((res) => res.data);

const createRturnEtaRequest = (params: RequestReturnEta): Promise<any> =>
  ApiClient.post(`/v2/return-eta/requests`, params).then((res) => res.data);

const downloadReturnEtaFile = (id: string): Promise<any> =>
  ApiClient.get(`/v2/return-eta/${id}/download`, { responseType: "blob" }).then((res) => res);

const returnEtaRequestService = { getReturnEtas, createRturnEtaRequest, downloadReturnEtaFile };

export default returnEtaRequestService;
