import { MoreOutlined } from "@ant-design/icons";
import { Menu, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { Eta, Sync } from "../models/delivery-order.model";
import { currentUserRole } from "../../Login/LoginSlice";
import { useSelector } from "react-redux";
import { enabledNewUploadDoTemplate } from "../featuresFlagsSlice";

interface Props {
  record: Eta;
  onAction: (result: { type: string; record: Eta }) => void;
}

interface SyncProps {
  record: Sync;
  onAction: (result: { type: string; record: Sync }) => void;
}

export const PopoverAction = ({ record, onAction }: Props) => {
  const userRole = useSelector(currentUserRole);
  const [open, setOpen] = useState(false);
  const enabledNewUploadDoTempState = useSelector(enabledNewUploadDoTemplate);
  const [menuItem, setMenuItem] = useState<{ key: string; label: string }[]>([
    { key: "send_message", label: "ส่งข้อความแจ้งเตือนจัดส่ง" },
    { key: "download_latest_data", label: "ดาวน์โหลดข้อมูลล่าสุด" },
    { key: "download_original_data", label: "ดาวน์โหลดข้อมูลต้นฉบับ" },
    { key: "delete", label: "ลบข้อมูล" },
  ]);

  useEffect(() => {
    if (userRole && userRole.includes("planner")) {
      setMenuItem([
        { key: "download_latest_data", label: "ดาวน์โหลดข้อมูลล่าสุด" },
        { key: "download_original_data", label: "ดาวน์โหลดข้อมูลต้นฉบับ" },
      ]);
    }

    if (userRole && userRole.includes("operation")) {
      setMenuItem([
        { key: "download_latest_data", label: "ดาวน์โหลดข้อมูลล่าสุด" },
        { key: "download_original_data", label: "ดาวน์โหลดข้อมูลต้นฉบับ" },
        { key: "delete", label: "ลบข้อมูล" },
      ]);
    }

    if (enabledNewUploadDoTempState) {
      setMenuItem([
        { key: "download_latest_data", label: "ดาวน์โหลดข้อมูลล่าสุด" },
        { key: "download_original_data", label: "ดาวน์โหลดข้อมูลต้นฉบับ" },
        { key: "delete", label: "ลบข้อมูล" },
      ]);
    }
  }, [userRole, enabledNewUploadDoTempState]);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const content = (
    <div>
      <Menu
        data-test-id={`menu_${record.dc_code}`}
        style={{ border: "none", width: "100%" }}
        onClick={(e) => {
          onAction({ type: e.key, record: record });
          hide();
        }}
        mode="inline"
        items={menuItem}
      />
    </div>
  );

  return (
    <PopoverComponent
      dc_code={record.dc_code}
      content={content}
      open={open}
      handleOpenChange={handleOpenChange}
    />
  );
};

export const PopoverSyncAction = ({ record, onAction }: SyncProps) => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const content = (
    <div>
      <Menu
        data-test-id={`menu_${record.dc_code}`}
        style={{ border: "none", width: "100%" }}
        onClick={(e) => {
          onAction({ type: e.key, record: record });
          hide();
        }}
        mode="inline"
        items={[{ key: "download_data", label: "ดาวน์โหลดข้อมูล" }]}
      />
    </div>
  );

  return (
    <PopoverComponent
      dc_code={record.dc_code}
      content={content}
      open={open}
      handleOpenChange={handleOpenChange}
    />
  );
};

interface ComponentProps {
  open: boolean;
  dc_code: string;
  content: React.ReactNode;
  handleOpenChange: (newOpen: boolean) => void;
}

const PopoverComponent = ({ dc_code, content, open, handleOpenChange }: ComponentProps) => {
  return (
    <Popover
      placement="left"
      content={content}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      data-testid={`action_${dc_code}`}
    >
      <a data-test-id={`action_${dc_code}`}>
        <MoreOutlined />
      </a>
    </Popover>
  );
};
