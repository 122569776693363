import { useRef, useState } from "react";
import { InfoCircleOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, Tooltip } from "antd";

interface Props {
  defaultRadius?: number;
  min?: number;
  max?: number;
  onChange: (radius: number) => void;
}

const InputRadius = ({ defaultRadius = 10, min = 10, max = 200, onChange }: Props) => {
  const [radius, setRadius] = useState<string>(defaultRadius.toString());
  const inputRef = useRef<any>(null);

  const calculateRadius = (value: number, operator: "add" | "minus") => {
    if (operator === "add" && parseInt(radius) < max) {
      const newRadius = parseInt(radius) + value;
      if (newRadius > max) {
        setRadius(max.toString());
        onChange(max);
      } else {
        setRadius(newRadius + "");
        onChange(newRadius);
      }
    }
    if (operator === "minus" && parseInt(radius) > min) {
      const newRadius = parseInt(radius) - value;
      if (newRadius < min) {
        setRadius(min.toString());
        onChange(min);
      } else {
        setRadius(newRadius + "");
        onChange(newRadius);
      }
    }
  };

  const tooltipTitle = (
    <div style={{ padding: "8px 12px" }}>
      การตั้งค่ารัศมีการค้นหารถ
      <ul>
        <li>ต่ำสุด 10 กิโลเมตร</li>
        <li>สูงสุด 200 กิโลเมตร</li>
      </ul>
    </div>
  );

  return (
    <div className="radius-dc">
      <Button
        className="radius-step-button"
        style={{ marginRight: "8px" }}
        onClick={() => calculateRadius(10, "minus")}
        disabled={radius === min.toString()}
      >
        <MinusOutlined />
      </Button>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ fontSize: "12px" }}>รัศมี (กิโลเมตร)</div>
          <Tooltip title={tooltipTitle} color="#fff" overlayInnerStyle={{ color: "#000" }}>
            <InfoCircleOutlined style={{ marginLeft: "8px", color: "#757575" }} />
          </Tooltip>
        </div>
        <Input
          ref={inputRef}
          defaultValue={radius}
          value={radius}
          onChange={(e) => {
            const value = e.target.value;
            if (value.length > 0) {
              const reg = new RegExp("^[0-9]+$");
              if (!reg.test(value)) {
                return false;
              }
            }
            setRadius(e.target.value);
          }}
          onBlur={() => {
            if (parseInt(radius) < min || radius.length === 0) {
              setRadius(min.toString());
              onChange(min);
            } else if (parseInt(radius) > max) {
              setRadius(max.toString());
              onChange(max);
            } else {
              setRadius(parseInt(radius).toString());
              onChange(parseInt(radius));
            }
          }}
          onPressEnter={() => {
            if (inputRef.current) {
              inputRef.current.blur();
            }
          }}
        />
      </div>
      <Button
        className="radius-step-button"
        style={{ marginLeft: "8px" }}
        onClick={() => calculateRadius(10, "add")}
        disabled={radius === max.toString()}
      >
        <PlusOutlined />
      </Button>
    </div>
  );
};

export default InputRadius;
