import "./style.scss";
import React from "react";
import { Col, Divider, Popover, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

type CardComponentProps = {
  size: "big" | "small";
  title: string;
  content: string | React.ReactNode;
  children?: React.ReactNode;
  detailProps?: DetailSectionProps[];
  popOverContent?: React.ReactNode;
  color?: string;
  unit?: string;
  loading: boolean;
  dataTestId?: string;
  loadingPosition?: "title" | "content";
  titleClass?: string;
  contentClass?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export type DetailSectionProps = {
  title: string;
  content: number | null;
};

function CardComponent({
  title,
  content,
  children,
  detailProps,
  popOverContent,
  color,
  unit,
  loading,
  dataTestId,
  loadingPosition = "content",
  titleClass = "",
  contentClass = "",
  onClick,
}: CardComponentProps) {
  return (
    <div
      className={`card-component ${detailProps ? "has-detail" : "no-detail"} ${
        loadingPosition === "title"
          ? Number(title) > 0 && onClick
            ? "clickable"
            : ""
          : Number(content) > 0 && onClick
          ? "clickable"
          : ""
      }`}
      style={{ backgroundColor: `${color}` }}
      data-testid={dataTestId}
      onClick={
        loadingPosition === "title"
          ? Number(title) > 0
            ? onClick
            : undefined
          : Number(content) > 0
          ? onClick
          : undefined
      }
    >
      <div className="card-component-title">
        {loadingPosition === "title" ? (
          loading ? (
            <Spin></Spin>
          ) : (
            <p className={titleClass}>{title}</p>
          )
        ) : (
          <p className={titleClass}>{title}</p>
        )}
        {popOverContent ? (
          <Popover content={popOverContent} placement="topRight">
            <ExclamationCircleOutlined data-testid={`${dataTestId}-popover-icon`} />
          </Popover>
        ) : (
          <></>
        )}
      </div>
      <div className="card-component-content">
        {loadingPosition === "content" ? (
          loading ? (
            <Spin></Spin>
          ) : (
            <p data-testid={`${dataTestId}-content`} className={contentClass}>
              {content ?? "-"}
            </p>
          )
        ) : (
          <p data-testid={`${dataTestId}-content`} className={contentClass}>
            {content ?? "-"}
          </p>
        )}
      </div>
      <div>{loading ? <></> : children}</div>
      <div className="card-component-detail">
        {loading ? <></> : detailProps && DetailSection(detailProps, unit)}
      </div>
    </div>
  );
}

function DetailSection(list: DetailSectionProps[], unit?: string) {
  return (
    <>
      <Divider style={{ margin: "10px 0 10px 0 " }} />
      {list.map((item, index) => (
        <div key={index} className="detail-section">
          <p className="detail-section-title">{item.title}</p>
          <p className="detail-section-content">
            {item.content?.toString() ?? "-"} {unit ?? ""}
          </p>
        </div>
      ))}
    </>
  );
}

const DashboardCard: React.FC<CardComponentProps> = ({
  size,
  title,
  content,
  children,
  detailProps,
  popOverContent,
  color,
  unit,
  loading,
  dataTestId,
  onClick,
  loadingPosition,
  titleClass,
  contentClass,
}) => {
  return size === "big" ? (
    <Col xs={24} sm={24} md={12} lg={5} xl={5} xxl={5}>
      <CardComponent
        size="big"
        dataTestId={dataTestId}
        title={title}
        content={content}
        children={children}
        detailProps={detailProps}
        popOverContent={popOverContent}
        color={color}
        unit={unit}
        loading={loading}
        onClick={onClick}
      />
    </Col>
  ) : (
    <Col xs={24} sm={24} md={12} lg={5} xl={5} xxl={5} flex="auto">
      <CardComponent
        size="small"
        dataTestId={dataTestId}
        title={title}
        content={content}
        children={children}
        detailProps={detailProps}
        popOverContent={popOverContent}
        color={color}
        unit={unit}
        loading={loading}
        onClick={onClick}
        loadingPosition={loadingPosition}
        titleClass={titleClass}
        contentClass={contentClass}
      />
    </Col>
  );
};

export { DashboardCard };
