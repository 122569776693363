import { BookingRequestSearchValue } from "../../feature/BookingRequest/models/booking";
import { SearchEpodReportParams } from "../../feature/PodReport/models/epodReport";

export type ConfigEpodReportState = {
  search: SearchEpodReportParams | undefined;
  sorting: EpodReportSorting[];
  activePage: number;
  pageSize: number;
  filter: string;
};

export type EpodReportSorting = {
  columnKey: string;
  value: "descend" | "ascend";
};

export type ConfigBookingRequestState = {
  search: BookingRequestSearchValue | undefined;
  activePage: number;
  pageSize: number;
};

const setConfigPodReportState = (condition: ConfigEpodReportState) => {
  const timestamp = new Date().getTime();
  const podReportState = {
    condition,
    timestamp,
  };
  sessionStorage.setItem("podReportState", JSON.stringify(podReportState));
};

const getConfigPodReportState = (): ConfigEpodReportState | null => {
  const podReportStateStr = sessionStorage.getItem("podReportState");
  if (podReportStateStr) {
    const podReportState: {
      condition: ConfigEpodReportState;
      timestamp: number;
    } = JSON.parse(podReportStateStr);

    // Check if timestamp is less than 1 hour
    if (new Date().getTime() - podReportState.timestamp < 60 * 60 * 1000) {
      return podReportState.condition;
    } else {
      sessionStorage.removeItem("podReportState");
    }
  }
  return null;
};

const setDCMaster = (dcLists: string[]) => {
  const lists = dcLists.map((v) => ({ value: v, label: v }));
  sessionStorage.setItem("dcMaster", JSON.stringify(lists));
};

const getDCMaster = (): { value: string; label: string }[] => {
  const dcMaster = sessionStorage.getItem("dcMaster");
  if (dcMaster) {
    return JSON.parse(dcMaster);
  } else {
    return [];
  }
};

const removeDCMaster = () => {
  sessionStorage.removeItem("dcMaster");
};

const setConfigBookingRequestState = (condition: ConfigBookingRequestState) => {
  const timestamp = new Date().getTime();
  const bookingRequestState = {
    condition,
    timestamp,
  };
  sessionStorage.setItem("bookingRequestState", JSON.stringify(bookingRequestState));
};

const getConfigBookingRequestState = (): ConfigBookingRequestState | null => {
  const bookingRequestStateStr = sessionStorage.getItem("bookingRequestState");
  if (bookingRequestStateStr) {
    const bookingRequestState: {
      condition: ConfigBookingRequestState;
      timestamp: number;
    } = JSON.parse(bookingRequestStateStr);

    // Check if timestamp is less than 1 hour
    if (new Date().getTime() - bookingRequestState.timestamp < 60 * 60 * 1000) {
      return bookingRequestState.condition;
    } else {
      sessionStorage.removeItem("bookingRequestState");
    }
  }
  return null;
};

const sessionStorageSerivce = {
  setConfigPodReportState,
  getConfigPodReportState,
  setDCMaster,
  getDCMaster,
  removeDCMaster,
  setConfigBookingRequestState,
  getConfigBookingRequestState,
};

export default sessionStorageSerivce;
