export const provincesOptions = [
  { value: "ALL", label: "ALL" },
  { value: "กรุงเทพมหานคร", label: "กรุงเทพมหานคร" },
  { value: "กระบี่", label: "กระบี่" },
  { value: "กาญจนบุรี", label: "กาญจนบุรี" },
  { value: "กาฬสินธุ์", label: "กาฬสินธุ์" },
  { value: "กำแพงเพชร", label: "กำแพงเพชร" },
  { value: "ขอนแก่น", label: "ขอนแก่น" },
  { value: "จันทบุรี", label: "จันทบุรี" },
  { value: "ฉะเชิงเทรา", label: "ฉะเชิงเทรา" },
  { value: "ชลบุรี", label: "ชลบุรี" },
  { value: "ชัยนาท", label: "ชัยนาท" },
  { value: "ชัยภูมิ", label: "ชัยภูมิ" },
  { value: "ชุมพร", label: "ชุมพร" },
  { value: "เชียงใหม่", label: "เชียงใหม่" },
  { value: "เชียงราย", label: "เชียงราย" },
  { value: "ตรัง", label: "ตรัง" },
  { value: "ตราด", label: "ตราด" },
  { value: "ตาก", label: "ตาก" },
  { value: "นครนายก", label: "นครนายก" },
  { value: "นครปฐม", label: "นครปฐม" },
  { value: "นครพนม", label: "นครพนม" },
  { value: "นครราชสีมา", label: "นครราชสีมา" },
  { value: "นครศรีธรรมราช", label: "นครศรีธรรมราช" },
  { value: "นครสวรรค์", label: "นครสวรรค์" },
  { value: "นนทบุรี", label: "นนทบุรี" },
  { value: "นราธิวาส", label: "นราธิวาส" },
  { value: "น่าน", label: "น่าน" },
  { value: "บึงกาฬ", label: "บึงกาฬ" },
  { value: "บุรีรัมย์", label: "บุรีรัมย์" },
  { value: "ปทุมธานี", label: "ปทุมธานี" },
  { value: "ประจวบคีรีขันธ์", label: "ประจวบคีรีขันธ์" },
  { value: "ปราจีนบุรี", label: "ปราจีนบุรี" },
  { value: "ปัตตานี", label: "ปัตตานี" },
  { value: "พระนครศรีอยุธยา", label: "พระนครศรีอยุธยา" },
  { value: "พะเยา", label: "พะเยา" },
  { value: "พังงา", label: "พังงา" },
  { value: "พัทลุง", label: "พัทลุง" },
  { value: "พิจิตร", label: "พิจิตร" },
  { value: "พิษณุโลก", label: "พิษณุโลก" },
  { value: "เพชรบุรี", label: "เพชรบุรี" },
  { value: "เพชรบูรณ์", label: "เพชรบูรณ์" },
  { value: "แพร่", label: "แพร่" },
  { value: "ภูเก็ต", label: "ภูเก็ต" },
  { value: "มหาสารคาม", label: "มหาสารคาม" },
  { value: "มุกดาหาร", label: "มุกดาหาร" },
  { value: "แม่ฮ่องสอน", label: "แม่ฮ่องสอน" },
  { value: "ยโสธร", label: "ยโสธร" },
  { value: "ยะลา", label: "ยะลา" },
  { value: "ร้อยเอ็ด", label: "ร้อยเอ็ด" },
  { value: "ระนอง", label: "ระนอง" },
  { value: "ระยอง", label: "ระยอง" },
  { value: "ราชบุรี", label: "ราชบุรี" },
  { value: "ลพบุรี", label: "ลพบุรี" },
  { value: "ลำปาง", label: "ลำปาง" },
  { value: "ลำพูน", label: "ลำพูน" },
  { value: "เลย", label: "เลย" },
  { value: "ศรีสะเกษ", label: "ศรีสะเกษ" },
  { value: "สกลนคร", label: "สกลนคร" },
  { value: "สงขลา", label: "สงขลา" },
  { value: "สตูล", label: "สตูล" },
  { value: "สมุทรปราการ", label: "สมุทรปราการ" },
  { value: "สมุทรสงคราม", label: "สมุทรสงคราม" },
  { value: "สมุทรสาคร", label: "สมุทรสาคร" },
  { value: "สระแก้ว", label: "สระแก้ว" },
  { value: "สระบุรี", label: "สระบุรี" },
  { value: "สิงห์บุรี", label: "สิงห์บุรี" },
  { value: "สุโขทัย", label: "สุโขทัย" },
  { value: "สุพรรณบุรี", label: "สุพรรณบุรี" },
  { value: "สุราษฎร์ธานี", label: "สุราษฎร์ธานี" },
  { value: "สุรินทร์", label: "สุรินทร์" },
  { value: "หนองคาย", label: "หนองคาย" },
  { value: "หนองบัวลำภู", label: "หนองบัวลำภู" },
  { value: "อ่างทอง", label: "อ่างทอง" },
  { value: "อำนาจเจริญ", label: "อำนาจเจริญ" },
  { value: "อุดรธานี", label: "อุดรธานี" },
  { value: "อุตรดิตถ์", label: "อุตรดิตถ์" },
  { value: "อุทัยธานี", label: "อุทัยธานี" },
  { value: "อุบลราชธาน", label: "อุบลราชธาน" },
];
