import { Button, Modal } from "antd";

type ModalEvent = {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  loading: boolean;
  email: string;
};

export default function ModalConfirm({ open, onOk, onCancel, email, loading }: ModalEvent) {
  const customFooter = (
    <>
      <Button style={{ marginRight: 12 }}>Cancel</Button>
      <Button type="primary" loading={loading} onClick={onOk} data-testid="forgot-password-confirm-btn">
        OK
      </Button>
    </>
  );

  return (
    <Modal centered title="Forgot Password" open={open} onOk={onOk} onCancel={onCancel} footer={customFooter}>
      <p>
        ระบบจะทำการส่งอีเมลสำหรับการ Reset Password ไปที่อีเมล <span>{email}</span> กรุณายืนยันเพื่อทำรายการ
      </p>
    </Modal>
  );
}
