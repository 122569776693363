import { PaginationProps, Table, TableProps, Tag } from "antd";
import { ButtonDs } from "design-system";
import { FormatDate } from "../../../utils/GetDateNow";
import StatusTag from "../../../shared/component/StatusTag";
import { TaskMonitoring } from "../models/task";

interface Props {
  dataSource: TaskMonitoring[] | undefined;
  isLoading: boolean;
  onChangePage: (page: number, pageSize: number) => void;
  currentPage: number;
  pageSize: number;
}

const DataTable = ({ dataSource, isLoading, onChangePage, currentPage, pageSize }: Props) => {
  // const navigate = useNavigate();

  const columns: TableProps<TaskMonitoring>["columns"] = [
    {
      title: "ลำดับ",
      key: "no",
      align: "center",
      width: 50,
      fixed: "left",
      render: (_, __, index) => <>{(currentPage - 1) * pageSize + (index + 1)}</>,
    },
    {
      title: "รหัสงานขนส่ง",
      dataIndex: "booking_id",
      key: "booking_id",
      fixed: "left",
      render: (text) => {
        return <>{text ?? "-"}</>;
      },
    },
    {
      title: "จุดรับสินค้า",
      dataIndex: "pickup_info",
      key: "pickup_info",
      fixed: "left",
      render: (_, record) => {
        return <>{record.pickup_info.address_name ?? "-"}</>;
      },
    },
    {
      title: "จุดส่งสินค้า",
      dataIndex: "dropoff_info",
      key: "dropoff_info",
      fixed: "left",
      render: (_, record) => {
        return <>{record.dropoff_info.address_name ?? "-"}</>;
      },
    },
    {
      title: "วันที่เข้ารับสินค้า",
      dataIndex: "pickup_date",
      key: "pickup_date",
      render: (_, record) => {
        return <>{FormatDate(record.pickup_info.pickup_date, "DD-MM-YYYY, HH:mm") ?? "-"}</>;
      },
    },
    {
      title: "วันที่ส่งสินค้า",
      dataIndex: "dropoff_date",
      key: "dropoff_date",
      render: (_, record) => {
        return <>{FormatDate(record.dropoff_info.dropoff_date, "DD-MM-YYYY, HH:mm") ?? "-"}</>;
      },
    },
    {
      title: "ประเภทรถ",
      dataIndex: "truck_type",
      key: "truck_type",
      render: (text) => {
        return <>{text ?? "-"}</>;
      },
    },
    {
      title: "ป้ายทะเบียน",
      dataIndex: "truck_plate",
      key: "truck_plate",
      render: (_, record) => {
        return (
          <>
            {record.truck_plate ?? "-"}
            {record.trailer_plate ? ` / ${record.trailer_plate}` : ""}
          </>
        );
      },
    },
    {
      title: "พนักงานขับรถ",
      dataIndex: "driver_name",
      key: "driver_name",
      render: (text) => {
        return <>{text ?? "-"}</>;
      },
    },
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "driver_mobile",
      key: "driver_mobile",
      render: (text) => {
        return <>{text ?? "-"}</>;
      },
    },
    {
      title: "จุดรับ/ส่ง",
      dataIndex: "taskType",
      key: "taskType",
      align: "center",
      fixed: "right",

      render: (_, record) => {
        return <TaskTypeTag status={record.driver_status} />;
      },
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      align: "center",
      fixed: "right",

      render: (_, record) => {
        return <StatusTag status={record.status} />;
      },
    },
    {
      title: "อัปเดตล่าสุด",
      dataIndex: "updated_at",
      key: "updated_at",
      fixed: "right",

      render: (text) => {
        return <>{FormatDate(text, "DD-MM-YYYY, HH:mm") ?? "-"}</>;
      },
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      align: "center",
      render: () => (
        <ButtonDs
          size="middle"
          type="default"
          onClick={() => {
            // navigate(`/booking-request/${record.booking_id}`);
          }}
        >
          รายละเอียด
        </ButtonDs>
      ),
      width: 150,
    },
  ];

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    onChangePage(page, pageSize);
  };

  const TaskTypeTag = ({ status }: { status: string }) => {
    let tagInfo = {
      text: "N/A",
      color: "default",
    };
    switch (status) {
      case "รับ":
        tagInfo = {
          text: "รับ",
          color: "gold",
        };
        break;
      case "ส่ง":
        tagInfo = {
          text: "ส่ง",
          color: "geekblue",
        };
        break;
    }

    return (
      <Tag color={tagInfo.color} style={{ fontSize: "12px", padding: "0px 8px" }}>
        {tagInfo.text}
      </Tag>
    );
  };

  return (
    <Table
      scroll={{ x: "auto" }}
      loading={isLoading}
      rowKey={(record) => record.booking_id + record.dropoff_info.address_name + record.truck_plate}
      columns={columns}
      dataSource={isLoading ? [] : dataSource}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ["15", "30", "50", "100"],
        pageSize: pageSize,
        current: currentPage,
        onChange: onChange,
      }}
    />
  );
};

export default DataTable;
