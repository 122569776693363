import React from "react";
import "../style.scss";
import { Card, Col, Row } from "antd";
import { DetailInfo } from "../Model/DetailInfo";

interface Props {
  shopDataSource: DetailInfo | null;
  truckDataSource: DetailInfo | null;
}

const CardInfo = ({ shopDataSource, truckDataSource }: Props) => {
  return (
    <>
      {shopDataSource && truckDataSource && (
        <Card bordered={true} className="card-border">
          <div style={{ display: "flex" }}>
            <div style={{ width: "50%", padding: "36px" }} data-testid={`report-detail-store-data`}>
              <h2>{shopDataSource.title}</h2>
              <Row gutter={[16, 16]}>
                {shopDataSource.dataSource.map((item, index) => (
                  <React.Fragment key={index}>
                    <Col span={8} className="title-label">
                      {item.title}
                    </Col>
                    <Col span={16} className="text-label">
                      {item.value}
                    </Col>
                  </React.Fragment>
                ))}
              </Row>
            </div>
            <div
              style={{ width: "50%", borderLeft: "1px solid #D1D4D9", padding: "36px" }}
              data-testid={`report-detail-truck-data`}
            >
              <h2>{truckDataSource.title}</h2>
              <Row gutter={[16, 16]}>
                {truckDataSource.dataSource.map((item, index) => (
                  <React.Fragment key={index}>
                    <Col span={8} className="title-label">
                      {item.title}
                    </Col>
                    <Col span={16} className="text-label">
                      {item.value}
                    </Col>
                  </React.Fragment>
                ))}
              </Row>
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default CardInfo;
