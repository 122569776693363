import { Button, DatePicker, Modal, Select, message } from "antd";
import { Form, Input, TimePicker } from "antd";
import { Driver } from "../Driver";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { editTrackingDriver, editTrackingDriverError, isEditLoading } from "../Slice";
import { useAppDispatch } from "../../../shared/store/store";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

type ModalEditTransportDetailProps = {
  truck_plate_number: string;
  driver_mobile_number: string;
  truck_type: string;
  sla: string;
  planned_date: string;
  planned_time: string;
};

export default function ModalEditTransportDetail({
  open,
  onClose,
  driverLocation,
}: {
  open: boolean;
  onClose: () => void;
  driverLocation: Driver | null;
}) {
  const [form] = Form.useForm();
  const { tracking_driver_id } = useParams();
  const isLoadingEditDriver = useSelector(isEditLoading);
  const editResponseError = useSelector(editTrackingDriverError);
  const dispatch = useAppDispatch();
  const { Option } = Select;
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const format = "HH:mm";
  const dateFormat = "DD-MM-YYYY";

  const handleOk = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const displayError = (rspCode: string) => {
    switch (rspCode) {
      case "invalid_mobile_phone":
        return messageApi.error("กรุณากรอกเบอร์ติดต่อให้ถูกต้อง");
      case "invalid_truck_plate_number":
        return messageApi.error("กรุณากรอกป้ายทะเบียนให้ถูกต้อง");
      default:
        return messageApi.error("แก้ไขข้อมูลขนส่งไม่สำเร็จ");
    }
  };

  useEffect(() => {
    if (editResponseError) {
      displayError(editResponseError.code || "");
    }
  }, [editResponseError]);

  const onFinish = async (values: ModalEditTransportDetailProps) => {
    const editTransportDetailParams = {
      truck_plate_number: values.truck_plate_number,
      driver_mobile_number: values.driver_mobile_number,
      truck_type: values.truck_type,
      planned_date: dayjs(form.getFieldValue("planned_date")).format("YYYY-MM-DD"),
      planned_time: dayjs(form.getFieldValue("planned_time")).format("HH:mm"),
      sla: values.sla,
      tracking_driver_id: tracking_driver_id,
    };

    const response = await dispatch(editTrackingDriver(editTransportDetailParams));
    if (response.type === "request/editTrackingDriver/fulfilled") {
      onClose();
      message.success("แก้ไขข้อมูลขนส่งสำเร็จ");
      navigate("/truck-monitor");
    }
  };

  return (
    <Modal
      title="แก้ไขข้อมูลขนส่ง"
      open={open}
      closable={false}
      className="modal-edit-transport-detail"
      footer={[
        <Button key="close" name="close" onClick={handleCancel}>
          Close
        </Button>,
        <Button key="submit" type="primary" name="submit" onClick={handleOk} loading={isLoadingEditDriver}>
          OK
        </Button>,
      ]}
    >
      <Form
        form={form}
        initialValues={{
          truck_plate_number: driverLocation?.truck_plate_number || "",
          driver_mobile_number: driverLocation?.driver_mobile_number || "",
          load: driverLocation?.load || "",
          truck_type: driverLocation?.truck_type || "",
          sla: driverLocation?.sla || "",
          planned_date: dayjs(driverLocation?.planned_date, dateFormat) || "",
          planned_time: dayjs(driverLocation?.planned_time, format) || "",
        }}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          key={"truck_plate_number"}
          label="ป้ายทะเบียน"
          name="truck_plate_number"
          rules={[
            {
              required: true,
              message: "กรุณากรอกป้ายทะเบียน",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          key={"driver_mobile_number"}
          label="เบอร์ติดต่อ"
          name="driver_mobile_number"
          rules={[
            {
              required: true,
              message: "กรุณากรอกเบอร์ติดต่อ",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          key={"truck_type"}
          label="ประเภทรถ"
          name="truck_type"
          rules={[
            {
              required: true,
              message: "กรุณาเลือกประเภทรถ",
            },
          ]}
        >
          <Select allowClear showSearch style={{ width: "100px" }} data-testid="select_truck_type">
            <Option value="10W">10W</Option>
            <Option value="10WT">10WT</Option>
            <Option value="6W">6W</Option>
            <Option value="6WT">6WT</Option>
            <Option value="4WCR">4WCR</Option>
            <Option value="4WJ">4WJ</Option>
            <Option value="4W">4W</Option>
          </Select>
        </Form.Item>

        <Form.Item
          key={"sla"}
          label="SLA"
          name="sla"
          rules={[
            {
              required: true,
              message: "กรุณาเลือก sla",
            },
          ]}
        >
          <Select allowClear showSearch style={{ width: "100px" }} data-testid="select_sla">
            <Option value="D+0">D+0</Option>
            <Option value="D+1">D+1</Option>
          </Select>
        </Form.Item>

        <Form.Item
          key={"planned_date"}
          label="Planned date"
          name="planned_date"
          rules={[
            {
              required: true,
              message: "กรุณาเลือก Planned date",
            },
          ]}
        >
          <DatePicker
            format={"DD-MM-YYYY"}
            value={dayjs(driverLocation?.planned_date, dateFormat) || ""}
            defaultPickerValue={dayjs(driverLocation?.planned_date, dateFormat) || ""}
          />
        </Form.Item>

        <Form.Item
          key={"planned_time"}
          label="Planned time"
          name="planned_time"
          rules={[
            {
              required: true,
              message: "กรุณาเลือก Planned time",
            },
          ]}
        >
          <TimePicker
            format={format}
            defaultPickerValue={dayjs(driverLocation?.planned_time, format) || ""}
          />
        </Form.Item>
      </Form>
      {contextHolder}
    </Modal>
  );
}
