import { Badge, Col, Progress, Table, Tag, Image, Space, Row } from "antd";
import PodReportFormFilter from "./PodReportFormFilter";
import DownloadCSVFile from "./DownloadCSVFile";
import { EpodReport, SearchEpodReportParams } from "../models/epodReport";
import { ConfigEpodReportState } from "../../../shared/service/Sessionstorage.service";
import { FormatDate } from "../../../utils/GetDateNow";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { FormInstance } from "antd/lib/form/Form";
import DropdownMenu from "../../../shared/component/DropDownMenu";
import { useNavigate } from "react-router-dom";
import PodReportService from "../services/pod-report-service";
import {
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
interface Props {
  dataSource: EpodReport[];
  form: FormInstance<any>;
  dataDisplay: EpodReport[] | undefined;
  isLoading: boolean;
  configState: ConfigEpodReportState | null;
  searchParam: SearchEpodReportParams;
  isToday: boolean;
  totalTask: number;
  pagination: Pagination;
  onPageChange: (pagination: any, filters: any, sorter: any) => void;
  onSearch: (param: SearchEpodReportParams) => void;
  onSearchFilter: (searchText: string) => void;
}
const EPODReportDataTable = ({
  form,
  dataSource,
  dataDisplay,
  isLoading,
  configState,
  searchParam,
  isToday,
  totalTask,
  pagination,
  onPageChange,
  onSearchFilter,
  onSearch,
}: Props) => {
  const navigate = useNavigate();

  const [totalReport, setTotalReport] = useState<number>(0);
  const [readReport, setReadReport] = useState<number>(0);
  const [readPercent, setReadPercent] = useState<number>(0);
  const [completionPercent, setCompletionPercent] = useState<number>(0);

  useEffect(() => {
    const getPercentage = (dataSource: EpodReport[]) => {
      const total = dataSource.length;
      const read = dataSource.filter((x) => x.mark_read).length;

      const calReadPercentage = calculatePercentage(read, total);
      const calTotalPercentage = calculatePercentage(total, totalTask);

      setTotalReport(total);
      setReadReport(read);

      setReadPercent(calReadPercentage);
      setCompletionPercent(calTotalPercentage);
    };

    const calculatePercentage = (a: number, b: number) => {
      if (b < a) {
        return 0;
      } else {
        return Math.round((a / b) * 100) || 0;
      }
    };

    getPercentage(dataSource);
  }, [dataSource, totalTask]);

  const getSortValueByColumnKey = (columnKey: string) => {
    const sortValue = configState?.sorting.find((sort) => sort.columnKey === columnKey);
    return sortValue ? sortValue.value : null;
  };

  const columns: ColumnsType<EpodReport> = [
    {
      title: <div id="sort_report_status"></div>,
      dataIndex: "report_status",
      key: "report_status",
      width: 50,
      align: "center",
      defaultSortOrder: getSortValueByColumnKey("report_status") || "ascend",
      sorter: {
        compare: (a, b) => a.report_status - b.report_status,
        multiple: 3,
      },
      render: (_, record) => {
        return record.confirm_status === "Issue" ? (
          statusIssueReport()
        ) : record.confirm_status === "Unread" ? (
          <Badge status="processing" />
        ) : null;
      },
    },
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      width: 60,
      render: (_, __, index) => <>{(pagination.current - 1) * pagination.pageSize + (index + 1)}</>,
    },
    {
      title: <div id="sort_truck_plate_number">ป้ายทะเบียน</div>,
      dataIndex: "truck_plate_number",
      key: "truck_plate_number",
      defaultSortOrder: getSortValueByColumnKey("truck_plate_number") || "descend",
      sorter: {
        compare: (a, b) => a.truck_plate_number.localeCompare(b.truck_plate_number),
        multiple: 2,
      },
    },
    {
      title: "DC",
      dataIndex: "dc_code",
      key: "dc_code",
    },
    {
      title: "Deliver SLA",
      dataIndex: "sla",
      key: "sla",
      align: "center",
    },
    {
      title: "Planned Date",
      dataIndex: "planned_date",
      key: "planned_date",
    },
    {
      title: "Stop",
      dataIndex: "stop",
      key: "stop",
      align: "center",
    },
    {
      title: "Load",
      dataIndex: "load",
      key: "load",
      align: "center",
      render: (_, record) => {
        return <>{record.load ? record.load : "-"}</>;
      },
    },
    {
      title: "ร้านที่ส่งสำเร็จ",
      dataIndex: "store_name",
      key: "store_name",
      width: "150px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, record) => {
        return (
          <Tag
            bordered={false}
            className={`status-tag ${record.status === "exception" ? "error" : "success"}`}
          >
            <span className="tag-text">{record.status}</span>
          </Tag>
        );
      },
    },
    {
      title: <div id="sort_updated_at">Update date</div>,
      dataIndex: "updated_at",
      key: "updated_at",
      width: 150,
      defaultSortOrder: getSortValueByColumnKey("updated_at") || "descend",
      sorter: {
        compare: (a, b) => {
          return (
            Date.parse(dayjs(a.update_date).format("MM/DD/YYYY HH:mm:ss")) -
            Date.parse(dayjs(b.update_date).format("MM/DD/YYYY HH:mm:ss"))
          );
        },
        multiple: 1,
      },
      render: (_, record) => {
        return FormatDate(record.update_date, "DD-MM-YYYY HH:mm");
      },
    },
    {
      title: "รูปภาพ",
      dataIndex: "pictures",
      key: "pictures",
      render: (_, record) => {
        return (
          record.epod_file && (
            <div style={{ overflow: "auto", display: "inline-flex" }}>
              <Image.PreviewGroup>
                {record.signed_url &&
                  record.signed_url.map((data, index) => {
                    return (
                      <div key={index} style={{ marginRight: "8px" }}>
                        <Image
                          width={35}
                          height={35}
                          src={data}
                          key={index}
                          data-testid="epod-picture"
                          preview={{
                            toolbarRender: (
                              _,
                              {
                                transform: { scale },
                                actions: {
                                  onFlipY,
                                  onFlipX,
                                  onRotateLeft,
                                  onRotateRight,
                                  onZoomOut,
                                  onZoomIn,
                                },
                              },
                            ) => (
                              <Space size={12} className="toolbar-wrapper">
                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                <SwapOutlined onClick={onFlipX} />
                                <RotateLeftOutlined onClick={onRotateLeft} />
                                <RotateRightOutlined onClick={onRotateRight} />
                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                              </Space>
                            ),
                          }}
                        />
                      </div>
                    );
                  })}
              </Image.PreviewGroup>
            </div>
          )
        );
      },
    },
    {
      title: <>&nbsp;</>,
      key: "menu",
      fixed: "right",
      width: 50,
      align: "center",
      render: (_, record) => {
        return <DropdownMenu dropdownItems={dropdownItems(record)} data-testid="dropdown-menu" />;
      },
    },
  ];

  const dropdownItems = (record: EpodReport) => {
    const task_id = record.task_id;

    const onClickViewReport = () => {
      const path = `${record.trip_id}/${record.store_number}`;
      navigate(`/report-detail/${path}?report&viewmode=${!isToday}`);
    };

    const viewReportMenu = (onClick: () => void) => {
      return {
        key: "view_report",
        label: "ดูรายละเอียด",
        onClick: onClick,
      };
    };

    return isToday
      ? [
          viewReportMenu(() => {
            PodReportService.markReadReport(task_id, true);
            onClickViewReport();
          }),
          {
            key: "mark_read",
            label: "ทำเครื่องหมายยังไม่ได้อ่าน",
            onClick: async () => {
              PodReportService.markReadReport(task_id, false);
              onSearch(searchParam);
            },
          },
        ]
      : [viewReportMenu(onClickViewReport)];
  };

  const taskViewPercentage = () => {
    const isDisable = searchParam.dc_code === "" && searchParam.planned_arrival === "";
    return (
      <div className="task-percentage">
        <div className={`show-detail ${isDisable && "disable"}`}>
          <h4 data-testid="view-percent">{readPercent}% Views</h4>
          <span>
            ดูแล้ว {readReport} จาก {totalReport} รายงาน
          </span>
        </div>
        <Progress
          className="progress-bar"
          percent={isDisable ? 0 : readPercent}
          strokeColor={"#41a447"}
          showInfo={false}
          style={{ marginBottom: 0 }}
        />
      </div>
    );
  };

  const taskCompletedPercentage = () => {
    const isDisable = searchParam.dc_code === "" && searchParam.planned_arrival === "";
    return (
      <div className="task-completed-percentage">
        <div className={`show-detail ${isDisable && "disable"}`}>
          <h4 data-testid="view-percent">{completionPercent}% Completions</h4>
          <span>
            ส่งแล้ว {totalReport} จาก {totalTask} รายงาน
          </span>
        </div>
        <Progress
          className="progress-bar"
          percent={isDisable ? 0 : completionPercent}
          strokeColor={"#41a447"}
          showInfo={false}
          style={{ marginBottom: 0 }}
        />
      </div>
    );
  };

  return (
    <div>
      <Col className="table-title" span={24}>
        <div className="title-left">
          <h3>ข้อมูล PoD Report</h3>
          {searchParam.planned_arrival && (
            <p>ข้อมูลจัดส่งของวันที่ : {FormatDate(searchParam.planned_arrival, "DD-MM-YYYY")}</p>
          )}
        </div>
        <Row justify="end">
          <div style={{ marginRight: "16px" }}>{taskCompletedPercentage()}</div>
          {taskViewPercentage()}
        </Row>
      </Col>
      <Col className="detail-table-top-content">
        <PodReportFormFilter form={form} onSearch={onSearchFilter} searchData={configState?.filter ?? ""} />
        <div className="top-right-content">
          <p data-testid="epod-report-total">ทั้งหมด {dataDisplay?.length ?? 0} รายการ</p>
          <DownloadCSVFile
            dataSource={dataDisplay ?? []}
            fileName={`PoDReport_${searchParam.dc_code}_${dayjs(searchParam.planned_arrival).format(
              "YYYYMMDD",
            )}.csv`}
          />
        </div>
      </Col>
      <Col>
        <Table
          className="epod-report-table"
          scroll={{ x: true }}
          loading={isLoading}
          rowKey="index"
          rowClassName={(record) => (record.report_status === 2 && isToday ? "unread-row" : "")}
          columns={columns}
          dataSource={dataDisplay}
          onChange={onPageChange}
          pagination={{
            showSizeChanger: true,
            pageSize: pagination.pageSize,
            current: pagination.current,
            defaultCurrent: 1,
            defaultPageSize: pagination.pageSize,
            onChange: pagination.onChange,
          }}
          sortDirections={["ascend", "descend", null]}
        />
      </Col>
    </div>
  );
};

export default EPODReportDataTable;

type Pagination = {
  current: number;
  pageSize: number;
  onChange: (newCurrent: number, newPageSize: number) => void;
};

export const statusIssueReport = () => {
  return (
    <div className="status-issue-report">
      <p className="icon">!</p>
    </div>
  );
};
