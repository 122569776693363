import { AssignTruckRequest, Booking, BookingInfo, DownloadBookingRequestParams } from "../models/booking";

import { AxiosResponse } from "axios";
import { BehaviorSubject } from "rxjs";
import { httpClientTD } from "../../../shared/api/ApiClient";

const LIST_BOOKING_REQUEST = "/v2/bookings";
const ASSIGN_TRUCK = (booking_id: string) => `/v2/bookings/${booking_id}/assign-truck`;
const UPDATE_TRUCK = (booking_id: string) => `/v2/bookings/${booking_id}/update-truck`;
const DOWNLOAD_BOOKING_REQUEST = "/v2/bookings/download";

const getBookingRequest = (): Promise<Booking[]> =>
  httpClientTD.get(LIST_BOOKING_REQUEST).then((res) => res.data);

const getBookingDetail = (booking_id: string): Promise<AxiosResponse<BookingInfo>> => {
  return httpClientTD.get(`/v2/bookings/${booking_id}`);
};

const requestAssignTruck = (bookingID: string, param: AssignTruckRequest): Promise<AxiosResponse> =>
  httpClientTD.put(ASSIGN_TRUCK(bookingID), param);

const requestUpdateTruck = (bookingID: string, param: AssignTruckRequest): Promise<AxiosResponse> =>
  httpClientTD.put(UPDATE_TRUCK(bookingID), param);

const cancelTruck = (bookingID: string, reason: string): Promise<AxiosResponse<any>> =>
  httpClientTD.put(`/v2/bookings/${bookingID}/cancel`, { reason });

const downloadBookingRequest = (params: DownloadBookingRequestParams): Promise<AxiosResponse> =>
  httpClientTD.post(DOWNLOAD_BOOKING_REQUEST, params, {
    responseType: "blob",
  });

const signalReset = new BehaviorSubject<boolean>(false);

const truckAssigned = new BehaviorSubject<string | null>(null); // string = booking_id
const truckUpdated = new BehaviorSubject<string | null>(null);

const bookingReqestService = {
  getBookingRequest,
  getBookingDetail,
  requestAssignTruck,
  requestUpdateTruck,
  cancelTruck,
  downloadBookingRequest,
  signalReset,
  truckAssigned,
  truckUpdated,
};

export default bookingReqestService;
