import "./style.scss";

import { Booking, BookingRequestSearchValue, DownloadBookingRequestParams } from "./models/booking";
import { Card, Col, Row, notification } from "antd";
import { CheckCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import { FormatDate, GetDateNowBeforeFetchNew } from "../../utils/GetDateNow";
import SessionstorageService, {
  ConfigBookingRequestState,
} from "../../shared/service/Sessionstorage.service";
import { useEffect, useRef, useState } from "react";

import BookingRequestFormSearch from "./components/BookingRequestFormSearch";
import BookingRequestService from "./services/booking-request-service";
import { ButtonDs } from "design-system";
import { Content } from "antd/es/layout/layout";
import { DashboardCard } from "../../shared/component/CardComponent/DashboardComponent";
import DataTable from "./components/BookingRequestTable";
import { NotificationComponent } from "../../shared/component/NotificationComponent";
import StateService from "../../shared/service/State.service";
import TooltipComponent from "../../shared/component/TooltipComponent";

interface Dashboard {
  content: {
    totalBooking: number;
    pending: number;
    confirmed: number;
    canceled: number;
    rejected: number;
    [key: string]: number; // Index signature to allow indexing with a string
  };
  selectedCard: "pending" | "confirmed" | "canceled" | "rejected" | "all";
}

const BookingRequest = () => {
  const [dataSource, setDataSource] = useState<Booking[]>([]);
  const [filteredBooking, setFilteredBooking] = useState<Booking[]>([]);
  const [dataDisplay, setDataDisplay] = useState<Booking[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dashboard, setDashboard] = useState<Dashboard>({
    content: {
      totalBooking: 0,
      pending: 0,
      confirmed: 0,
      canceled: 0,
      rejected: 0,
    },
    selectedCard: "all",
  });
  const [searchValue, setSearchValue] = useState<BookingRequestSearchValue>();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(15);
  const stateConfig = useRef<ConfigBookingRequestState>();
  const [notificationApi, contextHolderNoti] = notification.useNotification();
  const [customerList, setCustomerList] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  useEffect(() => {
    StateService.initializeState();

    getConfig();

    setIsLoading(true);
    fetchBookingRequest();

    const intervalId = setInterval(() => {
      setIsLoading(true);
      fetchBookingRequest();
      getConfig();
    }, 2 * (1000 * 60)); // Fetch data every 2 minutes

    return () => {
      clearInterval(intervalId);
      BookingRequestService.truckAssigned.next(null);
      BookingRequestService.truckUpdated.next(null);
    };
  }, []);

  useEffect(() => {
    const config: ConfigBookingRequestState = {
      search: searchValue,
      pageSize: currentPageSize,
      activePage: currentPage,
    };
    SessionstorageService.setConfigBookingRequestState(config);
  }, [searchValue, currentPageSize, currentPage]);

  useEffect(() => {
    BookingRequestService.truckAssigned.subscribe((res) => {
      if (res) {
        notificationApi.success({
          message: "มอบหมายงานสำเร็จ !",
          description: "การมอบหมายงานเสร็จสิ้น",
          className: "success-notification",
          icon: <CheckCircleOutlined style={{ color: "#41A447" }} />,
          placement: "topRight",
        });
      }
    });

    BookingRequestService.truckUpdated.subscribe((res) => {
      if (res) {
        notificationApi.success({
          message: "อัพเดตงานสำเร็จ !",
          description: "การอัพเดตงานเสร็จสิ้น",
          className: "success-notification",
          icon: <CheckCircleOutlined style={{ color: "#41A447" }} />,
          placement: "topRight",
        });
      }
    });
  }, []);

  const getConfig = () => {
    const config = SessionstorageService.getConfigBookingRequestState();
    if (config) {
      stateConfig.current = config;
      if (stateConfig.current.search) {
        setSearchValue(stateConfig.current.search);
      }
      if (stateConfig.current.activePage) {
        setCurrentPage(stateConfig.current.activePage);
      }
      if (stateConfig.current.pageSize) {
        setCurrentPageSize(stateConfig.current.pageSize);
      }
    }
  };

  const setNewDashboard = (
    booking: Booking[] | null,
    selectedCard: "pending" | "confirmed" | "canceled" | "rejected" | "all",
  ) => {
    if (booking !== null) {
      const newDashboard: Dashboard = {
        content: {
          totalBooking: booking.length,
          pending: 0,
          confirmed: 0,
          canceled: 0,
          rejected: 0,
        },
        selectedCard: selectedCard,
      };

      booking.forEach((booking) => {
        newDashboard.content[booking.status] += 1;
      });

      setDashboard({
        ...newDashboard,
      });
    } else {
      setDashboard({
        ...dashboard,
        selectedCard: selectedCard,
      });
    }
  };

  const fetchBookingRequest = async () => {
    return BookingRequestService.getBookingRequest()
      .then((res) => {
        if (res) {
          setDataSource(res);
          const config = SessionstorageService.getConfigBookingRequestState();
          const filteredBookings = filterBooking(res, config?.search);
          const filteredNoStatus = filterBooking(res, { ...config?.search, status: "all" });
          setNewDashboard(filteredNoStatus, config?.search?.status || "all");
          setFilteredBooking(filteredNoStatus);
          setDataDisplay(filteredBookings);

          // get list customer
          const customerList = res
            .map((booking) => {
              return { label: booking.booking_by.company_name, value: booking.booking_by.company_id };
            })
            .filter((value, index, self) => self.findIndex((v) => v.value === value.value) === index);
          customerList.unshift({ label: "ทั้งหมด", value: "all" });
          setCustomerList(customerList);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterBooking = (
    bookings: Booking[],
    searchValue: BookingRequestSearchValue | undefined,
  ): Booking[] => {
    const customer = searchValue?.company_id;
    const truckType = searchValue?.truck_type;
    const bookingId = searchValue?.booking_id;
    const status = searchValue?.status;
    const formatPickupDate = searchValue?.pickup_date && FormatDate(searchValue.pickup_date, "DD-MM-YYYY");

    bookings = bookings.map((booking) => booking);

    if (formatPickupDate) {
      bookings = bookings.filter((booking) => {
        const bookingPickupDate = FormatDate(booking.pickup_date, "DD-MM-YYYY");
        return bookingPickupDate === formatPickupDate;
      });
    }
    if (bookingId && bookingId.length > 0) {
      bookings = bookings.filter((booking) => {
        return booking.booking_id === bookingId;
      });
    }
    if (customer && customer !== "all") {
      bookings = bookings.filter((booking) => {
        return booking.booking_by.company_id === customer;
      });
    }

    if (status && status !== "all") {
      bookings = bookings.filter((booking) => {
        return booking.status.toLocaleLowerCase() === status.toLowerCase();
      });
    }

    if (truckType && truckType !== "all") {
      bookings = bookings.filter((booking) => {
        return booking.truck_type.toLowerCase() === truckType.toLowerCase();
      });
    }

    return bookings;
  };

  const onSearch = (searchValue: BookingRequestSearchValue | undefined) => {
    setIsLoading(true);

    const filteredBookings = filterBooking(dataSource, searchValue);
    setFilteredBooking(filteredBookings);

    setNewDashboard(filteredBookings, "all");
    setDataDisplay(filteredBookings);
    setCurrentPage(1);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const onFilterStatus = (status: "pending" | "confirmed" | "canceled" | "rejected" | "all") => {
    const filteredBookings = filterBooking(filteredBooking, {
      status: status,
    });
    setNewDashboard(null, status);
    setDataDisplay(filteredBookings);
    setSearchValue({ ...searchValue, status });
  };

  const onSavedValue = (searchValue: BookingRequestSearchValue) => {
    setSearchValue({ ...searchValue, status: dashboard.selectedCard });
  };

  const onReset = () => {
    setIsLoading(true);
    setNewDashboard(dataSource, "all");
    setFilteredBooking(dataSource);
    setDataDisplay(dataSource);
    setCurrentPage(1);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
    setSearchValue(undefined);
  };

  const onChangePage = (page: number, pageSize: number) => {
    setCurrentPage(page);

    if (currentPageSize !== pageSize) {
      setCurrentPageSize(pageSize);
      setCurrentPage(1);
    } else {
      setCurrentPageSize(pageSize);
    }
  };

  const onDownload = () => {
    setIsLoadingDownload(true);

    const booking_ids: string[] = [];
    dataDisplay.forEach((booking) => {
      booking_ids.push(booking.booking_id);
    });

    const params: DownloadBookingRequestParams = {
      booking_ids: booking_ids,
    };

    BookingRequestService.downloadBookingRequest(params)
      .then((res) => {
        if (res) {
          const filename = res.headers["content-disposition"].split(";")[1].split("=")[1];
          createFile(filename, res.data);
        }
        NotificationComponent({
          notification: notification,
          type: "success",
          topic: "ดาวน์โหลดสำเร็จ !",
          message: "การดาวน์โหลดการจองเสร็จสิ้น",
        });
      })
      .finally(() => {
        setIsLoadingDownload(false);
      });
  };

  const createFile = (fileName: string, data: Blob) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = URL.createObjectURL(data);
    link.setAttribute("download", decodeURI(fileName));
    link.click();
  };

  return (
    <Content className="booking-requests-content">
      {contextHolderNoti}
      <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
        <h1>Booking Requests</h1>
        <TooltipComponent title="ส่วนการจัดการคำขอ" />
      </div>
      <Card>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <h3>รายการจองรถขนส่ง</h3>
          </Col>
          <Col span={24}>
            <BookingRequestFormSearch
              dataSource={dataSource}
              isLoading={isLoading}
              onSearch={onSearch}
              onReset={onReset}
              configState={stateConfig.current?.search}
              onSavedValue={onSavedValue}
              customerList={customerList}
            />
          </Col>
          <Col span={24}>
            <Card className="booking-request-status-card-cover">
              <Row gutter={[24, 24]} justify="center">
                <DashboardCard
                  size="small"
                  title={dashboard.content.totalBooking.toString()}
                  content="การจองทั้งหมด"
                  color={dashboard.selectedCard === "all" ? "#F5FFF7" : "#f1f1f1"}
                  loading={isLoading}
                  loadingPosition="title"
                  titleClass="booking-request-card-title"
                  contentClass="booking-request-card-content"
                  onClick={() => onFilterStatus("all")}
                />
                <DashboardCard
                  size="small"
                  title={dashboard.content.pending.toString()}
                  content="รอดำเนินการ"
                  color={dashboard.selectedCard === "pending" ? "#F5FFF7" : "#f1f1f1"}
                  loading={isLoading}
                  loadingPosition="title"
                  titleClass="booking-request-card-title"
                  contentClass="booking-request-card-content"
                  onClick={() => onFilterStatus("pending")}
                />
                <DashboardCard
                  size="small"
                  title={dashboard.content.confirmed.toString()}
                  content="ยืนยันการจอง"
                  color={dashboard.selectedCard === "confirmed" ? "#F5FFF7" : "#f1f1f1"}
                  loading={isLoading}
                  loadingPosition="title"
                  titleClass="booking-request-card-title"
                  contentClass="booking-request-card-content"
                  onClick={() => onFilterStatus("confirmed")}
                />
                <DashboardCard
                  size="small"
                  title={dashboard.content.canceled.toString()}
                  content="ยกเลิกการจอง"
                  color={dashboard.selectedCard === "canceled" ? "#F5FFF7" : "#f1f1f1"}
                  loading={isLoading}
                  loadingPosition="title"
                  titleClass="booking-request-card-title"
                  contentClass="booking-request-card-content"
                  onClick={() => onFilterStatus("canceled")}
                />
                <DashboardCard
                  size="small"
                  title={dashboard.content.rejected.toString()}
                  content="ปฏิเสธการจอง"
                  color={dashboard.selectedCard === "rejected" ? "#F5FFF7" : "#f1f1f1"}
                  loading={isLoading}
                  loadingPosition="title"
                  titleClass="booking-request-card-title"
                  contentClass="booking-request-card-content"
                  onClick={() => onFilterStatus("rejected")}
                />
              </Row>
            </Card>
          </Col>
          <Col className="table-title-action" span={24}>
            <p>Last Updated {GetDateNowBeforeFetchNew("(DD-MM-YYYY, HH:mm)")} </p>
            <ButtonDs
              type="primary"
              disabled={dataDisplay.length === 0 || isLoading || isLoadingDownload}
              loading={isLoadingDownload}
              icon={<DownloadOutlined />}
              onClick={onDownload}
            >
              ดาวน์โหลดรายงาน
            </ButtonDs>
          </Col>
          <Col span={24}>
            <DataTable
              dataSource={dataDisplay}
              isLoading={isLoading}
              onChangePage={onChangePage}
              currentPage={currentPage}
              pageSize={currentPageSize}
            />
          </Col>
        </Row>
      </Card>
    </Content>
  );
};

export default BookingRequest;
