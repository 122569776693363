import "./style.scss";
import { Button, Card, Col, Row, Spin, Tag } from "antd";
import { Content } from "antd/es/layout/layout";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import CardInfo from "./Component/CardInfo";
import CardReportDetailList from "./Component/CardReportDetailList";
import { DetailInfo } from "./Model/DetailInfo";
import { HistoryInfo } from "./Model/HistoryInfo";
import { useEffect, useRef, useState } from "react";
import { ReportResponse, StoreInfo, TruckInfo, History, Issue } from "./Model/ReportResponse";
import { getReportDetail } from "./Slice";
import { FormatDate } from "../../utils/GetDateNow";

export default function ReportDetail() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { tracking_driver_id, store_number } = useParams();
  const [shopDataSource, setShopDataSource] = useState<DetailInfo | null>(null);
  const [truckDataSource, setTruckDataSource] = useState<DetailInfo | null>(null);
  const [historyInfoList, setHistoryInfoList] = useState<HistoryInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const taskId = useRef("");

  useEffect(() => {
    setLoading(true);
    getReportDetail(tracking_driver_id as string, store_number as string)
      .then((res) => {
        if (res) {
          convertReportDisplay(res);
          taskId.current = res.task_id;
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const convertReportDisplay = (reportResponse: ReportResponse) => {
    convertStoreInfo(reportResponse.store_info);
    convertTruckInfo(reportResponse.truck_info);
    convertHistoryInfo(reportResponse.history, reportResponse.issue);
  };

  const convertStoreInfo = (storeInfo: StoreInfo) => {
    const shopData: DetailInfo = {
      title: "ข้อมูลร้านค้า",
      dataSource: [
        { title: "ชื่อสโตร์", value: storeInfo.name },
        { title: "หมายเลขสโตร์", value: storeInfo.number },
        {
          title: "หมายเลข DO",
          value: (
            <>
              <Row gutter={[16, 16]}>
                {storeInfo.delivery_order.map((item, index) => (
                  <Col span={24} style={{ display: "flex" }} key={index}>
                    <div style={{ minWidth: "186px" }}>{item.do_number ? item.do_number : "-"}</div>
                    {item.do_type.length > 0 && (
                      <Tag
                        style={{ marginBottom: "4px" }}
                        color={`${
                          item.do_type === "open"
                            ? "green"
                            : item.do_type === "close"
                            ? "red"
                            : item.do_type === "replen"
                            ? "blue"
                            : item.do_type === "other"
                            ? "purple"
                            : ""
                        }`}
                        data-testid="report-detail-do-type"
                      >
                        {item.do_type[0].toUpperCase() + item.do_type.substring(1).toLowerCase()}
                      </Tag>
                    )}
                  </Col>
                ))}
              </Row>
            </>
          ),
        },
      ],
    };

    setShopDataSource(shopData);
  };

  const convertTruckInfo = (truckInfo: TruckInfo) => {
    const truckData: DetailInfo = {
      title: "ข้อมูลรถ",
      dataSource: [
        { title: "ป้ายทะเบียนรถ", value: truckInfo.plate_number },
        { title: "Planned Date", value: truckInfo.planned_date },
        {
          title: "Initial ETA",
          value: `${FormatDate(truckInfo.initial_eta_start, "HH:mm")} - ${FormatDate(
            truckInfo.initial_eta_end,
            "HH:mm",
          )}`,
        },
        {
          title: "เบอร์โทร",
          value: truckInfo.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
        },
      ],
    };

    setTruckDataSource(truckData);
  };

  const convertHistoryInfo = (historyList: History[], issue: Issue) => {
    const historyInfo: HistoryInfo[] = [];
    historyList.forEach((history) => {
      const historyData: HistoryInfo = {
        status: history.status,
        timestamp: history.timestamp,
        userEmail: history.user_email,
        reasonDescription: history.reason_description,
        note: history.note,
        imageFolders:
          history.status === "exception"
            ? [
                {
                  type: "รูปภาพประกอบ",
                  urlList: history.epod_file.others,
                },
              ]
            : [
                {
                  type: "รูปภาพร้าน/สาขา",
                  urlList: history.epod_file.stores,
                },
                {
                  type: "รูปภาพเอกสาร",
                  urlList: history.epod_file.docs,
                },
                {
                  type: "รูปภาพสินค้า",
                  urlList: history.epod_file.products,
                },
                {
                  type: "รูปภาพอื่นๆ",
                  urlList: history.epod_file.others,
                },
              ],
        issue_history: history.issue_history,
        issue: issue,
      };

      historyInfo.push(historyData);
    });

    setHistoryInfoList(historyInfo);
  };

  const isFromReportPage = () => {
    if (searchParams.get("report") !== null) {
      return true;
    } else {
      return false;
    }
  };

  const renderLoading = () => {
    return (
      <>
        <Row gutter={[16, 24]}>
          <Col span={24}>
            <Spin>
              <Card style={{ borderRadius: "16px", width: "100%", minHeight: "262px" }} loading={true}>
                xxx
              </Card>
            </Spin>
          </Col>
          <Col span={24}>
            <Spin>
              <Card style={{ borderRadius: "16px", width: "100%", minHeight: "147px" }} loading={true}>
                xxx
              </Card>
            </Spin>
          </Col>
          <Col span={24}>
            <Spin>
              <Card style={{ borderRadius: "16px", width: "100%", minHeight: "147px" }} loading={true}>
                xxx
              </Card>
            </Spin>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Content className="management-content">
      <div className="top-navigate">
        <Button
          onClick={() => {
            isFromReportPage() ? navigate("/pod-report") : navigate(`/gps-tracking/${tracking_driver_id}`);
          }}
          icon={<LeftOutlined rev="" />}
        >
          {isFromReportPage() ? "PoD Report" : "GPS Tracking"}
        </Button>
      </div>
      <div className="report-detail-content">
        {loading ? (
          renderLoading()
        ) : (
          <Row gutter={[16, 24]}>
            <Col span={24}>
              <Card style={{ borderRadius: "16px" }}>
                <CardInfo shopDataSource={shopDataSource} truckDataSource={truckDataSource} />
              </Card>
            </Col>
            {historyInfoList.map((item, index) => (
              <Col span={24} key={index}>
                <CardReportDetailList
                  dataSource={item}
                  dataIndex={index}
                  isFromReportPage={isFromReportPage()}
                  taskId={taskId.current}
                />
              </Col>
            ))}
          </Row>
        )}
      </div>
    </Content>
  );
}
