import { BehaviorSubject } from "rxjs";
import { getSubbyList } from "../api/ApiEndPoint";

const subbyListSubject = new BehaviorSubject<{ value: string; label: string }[] | null>(null);
let isLoadedSubject = false;

const initializeState = () => {
  if (!isLoadedSubject) {
    getSubbyList().then(
      (res) => {
        const subbyList: { value: string; label: string }[] = res.data.map((x) => {
          return { value: x.uid, label: x.name };
        });
        subbyListSubject.next(subbyList);
        isLoadedSubject = true;
      },
      () => {
        subbyListSubject.next([]);
      },
    );
  }
};

const stateService = {
  subbyListSubject,
  initializeState,
};

export default stateService;
