import { ButtonDs, Form, FormDs, InputDs, SelectDs } from "design-system";
import { provincesOptions } from "../../../shared/service/province-list-option";
import { dcLists } from "../../../utils/DClists";
import { Col, Row } from "antd";
import { UndoOutlined } from "@ant-design/icons";
import { NotificationInfo } from "../models/messaging-status.model";

interface Props {
  dataSource: NotificationInfo[];
  isLoading: boolean;
  onSearch: (records: NotificationInfo[]) => void;
  onReset: () => void;
}

const MessagingStatusFormSearch = ({ dataSource, isLoading, onSearch, onReset }: Props) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    const storeName = values.store_name;
    const province = values.province;
    const dcCode = values.dc_code;
    const status = values.status;
    const sla = values.sla;

    let records: NotificationInfo[] = dataSource.map((record) => record);

    if (storeName && storeName.length > 0) {
      records = dataSource.filter((record) => {
        if (
          storeName &&
          (record.store_name.toLowerCase().indexOf(storeName.toLowerCase()) >= 0 ||
            record.store_number.toLowerCase().indexOf(storeName.toLowerCase()) >= 0)
        ) {
          return true;
        }
        return false;
      });
    }

    if (province && province !== "ALL") {
      records = records.filter((record) => record.province === province);
    }

    if (dcCode && dcCode !== "ALL") {
      records = records.filter((record) => record.dc_code === dcCode);
    }

    if (status && status !== "ALL") {
      records = records.filter((record) => record.status.toLocaleLowerCase() === status.toLocaleLowerCase());
    }

    if (sla && sla !== "ALL") {
      records = records.filter((record) => record.sla === sla);
    }

    onSearch(records);
  };

  return (
    <FormDs
      style={{ display: "flex" }}
      form={form}
      initialValues={{ store_name: "", province: "ALL", dc_code: "ALL", status: "ALL", sla: "ALL" }}
      onFinish={onFinish}
      autoComplete="off"
      requiredMark={false}
    >
      <Row gutter={16} className="search-messaging-status">
        <Col>
          <Form.Item label="ค้นหา" name="store_name">
            <InputDs placeholder="ชื่อร้านค้า, รหัสร้านค้า" />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="จังหวัด" name="province">
            <SelectDs options={provincesOptions} style={{ width: 200 }} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="ศูนย์กระจายสินค้า" name="dc_code">
            <SelectDs
              options={[
                { value: "ALL", label: "ALL" },
                ...dcLists().map((x) => ({ value: x.value, label: x.label })),
              ]}
              style={{ width: 200 }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="สถานะการส่งข้อความ" name="status">
            <SelectDs
              options={[
                { value: "ALL", label: "ALL" },
                { value: "fail", label: "Fail" },
                { value: "success", label: "Success" },
              ]}
              style={{ width: 200 }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="SLA" name="sla">
            <SelectDs
              options={[
                { value: "ALL", label: "ALL" },
                { value: "D+0", label: "D+0" },
                { value: "D+1", label: "D+1" },
              ]}
              style={{ width: 200 }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item>
            <ButtonDs type="primary" htmlType="submit" disabled={isLoading}>
              ค้นหา
            </ButtonDs>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item>
            <ButtonDs
              type="default"
              disabled={isLoading}
              onClick={() => {
                form.resetFields();
                onReset();
              }}
            >
              <UndoOutlined />
              รีเซ็ต
            </ButtonDs>
          </Form.Item>
        </Col>
      </Row>
    </FormDs>
  );
};

export default MessagingStatusFormSearch;
