import { HTTP_STATUS_CODE } from "../../utils/Constants";

import axios from "axios";
import { currentToken, getAuth } from "./FirebaseAuth";
import LocalstorageService from "../service/Localstorage.service";

const headersOptions = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, PATCH, OPTIONS",
  "Access-Control-Allow-Headers":
    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
};

const getConfigOptions = async (config: any) => {
  const company = LocalstorageService.getCompany();
  const tokens = LocalstorageService.getTokens() || null;
  let token = tokens?.accessToken;

  if (tokens) {
    let tokenExpire = tokens?.tokenExpire;

    const now_unix = Math.floor(Date.now() / 1000);
    if (now_unix > tokenExpire) {
      tokenExpire = now_unix + 3500;
      token = (await currentToken()) as string;

      if (token) {
        LocalstorageService.setTokens({ accessToken: token, tokenExpire: tokenExpire });
      }
    }
  }

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (config.data && config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  }

  if (company && config.headers) {
    config.headers["X-Company-Code"] = company;
  }
  return config;
};

const handleError = async (error: any) => {
  if (error != null && error.response != null && error.response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
    await getAuth().signOut();

    LocalstorageService.clear();
    if (!error.config.url.includes("permissions")) {
      if (window.location.href.includes("external")) {
        window.location.href = "/external-login";
      } else {
        window.location.href = "/login";
      }
    }
  }
  return error;
};

const ApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 30000,
  headers: headersOptions,
});

ApiClient.interceptors.request.use(
  async function (config) {
    config.baseURL = process.env.REACT_APP_API_HOST_TD;
    config = await getConfigOptions(config);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

ApiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  async (error) => {
    error = await handleError(error);
    return Promise.reject(error);
  },
);

export default ApiClient;

export const httpClientTD = axios.create({
  baseURL: process.env.REACT_APP_API_HOST_TD,
  timeout: 30000,
  headers: headersOptions,
});

httpClientTD.interceptors.request.use(
  async (config) => {
    config = await getConfigOptions(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

httpClientTD.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    error = await handleError(error);
    return Promise.reject(error);
  },
);
