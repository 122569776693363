import * as ApiEndPoint from "../../shared/api/ApiEndPoint";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiClient from "../../shared/api/ApiClient";
import { AxiosError } from "axios";
import { RootState } from "../../shared/store/store";

type EtasListState = {
  loading: boolean;
};

const initialValues: EtasListState = {
  loading: false,
};

export const sendSMSToStoreOwner = createAsyncThunk(
  "request/sendSMSToStoreOwner",
  async (dcCode: string, { rejectWithValue }) => {
    try {
      const response = await ApiClient.post(ApiEndPoint.SEND_SMS_TO_STORE_OWNER(dcCode));
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }
    }
  },
);

export const sendLineMessage = createAsyncThunk(
  "request/sendLineMessage",
  async (dcCode: string, { rejectWithValue }) => {
    try {
      const response = await ApiClient.post(ApiEndPoint.SEND_LINE_MESSAGE(dcCode));
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }
    }
  },
);

const sendLineMessageSlice = createSlice({
  name: "sendLineMessage",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendLineMessage.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(sendLineMessage.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(sendLineMessage.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const isLoadingSendLineMessage = (store: RootState) => store.sendLineMessage.loading;
export default sendLineMessageSlice.reducer;
