import React from "react";
import { ThemeDsConfig, DesignToken } from "design-system";
import "../../style/font-face.scss";
import "design-system/lib/ds-bundle.css";
import { ConfigProvider } from "antd";

interface Props {
  children: React.ReactNode;
}

const DesignSystemTheme = ({ children }: Props) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainerDisabled: DesignToken.SurfaceStaticUiDisabled,
          colorPrimary: DesignToken.SurfaceActionPrimaryDefault,
          colorPrimaryBg: DesignToken.ContentPrimarySubdued,
          colorPrimaryBgHover: DesignToken.SurfaceActionPrimaryHover,
          colorTextDisabled: DesignToken.ContentDisabled,
          colorTextPlaceholder: DesignToken.ContentPlaceholder,
          fontFamily: `${DesignToken.Body2FontFamily} ${DesignToken.Body2FontWeight}`,
          fontSize: DesignToken.Body1FontSize,
          fontSizeHeading1: DesignToken.H1FontSize,
          fontSizeHeading2: DesignToken.H2FontSize,
          fontSizeHeading3: DesignToken.H3FontSize,
          fontSizeHeading4: DesignToken.H4FontSize,
          fontSizeLG: DesignToken.Body1FontSize,
          fontSizeSM: DesignToken.Body1FontSize,
          fontSizeXL: DesignToken.Body1FontSize,
        },
        components: {
          Button: ThemeDsConfig.ButtonDsConfig,
          Form: ThemeDsConfig.FormDsConfig,
          Modal: ThemeDsConfig.ModalDsConfig,
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default DesignSystemTheme;
