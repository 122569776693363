import ApiClient from "../../shared/api/ApiClient";
import {
  REPORT_ISSUE,
  GET_DRIVER_LOCATION,
  GET_REPORT_DETAIL,
  CANCEL_ISSUE,
} from "../../shared/api/ApiEndPoint";
import { ReportResponse } from "./Model/ReportResponse";
import { ReportIssueRequest } from "./Model/ReportIssueRequest";
import { Driver } from "../GpsTracking/Driver";

export const getReportDetail = (tracking_driver_id: string, store_number: string): Promise<ReportResponse> =>
  ApiClient.get(GET_REPORT_DETAIL(tracking_driver_id, store_number)).then((res) => res.data);

export const getDriverLocation = (tracking_driver_id: string): Promise<Driver> =>
  ApiClient.get<Driver>(GET_DRIVER_LOCATION({ trackingDriverID: tracking_driver_id })).then(
    (res) => res.data,
  );

export const epodReportIssue = (task_id: string, data: ReportIssueRequest): Promise<any> =>
  ApiClient.post(REPORT_ISSUE(task_id), data).then((res) => res.data);

export const epodCancelIssue = (task_id: string): Promise<any> =>
  ApiClient.put(CANCEL_ISSUE(task_id)).then((res) => res.data);
