import { Layout } from "antd";
import HeaderComponent from "../shared/component/HeaderComponent/HeaderComponent";
import { Outlet, useLocation } from "react-router-dom";
import SiderComponent from "../shared/component/SiderComponent/SiderComponent";
import { useState } from "react";

const GpsTrackingLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  return (
    <Layout className="management-layout">
      <HeaderComponent
        onToggle={(toggleState) => {
          setCollapsed(toggleState);
        }}
      />
      <Layout>
        {location.pathname !== "/select-company" &&
          location.pathname !== "/agent-access-token" &&
          location.pathname !== "/external-api-key" && <SiderComponent isToggleSider={collapsed} />}
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default GpsTrackingLayout;
