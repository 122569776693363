import { httpClientTD } from "../../../shared/api/ApiClient";
import { TaskMonitoring } from "../models/task";

const GET_LIST_TASK = "/v2/tasks";

const getTask = (): Promise<TaskMonitoring[]> =>
  httpClientTD.get(GET_LIST_TASK).then((res) => {
    return res.data;
  });

const dropMonitorService = { getTask };

export default dropMonitorService;
