import "./style.scss";

import {
  CarOutlined,
  FieldTimeOutlined,
  ShopOutlined,
  UserOutlined,
  FileTextOutlined,
  CheckCircleOutlined,
  DownOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Layout, Menu, Space } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cjLogo from "../../../assets/images/icon/icon-cj.svg";
import tdLogo from "../../../assets/images/icon/icon-td.svg";
import tdmLogo from "../../../assets/images/icon/tdm_icon.svg";
import LocalstorageService from "../../service/Localstorage.service";
import SessionstorageService from "../../service/Sessionstorage.service";
import { Company } from "../../type/Company";

const { Sider } = Layout;

interface Props {
  isToggleSider: boolean;
}

interface MenuItem {
  key: string;
  label: string;
  icon?: JSX.Element;
  onClick?: () => void;
  allow: string[];
  allowCompany: string[];
  children?: MenuItem[];
}

export default function SiderComponent({ isToggleSider }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState("1");
  const selectedCompany = LocalstorageService.getCompany();
  const userAuthInfoData = LocalstorageService.getUserAuthInfo();
  const toggleState = isToggleSider;
  const logoMapping = {
    CJ: {
      logo: cjLogo,
      name: "CJ Express",
    },
    TD: {
      logo: tdLogo,
      name: "TD Tawandang",
    },
    TDM: {
      logo: tdmLogo,
      name: "TDM Logistics",
    },
  };

  const handleClick = (e: any) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    if (location.pathname === "/delivery-order") {
      setCurrent("1");
    } else if (location.pathname === "/truck-monitor") {
      setCurrent("2-1");
    } else if (location.pathname === "/return-eta") {
      setCurrent("2-2");
    } else if (location.pathname === "/return-eta-request") {
      setCurrent("2-3");
    } else if (location.pathname === "/user-management") {
      setCurrent("3");
    } else if (location.pathname === "/store-master") {
      setCurrent("4");
    } else if (location.pathname === "/messaging-status") {
      setCurrent("5");
    } else if (location.pathname === "/task-status-report") {
      setCurrent("6");
    } else if (location.pathname === "/pod-report") {
      setCurrent("7");
    } else if (location.pathname === "/booking-request") {
      setCurrent("8");
    } else {
      setCurrent("0");
    }
  }, [current, location.pathname]);

  const menuItem: MenuItem[] = [
    {
      key: "1",
      icon: <FieldTimeOutlined rev="" />,
      label: "Delivery Order",
      allow: ["root", "admin", "manager", "planner", "operation"],
      allowCompany: ["CJ", "TD"],
      onClick: () => {
        navigate("/delivery-order");
      },
    },
    {
      key: "2",
      icon: <CarOutlined rev="" />,
      label: "Truck Monitor",
      allow: ["root", "admin", "manager", "planner", "operation", "call_center"],
      allowCompany: ["CJ", "TD"],
      children: [
        {
          key: "2-1",
          label: "Live Monitoring",
          allow: ["root", "admin", "manager", "planner", "operation", "call_center"],
          allowCompany: ["CJ", "TD"],
          onClick: () => {
            navigate("/truck-monitor");
          },
        },
        {
          key: "2-3",
          label: "Return ETA",
          allow: ["root", "admin", "manager", "planner", "operation"],
          allowCompany: ["CJ", "TD"],

          onClick: () => {
            navigate("/return-eta-request");
          },
        },
      ],
    },
    {
      key: "3",
      icon: <UserOutlined rev="" />,
      label: "User Management",
      allow: ["root", "admin", "manager"],
      allowCompany: ["CJ", "TD", "TDM"],
      onClick: () => {
        sessionStorage.removeItem("bookingRequestState");
        navigate("/user-management");
      },
    },
    {
      key: "4",
      icon: <ShopOutlined rev="" />,
      label: "Store Master",
      allow: ["root", "admin", "manager", "planner", "operation"],
      allowCompany: ["CJ", "TD"],
      onClick: () => {
        navigate("/store-master");
      },
    },
    {
      key: "5",
      icon: <UserOutlined rev="" />,
      label: "Messaging Status",
      allow: ["root", "admin", "manager", "planner", "operation"],
      allowCompany: ["CJ", "TD"],
      onClick: () => {
        navigate("/messaging-status");
      },
    },
    {
      key: "6",
      icon: <FileTextOutlined rev="" />,
      label: "Report",
      allow: ["root", "admin", "manager", "planner", "operation"],
      allowCompany: ["CJ", "TD"],
      onClick: () => {
        navigate("/task-status-report");
      },
    },
    {
      key: "7",
      icon: <CheckCircleOutlined />,
      label: "PoD Report",
      allow: ["root", "admin", "manager", "planner", "operation"],
      allowCompany: ["CJ", "TD"],
      onClick: () => {
        sessionStorage.removeItem("podReportState");
        navigate("/pod-report");
      },
    },
    {
      key: "8",
      icon: <ContainerOutlined />,
      label: "Booking Requests",
      allow: ["admin"],
      allowCompany: ["TDM"],
      onClick: () => {
        sessionStorage.removeItem("podReportState");
        navigate("/booking-request");
      },
    },
    {
      key: "9",
      icon: <CarOutlined rev="" />,
      label: "Live Monitoring",
      allow: ["admin"],
      allowCompany: ["TDM"],
      children: [
        {
          key: "9-1",
          label: "Drop Monitoring",
          allow: ["admin"],
          allowCompany: ["TDM"],
          onClick: () => {
            sessionStorage.removeItem("bookingRequestState");
            navigate("/drop-monitoring");
          },
        },
      ],
    },
  ];

  const onClickCompany = (company: Company) => {
    LocalstorageService.setCompany(company);
    SessionstorageService.removeDCMaster();
    if (company === "TDM") {
      window.location.href = "/booking-request";
    } else {
      window.location.href = "/truck-monitor";
    }
  };

  const renderSwitchCompany = () => {
    return (
      <>
        {selectedCompany && (
          <>
            {userAuthInfoData?.accessor_companies.length === 1 && (
              <div
                style={{
                  width: !toggleState ? "90%" : "",
                  margin: "10px",
                  padding: "10px 7px",
                }}
              >
                <div style={{ paddingBottom: "8px", opacity: 0.6 }}>Company</div>
                <Space style={{ display: "flex", justifyContent: "space-between" }}>
                  <Space style={{ width: "100%", fontSize: "14px", paddingLeft: "10px" }}>
                    <img src={logoMapping[selectedCompany].logo} alt="logo-company" width={38} height={24} />
                    {!toggleState && <>{logoMapping[selectedCompany].name}</>}
                  </Space>
                </Space>
              </div>
            )}
            {userAuthInfoData && userAuthInfoData?.accessor_companies.length > 1 && (
              <>
                <div style={{ paddingLeft: "10px", paddingTop: "10px", opacity: 0.6 }}>Company</div>
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items: [
                      selectedCompany === "CJ"
                        ? null
                        : userAuthInfoData?.accessor_companies.includes("CJ")
                        ? {
                            key: "1",
                            label: (
                              <Space>
                                <img src={logoMapping["CJ"].logo} alt="logo-company" />
                                {logoMapping["CJ"].name}
                              </Space>
                            ),
                            onClick: () => {
                              onClickCompany("CJ");
                            },
                          }
                        : null,
                      selectedCompany === "TD"
                        ? null
                        : userAuthInfoData?.accessor_companies.includes("TD")
                        ? {
                            key: "2",
                            label: (
                              <Space>
                                <img src={logoMapping["TD"].logo} alt="logo-company" />
                                {logoMapping["TD"].name}
                              </Space>
                            ),
                            onClick: () => {
                              onClickCompany("TD");
                            },
                          }
                        : null,
                      selectedCompany === "TDM"
                        ? null
                        : userAuthInfoData?.accessor_companies.includes("TDM")
                        ? {
                            key: "3",
                            label: (
                              <Space>
                                <img src={logoMapping["TDM"].logo} alt="logo-company" width={38} />
                                {logoMapping["TDM"].name}
                              </Space>
                            ),
                            onClick: () => {
                              onClickCompany("TDM");
                            },
                          }
                        : null,
                    ],
                  }}
                >
                  <Button
                    style={{
                      width: !toggleState ? "90%" : "",
                      margin: "10px",
                      padding: "10px 7px",
                      height: "50px",
                    }}
                    size="large"
                  >
                    <Space style={{ display: "flex", justifyContent: "space-between" }}>
                      <Space style={{ width: "100%", fontSize: "14px" }}>
                        <img
                          src={logoMapping[selectedCompany].logo}
                          alt="logo-company"
                          width={38}
                          height={24}
                        />
                        {!toggleState && <>{logoMapping[selectedCompany].name}</>}
                      </Space>
                      {!toggleState && <DownOutlined />}
                    </Space>
                  </Button>
                </Dropdown>
              </>
            )}
          </>
        )}
      </>
    );
  };

  const filterMenuItem = (menuItems: MenuItem[]) => {
    return menuItems.filter(
      (x) =>
        x.allow.includes(userAuthInfoData?.role[selectedCompany || ""] ?? "") &&
        x.allowCompany.includes(selectedCompany || ""),
    );
  };

  // userType contains multiple roles in array.
  // So we need to check have any role in userType allow to open each menu.
  const menuItemPermission = filterMenuItem(menuItem).map((x) => {
    return {
      key: x.key,
      label: x.label,
      children: x.children
        ? filterMenuItem(x.children).map((c) => {
            return {
              key: c.key,
              label: c.label,
              onClick: c.onClick,
            };
          })
        : undefined,
      icon: x.icon,
      onClick: x.onClick,
    };
  });

  return (
    <Sider trigger={null} collapsible collapsed={toggleState} className="sider-custom">
      {renderSwitchCompany()}
      <Menu
        onClick={handleClick}
        mode="inline"
        defaultOpenKeys={
          location.pathname === "/truck-monitor" || location.pathname === "/return-eta" ? ["2"] : [""]
        }
        selectedKeys={[current]}
        items={menuItemPermission}
      />
    </Sider>
  );
}
