import { BehaviorSubject } from "rxjs";
import ApiClient from "../../../shared/api/ApiClient";
import {
  DownloadEpodReportParams,
  EpodReport,
  SearchEpodReportParams,
  SignedUrl,
  TotalTask,
} from "../models/epodReport";
import {
  DOWNLOAD_EPOD_REPORT,
  GEN_SIGNED_URL,
  MARK_READ_REPORT,
  SEARCH_EPOD_REPORT,
  TOTAL_TASK,
} from "../../../shared/api/ApiEndPoint";

const searchPodReport = async (params: SearchEpodReportParams): Promise<EpodReport[]> => {
  const { data } = await ApiClient.post<EpodReport[]>(SEARCH_EPOD_REPORT, params);
  return data;
};

const generateSignedUrl = async (param: string[]): Promise<SignedUrl> => {
  const { data } = await ApiClient.post<SignedUrl>(GEN_SIGNED_URL, {
    file_path: param,
  });
  return data;
};

const markReadReport = async (task_id: string, mark_read: boolean) => {
  const { data } = await ApiClient.post(MARK_READ_REPORT(task_id), { mark_read });
  return data;
};

const downloadEpodReport = (param: DownloadEpodReportParams): Promise<any> => {
  return ApiClient.post(DOWNLOAD_EPOD_REPORT, param, {
    responseType: "blob",
  });
};

const getTotalTask = async (params: SearchEpodReportParams) => {
  const { data } = await ApiClient.post<TotalTask>(TOTAL_TASK, params);
  return data;
};

const isloadingTable = new BehaviorSubject<boolean>(false);
const signalReset = new BehaviorSubject<boolean>(false);

const podReportService = {
  searchPodReport,
  generateSignedUrl,
  markReadReport,
  downloadEpodReport,
  getTotalTask,
  isloadingTable,
  signalReset,
};

export default podReportService;
