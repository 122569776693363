import "./style.scss";
import { Button, Layout } from "antd";
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import logoImage from "../../../assets/images/logo/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth } from "../../api/FirebaseAuth";
import LocalstorageService from "../../service/Localstorage.service";
import SessionstorageService from "../../service/Sessionstorage.service";
import { useEffect, useState } from "react";

interface Props {
  onToggle?: (toggleState: boolean) => void;
}

export default function HeaderComponent({ onToggle }: Props) {
  const { Header } = Layout;
  const [toggleState, setToggleState] = useState(false);
  const location = useLocation();
  const userAuthInfoData = LocalstorageService.getUserAuthInfo();
  const role = LocalstorageService.getCurrentRole();
  const externalRole = LocalstorageService.getExternalCurrentRole();
  const navigate = useNavigate();

  useEffect(() => {
    if (onToggle) {
      onToggle(toggleState);
    }
  }, [toggleState]);

  const displayUser = (role: string) => {
    return (
      userAuthInfoData && (
        <div className="user-profile">
          <p className="title">
            {userAuthInfoData.first_name && userAuthInfoData.last_name
              ? userAuthInfoData.first_name + " " + userAuthInfoData.last_name
              : "-"}
          </p>
          <div className="display-role">
            {role && (
              <p className="sub-title">
                {userAuthInfoData.email} <span className="role">{`(role: ${role})`}</span>
              </p>
            )}
          </div>
        </div>
      )
    );
  };

  return (
    <Header>
      <>
        {location.pathname.includes("/external") ? (
          <>
            <div className="top-left-items" data-testid="test-header-component">
              <img src={logoImage} alt="logo" />
              <span>External Sync</span>
            </div>
            {userAuthInfoData ? (
              <div className="top-right-items">
                {displayUser(externalRole)}
                <Button
                  data-testid="test-logout-button"
                  icon={<LogoutOutlined rev="" />}
                  onClick={() => {
                    getAuth()
                      .signOut()
                      .then(() => {
                        LocalstorageService.clear();
                        navigate("/external-login");
                      });
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <div className="top-left-items" data-testid="test-header-component">
              {location.pathname !== "/reset-password" &&
              location.pathname !== "/login" &&
              location.pathname !== "/forgot-password" &&
              location.pathname !== "/external-api-key" ? (
                <Button
                  data-testid="test-toggle-sider-button"
                  icon={toggleState ? <MenuUnfoldOutlined rev="" /> : <MenuFoldOutlined rev="" />}
                  onClick={() => {
                    setToggleState(!toggleState);
                  }}
                />
              ) : (
                ""
              )}
              <img src={logoImage} alt="logo" />
              <span>GPS Tracking</span>
            </div>
            {userAuthInfoData ? (
              <div className="top-right-items">
                {displayUser(role)}
                <Button
                  data-testid="test-logout-button"
                  icon={<LogoutOutlined rev="" />}
                  onClick={() => {
                    getAuth()
                      .signOut()
                      .then(() => {
                        LocalstorageService.clear();
                        SessionstorageService.removeDCMaster();
                        navigate("/login");
                      });
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </>
    </Header>
  );
}
