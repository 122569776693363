import { ButtonDs } from "design-system";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { EpodReport } from "../models/epodReport";
import dayjs from "dayjs";
import { NotificationComponent } from "../../../shared/component/NotificationComponent";
import { notification } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

interface Props {
  dataSource: EpodReport[];
  fileName: string;
}

const DownloadCSVFile = ({ dataSource, fileName }: Props) => {
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [csvFilename, setCsvFilename] = useState<string>("");
  const [notificationApi, contextHolderNoti] = notification.useNotification();

  const csvHeader = [
    "No.",
    "ป้ายทะเบียน",
    "DC",
    "Delivery SLA",
    "Planned Date",
    "Stop",
    "Load",
    "ร้านที่ส่งสำเร็จ",
    "Status",
    "Update Date",
    "Confirm Status",
    "Report Reasons",
  ];

  const onDownloadFile = async () => {
    setIsDownloadLoading(true);
    setCsvFilename(fileName);
    if (dataSource.length > 0) {
      const csvRows = dataSource.map((v, i) => {
        return [
          i + 1,
          v.truck_plate_number,
          v.dc_code,
          v.sla,
          v.planned_date.replaceAll("-", "/"),
          v.stop,
          v.load && v.load != "" ? v.load : "-",
          v.store_name,
          v.status,
          dayjs(v.update_date).format("DD/MM/YYYY HH:mm"),
          v.confirm_status,
          v.report_reason,
        ];
      });
      setCsvData(csvData.concat(csvRows as string[][]));
      setCsvData(csvRows as string[][]);
    } else if (dataSource.length === 0) {
      setCsvData([]);
    }
    setIsDownloadLoading(false);
    NotificationComponent({
      notification: notificationApi,
      type: "success",
      topic: "ดาวน์โหลดสำเร็จ !",
      message: "การดาวน์โหลดรายงานเสร็จสิ้น",
    });
  };

  return (
    <>
      <CSVLink data={csvData} headers={csvHeader} filename={csvFilename} onClick={onDownloadFile}>
        <ButtonDs
          size="middle"
          disabled={dataSource.length === 0}
          loading={isDownloadLoading}
          icon={<DownloadOutlined />}
        >
          ดาวน์โหลด
        </ButtonDs>
      </CSVLink>
      {contextHolderNoti}
    </>
  );
};

export default DownloadCSVFile;
