import "./style.scss";

import {
  Button,
  Card,
  Collapse,
  DatePicker,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Table,
  Tag,
  message,
} from "antd";
import type { CollapseProps } from "antd";
import {
  GetDate,
  GetDateNow,
  GetDateNowBeforeFetchNew,
  GetTimestamp,
  recentTime,
} from "../../utils/GetDateNow";
import {
  SearchParams,
  dashboardDataLoading,
  dashboards,
  downloadTrips,
  downloadTripsErrorResponse,
  drivers,
  driversLoading,
  listDashboard,
  searchDriver,
} from "./Slice";

import { ColumnsType } from "antd/es/table";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { Driver } from "./Driver";
import { Link, useSearchParams } from "react-router-dom";
import { dcLists } from "../../utils/DClists";
import { useAppDispatch } from "../../shared/store/store";
import { useEffect, useRef, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { useSelector } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { DashboardCard, DetailSectionProps } from "../../shared/component/CardComponent/DashboardComponent";
import { PopoverDetail } from "../../shared/component/CardComponent/PopoverComponent";
import { DownCircleOutlined, DownloadOutlined, UpCircleOutlined } from "@ant-design/icons";
import TomtomMap from "./TomtomMap";
import LocalstorageService from "../../shared/service/Localstorage.service";

const { Content } = Layout;
const { Option } = Select;
const options = [{ value: "open" }, { value: "close" }, { value: "replen" }, { value: "other" }];

const tagMessage = (type: string[]) => {
  return type?.map((x, i) => (
    <Tag
      key={i}
      className="truck-driver-do-type"
      style={{ marginBottom: "4px" }}
      color={`${
        x === "open"
          ? "green"
          : x === "close"
          ? "red"
          : x === "replen"
          ? "blue"
          : x === "other"
          ? "purple"
          : ""
      }`}
      data-testid="transport-detail-do-type"
    >
      {x}
    </Tag>
  ));
};

export default function TruckMonitor() {
  const dispatch = useAppDispatch();
  const userRole = LocalstorageService.getCurrentRole();
  const driverDatas = useSelector(drivers);
  const loadingDriverDatas = useSelector(driversLoading);
  const dashboardsData = useSelector(dashboards);
  const dashboardLoading = useSelector(dashboardDataLoading);
  const downloadTripsError = useSelector(downloadTripsErrorResponse);
  const [form] = useForm();
  const [filterDate, setFilterDate] = useState("");
  const [searchParams] = useSearchParams();
  const searchData = useRef<SearchParams>();
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSizeRef = useRef(10);
  const [popupDriverKey, setPopupDriverKey] = useState<Driver>();
  const [isToggleMapActive, setIsToggleMapActive] = useState<boolean>(false);
  const [isDashboardActive, setIsDashboardActive] = useState<boolean>(false);

  const toggles = LocalstorageService.getConfigToggleTruckMonitor();

  useEffect(() => {
    if (toggles) {
      setIsDashboardActive(toggles.isShowDashborad);
      setIsToggleMapActive(toggles.isShowMap);
    }
  }, []);

  const messageError = (message: string) => {
    messageApi.error(message);
  };

  useEffect(() => {
    dispatch(searchDriver(searchData.current ?? setSearchParam({})));
    dispatch(listDashboard(searchData.current ?? setSearchParam({})));
  }, [dispatch]);

  useEffect(() => {
    if (downloadTripsError) {
      messageError("ดาวน์โหลดข้อมูลไม่สำเร็จ");
    }
  }, [downloadTripsError]);

  const columns: ColumnsType<Driver> = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      width: 15,
      align: "center",
      render: (_, __, index) => <>{index + 1}</>,
    },
    {
      title: "ป้ายทะเบียน",
      dataIndex: "truck_plate_number",
      key: "truck_plate_number",
      render: (_, record) => (
        <>
          {record.location?.lat && record.location?.long ? (
            <a
              style={{ color: "#000", textDecoration: "underline" }}
              onClick={() => {
                setIsToggleMapActive(true);
                LocalstorageService.setConfigToggleTruckMonitor({
                  isShowDashborad: isDashboardActive,
                  isShowMap: true,
                });
                const element = document.getElementById("totom-map");
                setPopupDriverKey(record);
                if (element) {
                  element.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              {record.truck_plate_number}
            </a>
          ) : (
            record.truck_plate_number
          )}
        </>
      ),
    },
    {
      title: "ประเภทรถ",
      dataIndex: "truck_type",
      key: "truck_type",
    },
    {
      title: "DC",
      dataIndex: "dc_code",
      key: "dc_code",
    },
    {
      title: "Load",
      dataIndex: "load",
      key: "load",
      render: (_, record) => {
        return <>{record.load ? record.load : "-"}</>;
      },
    },
    {
      title: "Deliver SLA",
      dataIndex: "sla",
      key: "sla",
    },
    {
      title: "Planned Date",
      dataIndex: "planned_date",
      key: "planned_date",
    },
    {
      title: "Planned Time",
      dataIndex: "planned_time",
      key: "planned_time",
      align: "center",
    },
    {
      title: "Task Complete",
      dataIndex: "task_complete",
      render: (_, record) => (
        <div data-testid="transport-detail-task-complete">{`${record.completed_job}/${record.total_job}`}</div>
      ),
      align: "center",
    },
    {
      title: "DO Type",
      dataIndex: "do_type_list",
      key: "do_type_list",
      width: "150px",
      render: (_, record) => tagMessage(record.do_type_list),
    },
    {
      title: "Arrival Date",
      dataIndex: "arrival_date",
      key: "arrival_date",
    },
    {
      title: "ร้านล่าสุด",
      dataIndex: "latest_store",
      key: "latest_store",
      width: "150px",
    },
    {
      title: "ร้านถัดไป",
      dataIndex: "next_store",
      key: "next_store",
      width: "150px",
    },
    {
      title: "MobilePhone",
      dataIndex: "mobilePhone",
      key: "mobilePhone",
      render: (_, record) => (
        <a className="phone-number" href={`tel:${record.driver_mobile_number}`}>
          {record.driver_mobile_number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}
        </a>
      ),
    },
    {
      title: "Recent Update time",
      dataIndex: "last_location_timestamp",
      key: "last_location_timestamp",
      render: (_, record) => <div>{recentTime(record.last_location_timestamp)}</div>,
    },
    {
      title: "Status",
      align: "center",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <span className={`status-badge ${record.status ? "complete" : "incomplete"}`}></span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Link className="action-gps-tracking" to={`/gps-tracking/${record.tracking_driver_id}`}>
          รายละเอียด
        </Link>
      ),
    },
  ];

  const setSearchParam = (values: any): SearchParams => {
    const params: SearchParams = {
      dc_code: values.dc || "",
      load: values.load || "",
      sla: values.sla || "",
      truck_type: values.truck_type || "",
      truck_plate_number: values.license || "",
      sync_status: values.sync_status || "",
      store_number: values.store_number || "",
      do_type_list: values.do_type_list || [],
      planned_arrival: values.planned_arrival,
      task_progress: values.task_progress || "",
      test_search_error: null,
      current_time: GetTimestamp(),
    };

    if (searchParams.get("test_search_error") !== null) {
      params.test_search_error = searchParams.get("test_search_error");
    }

    return params;
  };

  const onFinish = (values: any) => {
    const date = values.planned_arrival ? GetDate(values.planned_arrival) : GetDateNow();
    setFilterDate(date);

    searchData.current = setSearchParam(values);
    setCurrentPage(1);
    dispatch(searchDriver(searchData.current ?? setSearchParam(values)));
    dispatch(listDashboard(searchData.current ?? setSearchParam(values)));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const filterTagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const colorTag = (value: string) => {
      if (value === "open") {
        return "green";
      } else if (value === "close") {
        return "red";
      } else if (value === "replen") {
        return "blue";
      } else if (value === "other") {
        return "purple";
      } else {
        return "grey";
      }
    };

    return (
      <Tag
        color={colorTag(value)}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
        data-testid=""
      >
        {label}
      </Tag>
    );
  };

  const checkDate = (date: Dayjs) => {
    return (
      date &&
      (date.isAfter(dayjs().endOf("day")) || date.isBefore(dayjs().subtract(90, "day").startOf("day")))
    );
  };

  const showDateTime = (date: string) => {
    return filterDate ? filterDate : date;
  };

  const dashboardDetailData = {
    complete: {
      detailProps: [
        {
          title: "ส่งไปแล้ว",
          content: dashboardsData?.task_completed ?? null,
        },
      ],
    },
    incomplete: {
      detailProps: [
        {
          title: "ต้องส่งอีก",
          content: dashboardsData?.task_incompleted ?? null,
        },
      ],
    },
    truck_turn: {
      detailProps: [
        {
          title: "เสร็จแล้ว",
          content: dashboardsData?.truck_completed ?? null,
        },
        {
          title: "เหลือ 1 ร้าน",
          content: dashboardsData?.truck_remaining_one_task ?? null,
        },
      ],
    },
    not_available: {
      detailProps: [
        {
          title: "N/A",
          content: dashboardsData?.truck_not_avaiable ?? null,
        },
        {
          title: "มากกว่า 1 ชั่วโมง",
          content: dashboardsData?.truck_inactive ?? null,
        },
      ],
    },
  };

  const sumTotal = (detailProps: DetailSectionProps[]): string => {
    let result = "";
    let sum = 0;
    detailProps.forEach((x: DetailSectionProps) => {
      if (x.content === undefined || x.content === null) {
        result = "-";
      } else {
        sum += Number(x.content);
        result = sum.toString();
      }
    });
    return result;
  };

  const returnTotalTrip = (): string => {
    if (
      dashboardsData?.total_trip_by_planned_arrival === undefined ||
      dashboardsData?.total_trip === undefined
    ) {
      return "-";
    }
    return `${dashboardsData?.total_trip} / ${dashboardsData?.total_trip_by_planned_arrival}`;
  };

  function allTasks(obj: any) {
    return obj ? Object.keys(obj).reduce((sum, key) => sum + parseFloat(obj[key] || 0), 0) : 0;
  }

  const onClickDashboard = (field: { name: string; value: string }) => {
    form.setFieldValue(field.name, field.value);
    form.submit();
  };

  const onClickDownloadTrips = async () => {
    dispatch(downloadTrips(searchData.current ?? setSearchParam({})));
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: `สถานะพนักงานขับรถ (วันที่อัพเดทล่าสุด: ${GetDateNowBeforeFetchNew()})`,
      children: (
        <div className="wrap-dashboard">
          <Row gutter={[24, 24]} align="stretch" justify="space-between">
            <DashboardCard
              size="big"
              title={"ทริปทั้งหมด"}
              content={`${returnTotalTrip()}`}
              color="#EAF6FF"
              popOverContent={PopoverDetail(
                dashboardsData?.task,
                dashboardsData?.fleet_type,
                allTasks(dashboardsData?.task),
              )}
              loading={dashboardLoading}
              dataTestId="dashboard-total-trip"
            />
            <DashboardCard
              size="big"
              title={"เสร็จสิ้น (ทริป)"}
              content={dashboardsData?.trip_completed}
              detailProps={dashboardDetailData.complete.detailProps}
              color="#ecf6ed"
              loading={dashboardLoading}
              dataTestId="dashboard-trip-completed"
              unit="ร้าน"
              onClick={() => onClickDashboard({ name: "task_progress", value: "completed" })}
            />
            <DashboardCard
              size="big"
              title={"ยังไม่เสร็จ (ทริป)"}
              content={dashboardsData?.trip_incompleted}
              detailProps={dashboardDetailData.incomplete.detailProps}
              color="#fff8e5"
              loading={dashboardLoading}
              dataTestId="dashboard-trip-incompleted"
              unit="ร้าน"
              onClick={() => onClickDashboard({ name: "task_progress", value: "incompleted" })}
            />
            <DashboardCard
              size="small"
              title={"รถที่มีโอกาสกลับมารับงาน"}
              content={sumTotal(dashboardDetailData.truck_turn.detailProps)}
              detailProps={dashboardDetailData.truck_turn.detailProps}
              color="#edf0fe"
              unit="คัน"
              loading={dashboardLoading}
              dataTestId="dashboard-truck-turn"
              onClick={() => onClickDashboard({ name: "task_progress", value: "potential_truck_turn" })}
            />
            <DashboardCard
              size="small"
              title={"ไม่อยู่ในระบบ"}
              content={sumTotal(dashboardDetailData.not_available.detailProps)}
              detailProps={dashboardDetailData.not_available.detailProps}
              color="#fdeff4"
              unit="คัน"
              loading={dashboardLoading}
              dataTestId="dashboard-truck-not-available"
              onClick={() => onClickDashboard({ name: "sync_status", value: "inactive" })}
            />
          </Row>
        </div>
      ),
    },
  ];

  const filterProps = "children";

  return (
    <Content className="management-content">
      <div className="warp-header-truck">
        <p>Truck Monitor</p>
        <div>
          <Button onClick={onClickDownloadTrips}>
            <DownloadOutlined className="icon" />
            ดาวน์โหลด
          </Button>
        </div>
      </div>
      <div className="wrap-card-form-search-truck">
        <Card style={{ paddingBottom: "0px" }}>
          <div className="wrap-form-search-truck">
            <Form
              form={form}
              name="search-truck"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              initialValues={{
                dc: "ALL",
                sla: "",
                sync_status: "",
                task_progress: "",
                truck_type: "",
              }}
              labelWrap
              layout="vertical"
            >
              <Form.Item label="ศูนย์กระจายสินค้า" name="dc">
                <Select
                  showSearch
                  style={{ width: "120px" }}
                  data-testid="search-dc"
                  listHeight={300}
                  optionFilterProp={filterProps}
                >
                  <Option value="ALL">ทั้งหมด</Option>
                  {dcLists().map((x) => (
                    <Select.Option key={x.value} value={x.value}>
                      {x.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Load" name="load">
                <Input
                  style={{ width: "100px" }}
                  onChange={(e) => {
                    form.setFieldValue("load", e.target.value.trim());
                  }}
                />
              </Form.Item>

              <Form.Item label="Delivery SLA" name="sla">
                <Select
                  showSearch
                  style={{ width: "120px" }}
                  data-testid="search-truck_type"
                  optionFilterProp={filterProps}
                >
                  <Option value="">ทั้งหมด</Option>
                  <Option value="D+0">D+0</Option>
                  <Option value="D+1">D+1</Option>
                </Select>
              </Form.Item>

              <Form.Item label="Sync Status" name="sync_status">
                <Select
                  showSearch
                  style={{ minWidth: "150px" }}
                  data-testid="search-sync_status"
                  optionFilterProp={filterProps}
                >
                  <Option value="">ทั้งหมด</Option>
                  <Option value="active">Active</Option>
                  <Option value="inactive">ไม่อยู่ในระบบ</Option>
                  <Option value="n/a">N/A</Option>
                  <Option value="gt 60m">มากกว่า 1 ชั่วโมง</Option>
                </Select>
              </Form.Item>

              <Form.Item label="ประเภทรถ" name="truck_type">
                <Select
                  showSearch
                  style={{ width: "120px" }}
                  data-testid="search-truck_type"
                  optionFilterProp={filterProps}
                >
                  <Option value="">ทั้งหมด</Option>
                  <Option value="10W">10W</Option>
                  <Option value="10WT">10WT</Option>
                  <Option value="6W">6W</Option>
                  <Option value="6WT">6WT</Option>
                  <Option value="4WCR">4WCR</Option>
                  <Option value="4WJ">4WJ</Option>
                  <Option value="4W">4W</Option>
                </Select>
              </Form.Item>

              <Form.Item className="custom-tag-dotype" label="DO Type" name="do_type_list">
                <Select
                  mode="multiple"
                  tagRender={filterTagRender}
                  style={{ minWidth: "120px" }}
                  options={options}
                  data-testid="search-do_type_list"
                  popupClassName="popup-select-dotype"
                  showSearch
                  allowClear={false}
                />
              </Form.Item>

              <Form.Item label="Task Progress" name="task_progress">
                <Select
                  showSearch
                  style={{ minWidth: "200px" }}
                  data-testid="search-task_progress"
                  optionFilterProp={filterProps}
                >
                  <Option value="">ทั้งหมด</Option>
                  <Option value="completed">เสร็จสิ้น</Option>
                  <Option value="potential_truck_turn">รถที่มีโอกาสกลับมารับงาน</Option>
                  <Option value="not_started">ยังไม่เริ่มงาน</Option>
                  <Option value="incompleted">ยังไม่เสร็จ</Option>
                  <Option value="eq 1 left">เหลือ 1 ร้าน</Option>
                  <Option value="eq 2 left">เหลือ 2 ร้าน</Option>
                  <Option value="gt 2 left">มากกว่า 2 ร้าน</Option>
                </Select>
              </Form.Item>

              <Form.Item label="ป้ายทะเบียน" name="license">
                <Input
                  style={{ width: "100px" }}
                  onChange={(e) => {
                    form.setFieldValue("license", e.target.value.trim());
                  }}
                />
              </Form.Item>

              <Form.Item label="รหัสร้านค้า" name="store_number">
                <Input
                  style={{ width: "100px" }}
                  onChange={(e) => {
                    form.setFieldValue("store_number", e.target.value.trim());
                  }}
                />
              </Form.Item>

              <Form.Item label="Arrival Date" name="planned_arrival">
                <DatePicker disabledDate={checkDate} format={"DD-MM-YYYY"} />
              </Form.Item>

              <div className="form-button-section">
                <Form.Item label=" ">
                  <Button type="default" htmlType="reset">
                    ล้างค่า
                  </Button>
                </Form.Item>
                <Form.Item label=" ">
                  <Button className="submit-button" type="primary" htmlType="submit">
                    ค้นหา
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Card>
      </div>
      <div className="wrap-card-table-truck">
        <Card>
          <p>Upload date {showDateTime(GetDateNow())}</p>
          <div className="wrap-dashboard-collapse" id="totom-map" data-testid="tomtom-map-testid">
            <Collapse
              defaultActiveKey={toggles?.isShowMap ? [1] : []}
              activeKey={isToggleMapActive ? [1] : []}
              onChange={(key) => {
                const istoggleActive = key.length > 0;
                setIsToggleMapActive(istoggleActive);

                LocalstorageService.setConfigToggleTruckMonitor({
                  isShowDashborad: isDashboardActive,
                  isShowMap: istoggleActive,
                });
              }}
              ghost
              items={[
                {
                  key: "1",
                  label: `แผนที่ (วันที่อัพเดทล่าสุด: ${GetDateNowBeforeFetchNew()})`,
                  children: (
                    <>
                      {driverDatas.length > 0 && (
                        <TomtomMap
                          initialDriverLocations={driverDatas}
                          selectedDriver={popupDriverKey}
                          onClearPopup={(isClear) => {
                            if (isClear) {
                              setPopupDriverKey(undefined);
                            }
                          }}
                        />
                      )}
                      {driverDatas.length === 0 && (
                        <TomtomMap
                          initialDriverLocations={[]}
                          selectedDriver={undefined}
                          onClearPopup={(isClear) => {
                            if (isClear) {
                              setPopupDriverKey(undefined);
                            }
                          }}
                        />
                      )}
                    </>
                  ),
                },
              ]}
              expandIconPosition="end"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <UpCircleOutlined
                    className="collapse-icon"
                    style={{ fontSize: "22px" }}
                    data-testid="collapse-icon"
                  />
                ) : (
                  <DownCircleOutlined
                    className="collapse-icon"
                    style={{ fontSize: "22px" }}
                    data-testid="collapse-icon"
                  />
                )
              }
            />
          </div>

          <div className="wrap-dashboard-collapse">
            <Collapse
              defaultActiveKey={toggles?.isShowDashborad ? [1] : []}
              ghost
              items={items}
              expandIconPosition="end"
              onChange={(key) => {
                const istoggleActive = key.length > 0;
                setIsDashboardActive(istoggleActive);

                LocalstorageService.setConfigToggleTruckMonitor({
                  isShowDashborad: istoggleActive,
                  isShowMap: isToggleMapActive,
                });
              }}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <UpCircleOutlined
                    className="collapse-icon"
                    style={{ fontSize: "22px" }}
                    data-testid="collapse-icon"
                  />
                ) : (
                  <DownCircleOutlined
                    className="collapse-icon"
                    style={{ fontSize: "22px" }}
                    data-testid="collapse-icon"
                  />
                )
              }
            />
          </div>
          <div className="wrap-table-truck-list">
            <p data-testid="truck-monitor-result-total">รายการทั้งหมด {driverDatas.length} รายการ</p>
            <Table
              scroll={{ x: 1000 }}
              className="table-eta-list"
              loading={loadingDriverDatas}
              dataSource={driverDatas}
              columns={columns}
              pagination={{
                showSizeChanger: true,
                current: currentPage,
                defaultPageSize: pageSizeRef.current,
                onChange: (newCurrent, newPageSize) => {
                  const pageSizeChange = pageSizeRef.current !== newPageSize;
                  if (pageSizeChange) {
                    setCurrentPage(1);
                  } else {
                    setCurrentPage(newCurrent);
                  }
                  pageSizeRef.current = newPageSize;
                },
              }}
              rowKey={(record) => record.tracking_driver_id}
            />
          </div>
        </Card>
      </div>
      {contextHolderMessage}
    </Content>
  );
}
