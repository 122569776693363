import { ButtonDs, Form, FormDs, SelectDs } from "design-system";
import { Col, DatePicker, Row, Select } from "antd";
import { SearchEpodReportParams } from "../models/epodReport";
import { dcLists } from "../../../utils/DClists";
import dayjs, { Dayjs } from "dayjs";
import { ConfigEpodReportState } from "../../../shared/service/Sessionstorage.service";
import { useEffect, useState } from "react";
import { FormInstance } from "antd/lib/form/Form";

interface Props {
  form: FormInstance<any>;
  isLoading: boolean;
  onSearch: (values: SearchEpodReportParams) => void;
  configState: ConfigEpodReportState | null;
}

const EPODReportFormSearch = ({ form, isLoading, onSearch, configState }: Props) => {
  const [isSearchEmpty, setIsSearchEmpty] = useState<boolean>(true);
  const [dc, setDc] = useState<string | undefined>(undefined);
  const [plannedArrival, setPlannedArrival] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (configState && configState.search) {
      form.setFieldsValue({
        dc: configState.search.dc_code,
        planned_arrival: dayjs(configState.search.planned_arrival),
      });
      setDc(configState.search.dc_code);
      setPlannedArrival(configState.search.planned_arrival);
    }
  }, [configState]);

  useEffect(() => {
    if (dc === undefined || plannedArrival === undefined || plannedArrival === "" || dc === "") {
      setIsSearchEmpty(true);
    } else {
      setIsSearchEmpty(false);
    }
  }, [dc, plannedArrival]);

  const onFinish = () => {
    const param: SearchEpodReportParams = {
      dc_code: form.getFieldValue("dc") || "",
      planned_arrival: form.getFieldValue("planned_arrival").format() || "",
    };

    onSearch(param);
  };

  const checkDate = (date: Dayjs) => {
    return (
      date &&
      (date.isAfter(dayjs().endOf("day")) || date.isBefore(dayjs().subtract(30, "day").startOf("day")))
    );
  };

  return (
    <FormDs className="filter-form-search" form={form} autoComplete="off" requiredMark={true}>
      <Row gutter={16}>
        <Col>
          <Form.Item
            required
            className="form-item"
            label="ศูนย์กระจายสินค้า"
            name="dc"
            rules={[
              {
                required: true,
                message: "กรุณาเลือก ศูนย์กระจายสินค้า",
              },
            ]}
          >
            <SelectDs
              style={{
                width: 280,
              }}
              showSearch
              placeholder="เลือกศูนย์กระจายสินค้า"
              optionFilterProp="children"
              data-testid="select-dc"
              onChange={(value) => {
                setDc(value);
                form.setFieldsValue({
                  planned_arrival: dayjs(),
                });
                setPlannedArrival(dayjs().format());
              }}
            >
              {dcLists().map((x) => (
                <Select.Option key={x.value} value={x.value}>
                  {x.label}
                </Select.Option>
              ))}
            </SelectDs>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            required
            className="form-item"
            label="วันที่จัดส่ง"
            name="planned_arrival"
            rules={[
              {
                required: true,
                message: "กรุณาเลือกวันที่จัดส่ง",
              },
            ]}
          >
            <DatePicker
              className="date-picker-input"
              placeholder="เลือกวันที่จัดส่ง"
              size="large"
              disabledDate={checkDate}
              format={"DD-MM-YYYY"}
              allowClear={false}
              onChange={(value) => setPlannedArrival(dayjs(value).format())}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label={<>&nbsp;</>}>
            <ButtonDs
              type="primary"
              htmlType="submit"
              onClick={onFinish}
              disabled={isSearchEmpty}
              loading={isLoading}
            >
              ค้นหา
            </ButtonDs>
          </Form.Item>
        </Col>
      </Row>
    </FormDs>
  );
};

export default EPODReportFormSearch;
