import { UndoOutlined } from "@ant-design/icons";
import { ButtonDs, Form, FormDs, InputDs, SelectDs } from "design-system";
import { Col, DatePicker, Row } from "antd";
import { Booking, BookingRequestSearchValue } from "../models/booking";
import { bookingTruckTypeLists } from "../../../utils/truckTypeLists";
import dayjs, { Dayjs } from "dayjs";
import BookingRequestService from "../services/booking-request-service";
import { useEffect } from "react";

interface Props {
  dataSource: Booking[] | undefined;
  isLoading: boolean;
  onSearch: (values: BookingRequestSearchValue | undefined) => void;
  onSavedValue: (searchValue: BookingRequestSearchValue) => void;
  onReset: () => void;
  configState: BookingRequestSearchValue | undefined;
  customerList: { label: string; value: string }[];
}

const BookingRequestFormSearch = ({
  dataSource,
  isLoading,
  onSearch,
  onReset,
  configState,
  onSavedValue,
  customerList,
}: Props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    BookingRequestService.signalReset.subscribe((signal) => {
      if (signal === true) {
        onResetForm();
      }
    });

    return () => {
      BookingRequestService.signalReset.next(false);
    };
  }, []);

  useEffect(() => {
    if (dataSource !== undefined) {
      setSearchValue(configState);
    }
  }, [dataSource]);

  const setSearchValue = (searchValue: BookingRequestSearchValue | undefined) => {
    form.setFieldsValue({
      pickup_date: searchValue?.pickup_date ? dayjs(searchValue?.pickup_date) : undefined,
      booking_id: searchValue?.booking_id ? searchValue?.booking_id : undefined,
      customer: searchValue?.company_id !== undefined ? searchValue?.company_id : "all",
      truck_type: searchValue?.truck_type !== undefined ? searchValue?.truck_type : "all",
    });
  };

  const onFinish = (values: any) => {
    const pickupDate = values.pickup_date;
    const bookingId: string = values.booking_id;
    const customer = values.customer;
    const status = values.status;
    const truckType = values.truck_type;

    const formatBookingId = bookingId && bookingId.trim();

    const searchValue: BookingRequestSearchValue = {
      pickup_date: pickupDate,
      company_id: customer,
      status: status,
      booking_id: formatBookingId,
      truck_type: truckType,
    };

    searchData(searchValue);
    onSavedValue(searchValue);
  };

  const searchData = (searchValue: BookingRequestSearchValue | undefined) => {
    onSearch(searchValue);
  };

  const onResetForm = () => {
    form.resetFields();
    onReset();
  };

  const checkDate = (date: Dayjs) => {
    return date && date.isBefore(dayjs().subtract(30, "day").startOf("day"));
  };

  return (
    <FormDs
      style={{ display: "flex" }}
      form={form}
      initialValues={{
        customer: "all",
        status: "all",
        truck_type: "all",
      }}
      onFinish={onFinish}
      autoComplete="off"
      requiredMark={false}
    >
      <Row gutter={16}>
        <Col>
          <Form.Item className="form-item" label="วันที่เข้ารับ" name="pickup_date">
            <DatePicker
              className="date-picker-input"
              placeholder="From"
              size="large"
              disabledDate={checkDate}
              format={"DD-MM-YYYY"}
              allowClear={true}
              style={{ width: 200 }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="ลูกค้า" name="customer">
            <SelectDs options={customerList} style={{ width: 300 }} placeholder="เลือกลูกค้า" />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="รหัสงานขนส่ง" name="booking_id">
            <InputDs
              placeholder="ค้นหารหัสขนส่ง"
              style={{ width: 200 }}
              allowClear
              onBlur={(v) => {
                const value = v.target.value;
                const trimValue = value.trim();
                form.setFieldsValue({ booking_id: trimValue });
              }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="ประเภทรถขนส่ง" name="truck_type">
            <SelectDs
              options={bookingTruckTypeLists}
              style={{ width: 200 }}
              placeholder="เลือกประเภทรถขนส่ง"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label={<>&nbsp;</>}>
            <ButtonDs type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
              ค้นหา
            </ButtonDs>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label={<>&nbsp;</>}>
            <ButtonDs type="default" disabled={isLoading} onClick={onResetForm}>
              <UndoOutlined />
              รีเซ็ต
            </ButtonDs>
          </Form.Item>
        </Col>
      </Row>
    </FormDs>
  );
};

export default BookingRequestFormSearch;
