import * as ApiEndPoint from "../../shared/api/ApiEndPoint";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiClient from "../../shared/api/ApiClient";
import { RootState } from "../../shared/store/store";

type UploadETA = {
  loading: boolean;
  response: any;
};

const initialValues: UploadETA = {
  loading: false,
  response: null,
};

export const uploadEtasFile = createAsyncThunk(
  "request/uploadEtasFile",
  async (dataUpload: any, { rejectWithValue }) => {
    try {
      const blob = dataUpload.file.file.originFileObj;
      const formData = new FormData();
      formData.append("file", blob);
      formData.append("dc_code", dataUpload.dc);
      const response = await ApiClient.post(ApiEndPoint.UPLOAD_ETAS, formData, { timeout: 3600000 }); // set timeout to one hour.
      return response.data;
    } catch (err) {
      if (err instanceof Error) {
        return rejectWithValue(err);
      }
    }
  },
);

const etasUpload = createSlice({
  name: "etasUpload",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadEtasFile.pending, (state) => {
      state.loading = true;
      state.response = null;
    });

    builder.addCase(uploadEtasFile.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
    });

    builder.addCase(uploadEtasFile.rejected, (state) => {
      state.loading = false;
      state.response = null;
    });
  },
});

export const etasUploadIsLoading = (store: RootState) => store.etasUpload.loading;
export const etasUploadResponse = (store: RootState) => store.etasUpload.response;
export default etasUpload.reducer;
