import "./style.scss";

import { Button, Form, Input, message } from "antd";

import { LoginData } from "./LoginInterface";
import { useForm } from "antd/lib/form/Form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IdTokenResult, User, UserCredential, signInWithEmailAndPassword } from "firebase/auth";
import { getAuth } from "../../shared/api/FirebaseAuth";
import LocalstorageService from "../../shared/service/Localstorage.service";
import { getUserAuthInfo } from "../../shared/api/UserAuthEndPoint";
import { Company } from "../../shared/type/Company";

export default function Login() {
  const [form] = useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const tokens = LocalstorageService.getTokens();
    if (tokens) {
      navigate("/");
    } else {
      localStorage.removeItem("userAuthInfo");
      localStorage.removeItem("company");
    }
  }, []);

  // ต้องเปลี่ยน onFinish ให้เปลี่ยนตาม state changed
  const onFinish = async (values: LoginData) => {
    const dataLogin: LoginData = {
      email: values.email,
      password: values.password,
    };

    try {
      setIsLoading(true);
      const userCredential: UserCredential = await signInWithEmailAndPassword(
        getAuth(),
        dataLogin.email,
        dataLogin.password,
      );

      const user: User = userCredential.user;
      const idTokenResult: IdTokenResult = await user.getIdTokenResult();
      LocalstorageService.setTokens({
        accessToken: idTokenResult.token,
        tokenExpire: Date.parse(idTokenResult.expirationTime) / 1000,
      });

      await fetchUserAuthInfo(user.uid);
      message.success("Login completed");
      const company = LocalstorageService.getCompany();
      const fromParam = searchParams.get("from");
      setIsLoading(false);
      if (fromParam === "agent-access-token") {
        navigate("/agent-access-token");
        return false;
      }
      if (company) {
        if (company === "TDM") {
          navigate("/booking-request");
          return false;
        } else {
          navigate("/truck-monitor");
          return false;
        }
      } else {
        navigate("/select-company");
        return false;
      }
    } catch (err: any) {
      const errMessage = err.response?.data.message ? err.response?.data.message : err.message;
      message.error(errMessage);
      LocalstorageService.clear();
      setIsLoading(false);
    }
  };

  const fetchUserAuthInfo = async (uid: string) => {
    let userAuthInfo = LocalstorageService.getUserAuthInfo();

    if (userAuthInfo === null) {
      userAuthInfo = await getUserAuthInfo(uid);
      LocalstorageService.setUserAuthInfo(userAuthInfo);
    }

    if (userAuthInfo.accessor_companies.length === 1) {
      LocalstorageService.setCompany(userAuthInfo.accessor_companies[0] as Company);
    }

    return userAuthInfo;
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="wrap-login-page">
      <div className="wrap-form">
        <Form
          form={form}
          name="login"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                form.setFieldValue("email", e.target.value.trim());
              }}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              onChange={(e) => {
                form.setFieldValue("password", e.target.value.trim());
              }}
            />
          </Form.Item>
          <div className="wrap-forget-pass-action">
            <Button
              type="link"
              onClick={() => {
                navigate("/forgot-password");
              }}
            >
              Forget Password?
            </Button>
          </div>
          <div className="wrap-login-action">
            <Button style={{ marginRight: 12 }}>Cancel</Button>
            <Button loading={isLoading} type="primary" htmlType="submit">
              OK
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
