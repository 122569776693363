import { Card, Col, Layout, Row, notification } from "antd";
import DesignSystemTheme from "../../shared/component/DesignSystemTheme";
import "./style.scss";
import { PlusOutlined } from "@ant-design/icons";
import DataTable from "./components/DataTable";
import FormSearch from "./components/FormSearch";
import { useEffect, useRef, useState } from "react";
import { User, UserInfo } from "./models/user";
import UserManagementService from "./services/user-management-service";
import ModalCreateUser from "./components/ModalCreateUser";
import { CreateUserParam } from "./models/user";
import { NotificationComponent } from "../../shared/component/NotificationComponent";
import TooltipComponent from "../../shared/component/TooltipComponent";
import { ButtonDs } from "design-system";
import LocalstorageService from "../../shared/service/Localstorage.service";

const { Content } = Layout;

const NewUserManagement = () => {
  const [dataSource, setDataSource] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataDisplay, setDataDisplay] = useState<User[]>([]);
  const [isModalCreateUserOpen, setIsModalCreateUserOpen] = useState(false);
  const userUpdated = useRef<UserInfo | null>(null);
  const [notificationComponent, contextHolderNoti] = notification.useNotification();
  const userRole = LocalstorageService.getCurrentRole();

  useEffect(() => {
    getUserList();
    return () => {
      UserManagementService.userUpdated.next(null);
      UserManagementService.userDeleted.next(null);
    };
  }, []);

  useEffect(() => {
    UserManagementService.userUpdated.subscribe((res) => {
      if (res) {
        userUpdated.current = res;
        openNotiSuccess();
      }
    });

    UserManagementService.userDeleted.subscribe((res) => {
      if (res) {
        openNotiDeleteSuccess(res);
      }
    });
  }, []);

  const getUserList = () => {
    setIsLoading(true);
    UserManagementService.getUsers().then(
      (res) => {
        let sourceList: User[] = res;
        if (userUpdated.current) {
          const updatedUserIndex = res.findIndex((user) => user.email === userUpdated.current?.email);
          const userUpdate = res.at(updatedUserIndex);
          if (userUpdate) {
            userUpdate.is_update_user = true;
            res.splice(updatedUserIndex, 1);
            sourceList = [userUpdate, ...res];
          }
        }
        setDataSource(sourceList);
        setDataDisplay(sourceList);
        setIsLoading(false);
      },
      (err) => {
        console.log("err", err);
        setIsLoading(false);
      },
    );
  };

  const addNewUserForTable = (data: CreateUserParam) => {
    setIsLoading(true);
    UserManagementService.getUsers().then(
      (res) => {
        setDataSource(res.map((user) => user));

        const newUserIndex = res.findIndex((user) => user.email === data.email);
        const newUser: User = { ...res[newUserIndex], is_new_user: true };
        res.splice(newUserIndex, 1);

        setDataDisplay([newUser, ...res]);
        setIsLoading(false);
      },
      (err) => {
        console.log("err", err);
        setIsLoading(false);
      },
    );
  };

  const onReset = () => {
    UserManagementService.signalReset.next(true);
    setIsLoading(true);
    setDataDisplay(dataSource);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const openNotiSuccess = () => {
    NotificationComponent({
      notification: notificationComponent,
      type: "success",
      topic: "แก้ไขข้อมูลสำเร็จ",
      message: "แก้ไขข้อมูลผู้ใช้งานสำเร็จ",
    });
  };

  const openNotiDeleteSuccess = (userInfo: UserInfo) => {
    NotificationComponent({
      notification: notificationComponent,
      type: "success",
      topic: "ลบบัญชีผู้ใช้งานสำเร็จ",
      message: `ลบบัญชีผู้ใช้งาน ${userInfo.email} สำเร็จ`,
    });
  };

  return (
    <DesignSystemTheme>
      <Content className="user-management-content">
        <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
          <h1>User Management</h1>
          <TooltipComponent title="ส่วนการจัดการบัญชีผู้ใช้" />
        </div>
        <Card>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <FormSearch
                dataSource={dataSource}
                isLoading={isLoading}
                onSearch={(data) => {
                  setIsLoading(true);
                  setDataDisplay(data);
                  setTimeout(() => {
                    setIsLoading(false);
                  }, 500);
                }}
                onReset={onReset}
              />
            </Col>
            <Col
              span={24}
              style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}
            >
              <div>ทั้งหมด {dataDisplay.length} รายการ</div>
              {userRole?.includes("admin") && (
                <ButtonDs
                  disabled={isLoading}
                  type="primary"
                  size="large"
                  onClick={() => {
                    onReset();
                    setIsModalCreateUserOpen(true);
                  }}
                >
                  <PlusOutlined />
                  เพิ่ม User ใหม่
                </ButtonDs>
              )}
            </Col>
            <Col span={24}>
              <DataTable dataSource={dataDisplay} isLoading={isLoading} />
            </Col>
          </Row>
        </Card>
        <ModalCreateUser
          open={isModalCreateUserOpen}
          onCancel={() => setIsModalCreateUserOpen(false)}
          onOk={(newUser: CreateUserParam) => {
            addNewUserForTable(newUser);
          }}
          datasource={dataSource}
        />
      </Content>
      {contextHolderNoti}
    </DesignSystemTheme>
  );
};

export default NewUserManagement;
