import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiClient from "../../shared/api/ApiClient";
import { SENT_EMAIL_TO_FORGOT_PASSWORD } from "../../shared/api/ApiEndPoint";
import { RootState } from "../../shared/store/store";

type ForgotPasswordState = {
  loadingResetPassword: boolean;
  isComplete: boolean;
};

export const initialState: ForgotPasswordState = {
  loadingResetPassword: false,
  isComplete: false,
};

export const sentUIDToForgotPassword = createAsyncThunk(
  "request/sentUIDToForgotPassword",
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await ApiClient.post(SENT_EMAIL_TO_FORGOT_PASSWORD, { email: email });
      return response.data;
    } catch (err) {
      if (err instanceof Error) {
        return rejectWithValue(err.message);
      }
    }
  },
);

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sentUIDToForgotPassword.pending, (state) => {
      state.loadingResetPassword = true;
    });

    builder.addCase(sentUIDToForgotPassword.rejected, (state) => {
      state.loadingResetPassword = false;
      state.isComplete = false;
    });

    builder.addCase(sentUIDToForgotPassword.fulfilled, (state) => {
      state.loadingResetPassword = false;
      state.isComplete = true;
    });
  },
});

export default resetPasswordSlice.reducer;
export const loadingResetPassword = (state: RootState) => state.forgotPassword.loadingResetPassword;
export const isComplete = (state: RootState) => state.forgotPassword.isComplete;
