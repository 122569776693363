import { Tag } from "antd";

type Props = {
  status: string;
};

enum TagStatus {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  INFO = "info",
  DEFAULT = "default",
}

const StatusTag = ({ status }: Props) => {
  const tagsMessage: { [key: string]: { text: string; status: TagStatus } } = {
    // Task Status
    pending: { text: "รอดำเนินการ", status: TagStatus.INFO },
    confirmed: { text: "ยืนยันการจอง", status: TagStatus.SUCCESS },
    canceled: { text: "ยกเลิกการจอง", status: TagStatus.DEFAULT },
    rejected: { text: "ปฏิเสธการจอง", status: TagStatus.ERROR },

    // Pickup Status
    "waiting-pickup": { text: "รอดำเนินการรับ", status: TagStatus.DEFAULT },
    "pickup-arrived": { text: "ถึงจุดรับสินค้า", status: TagStatus.WARNING },
    "start-load": { text: "เริ่มโหลดสินค้า", status: TagStatus.INFO },
    loaded: { text: "รับสินค้าสำเร็จ", status: TagStatus.SUCCESS },

    // Sent Status
    waiting: { text: "รอดำเนินการส่ง", status: TagStatus.DEFAULT },
    "in-transit": { text: "ระหว่างขนส่ง", status: TagStatus.DEFAULT },
    arrived: { text: "ถึงจุดส่งสินค้า", status: TagStatus.WARNING },
    delivered: { text: "กำลังลงสินค้า", status: TagStatus.INFO },
    success: { text: "จัดส่งสำเร็จ", status: TagStatus.SUCCESS },
    exception: { text: "จัดส่งไม่สำเร็จ", status: TagStatus.ERROR },
  };
  const tag = (text: string, status: string) => {
    return <Tag className={`status-tag ${status}`}>{text}</Tag>;
  };

  if (status !== "") {
    return tag(tagsMessage[status].text, tagsMessage[status].status);
  } else {
    return <></>;
  }
};

export default StatusTag;
