import { useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { LoginData } from "../../Login/LoginInterface";
import { IdTokenResult, signInWithEmailAndPassword, User, UserCredential } from "firebase/auth";
import { getAuth } from "../../../shared/api/FirebaseAuth";
import LocalstorageService from "../../../shared/service/Localstorage.service";
import { getExternalUserAuthInfo } from "../../../shared/api/UserAuthEndPoint";

export default function ExternalLogin() {
  const [form] = useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const tokens = LocalstorageService.getTokens();
    if (tokens) {
      navigate("/external-api-key");
    } else {
      localStorage.removeItem("userAuthInfo");
    }
  }, [navigate]);

  const onFinish = async (values: LoginData) => {
    const dataLogin: LoginData = {
      email: values.email,
      password: values.password,
    };

    try {
      setIsLoading(true);
      const userCredential: UserCredential = await signInWithEmailAndPassword(
        getAuth(),
        dataLogin.email,
        dataLogin.password,
      );

      const user: User = userCredential.user;
      const idTokenResult: IdTokenResult = await user.getIdTokenResult();
      LocalstorageService.setTokens({
        accessToken: idTokenResult.token,
        tokenExpire: Date.parse(idTokenResult.expirationTime) / 1000,
      });

      await fetchUserAuthInfo(user.uid);
      const role = LocalstorageService.getExternalCurrentRole();

      if (role === "developer") {
        LocalstorageService.setConfigExternal(true);
        message.success("Login completed");
        navigate("/external-api-key");
      }
    } catch (err: any) {
      const errMessage = err.response?.data.message ? err.response?.data.message : err.message;
      message.error(errMessage);
    } finally {
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const fetchUserAuthInfo = async (uid: string) => {
    let userAuthInfo = LocalstorageService.getUserAuthInfo();

    if (userAuthInfo === null) {
      userAuthInfo = await getExternalUserAuthInfo(uid);
      LocalstorageService.setUserAuthInfo(userAuthInfo);
    }

    return userAuthInfo;
  };

  return (
    <div className="wrap-login-page">
      <div className="wrap-form">
        <Form
          form={form}
          name="login"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                form.setFieldValue("email", e.target.value.trim());
              }}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              onChange={(e) => {
                form.setFieldValue("password", e.target.value.trim());
              }}
            />
          </Form.Item>

          <div className="wrap-login-action">
            <Button style={{ marginRight: 12 }}>Cancel</Button>
            <Button loading={isLoading} type="primary" htmlType="submit">
              OK
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
