import { BehaviorSubject } from "rxjs";
import { getDCInformation } from "../feature/ReturnEta/ReturnEta";
import LocalstorageService from "../shared/service/Localstorage.service";
import SessionstorageService from "../shared/service/Sessionstorage.service";

export const dcList = new BehaviorSubject<
  | {
      value: string;
      label: string;
    }[]
  | null
>(null);

export const dcLists = () => {
  let dcLists = SessionstorageService.getDCMaster();
  if (dcLists.length === 0) {
    getDCInformation()
      .then((res) => {
        SessionstorageService.setDCMaster(Object.keys(res));
        dcLists = SessionstorageService.getDCMaster();
        dcList.next(dcLists);
      })
      .catch(() => {
        SessionstorageService.setDCMaster([]);
      });
  }
  return dcLists;
};

export const isCJ = () => {
  const company = LocalstorageService.getCompany();
  if (company === "CJ") return true;
  return false;
};
