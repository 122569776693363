import axios from "axios";

export const checkTomTomApiKey = (
  tomtom_key: string,
): Promise<{ formatVersion: string; copyrightsCaption: string }> => {
  return axios
    .get<{ formatVersion: string; copyrightsCaption: string }>(
      `https://api.tomtom.com/map/2/copyrights/caption.json?key=${tomtom_key}`,
    )
    .then(({ data }) => data);
};
