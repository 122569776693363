import { ButtonDs, Form, FormDs, InputDs, SelectDs } from "design-system";
import { provincesOptions } from "../../../shared/service/province-list-option";
import { UndoOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import StoreMasterService from "../services/store-master-service";

interface Props {
  onSearch: (values: any) => void;
  onResetSearch: () => void;
  isLoading: boolean;
}

const FormSearch = ({ onSearch, onResetSearch, isLoading }: Props) => {
  const [form] = Form.useForm();
  const [signalResetForm, setSignalResetForm] = useState(false);

  useEffect(() => {
    StoreMasterService.signalResetForm.subscribe((loading) => {
      setSignalResetForm(loading);
    });
  }, []);

  useEffect(() => {
    if (signalResetForm) {
      form.resetFields();
    }
  }, [signalResetForm]);

  const registrationStatusOptions = [
    {
      label: "ลงทะเบียน",
      value: "true",
    },
    {
      label: "ยังไม่ลงทะเบียน",
      value: "false",
    },
  ];

  const allowSendMessageOptions = [
    {
      label: "อนุญาต",
      value: "true",
    },
    {
      label: "ไม่อนุญาต",
      value: "false",
    },
  ];

  const enabledGeofenceOptions = [
    {
      label: "เปิด",
      value: "true",
    },
    {
      label: "ปิด",
      value: "false",
    },
  ];

  const onFinish = (value: any) => {
    onSearch(value);
  };

  const onResetForm = () => {
    form.resetFields();
    onResetSearch();
  };

  return (
    <FormDs
      className="form-search"
      form={form}
      onFinish={onFinish}
      onReset={onResetForm}
      autoComplete="off"
      requiredMark={false}
    >
      <Row gutter={16}>
        <Col>
          <Form.Item label="ชื่อร้านค้า" name="store_name">
            <InputDs
              placeholder="ค้นหาจาก ชื่อร้านค้า"
              style={{ width: 200 }}
              onBlur={() => {
                if (form.getFieldValue("store_name")) {
                  form.setFieldValue("store_name", form.getFieldValue("store_name").trim());
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="รหัสร้านค้า" name="store_number">
            <InputDs
              placeholder="ค้นหาจาก รหัสร้านค้า"
              style={{ width: 200 }}
              onBlur={() => {
                if (form.getFieldValue("store_number")) {
                  form.setFieldValue("store_number", form.getFieldValue("store_number").trim());
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="จังหวัด" name="province">
            <SelectDs options={provincesOptions} style={{ width: 200 }} placeholder="ระบุจังหวัด" />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="สถานะการลงทะเบียน" name="registration_status">
            <SelectDs
              options={registrationStatusOptions}
              style={{ width: 200 }}
              placeholder="สถานะการลงทะเบียน"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="การอนุญาตให้ส่งข้อความ" name="enabled_notification">
            <SelectDs
              options={allowSendMessageOptions}
              style={{ width: 200 }}
              placeholder="การอนุญาตส่งข้อความ"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="การตรวจสอบตำแหน่ง" name="enabled_checking_geofence">
            <SelectDs
              options={enabledGeofenceOptions}
              style={{ width: 200 }}
              placeholder="การตรวจสอบตำแหน่ง"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="submit">
            <ButtonDs type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
              ค้นหา
            </ButtonDs>
          </Form.Item>
          <Form.Item name="reset" style={{ paddingLeft: 13 }}>
            <ButtonDs type="default" htmlType="reset" disabled={isLoading}>
              <UndoOutlined />
              รีเซ็ต
            </ButtonDs>
          </Form.Item>
        </Col>
      </Row>
    </FormDs>
  );
};

export default FormSearch;
