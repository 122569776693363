import {
  DASHBOARD,
  DOWNLOAD_TRIPS,
  LIST_DRIVER,
  SEARCH_DRIVER,
  STORE_PROGRESS,
} from "../../shared/api/ApiEndPoint";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiClient from "../../shared/api/ApiClient";
import { Dashboard, Driver, StoreProgress } from "./Driver";
import { RootState } from "../../shared/store/store";
import { ErrorResponse } from "../../shared/type/ErrorType";
import axios, { AxiosError } from "axios";

type TruckMonitorState = {
  drivers: Driver[];
  loading: boolean;

  dashboards: Dashboard | null;
  dashboardLoading: boolean;
  dashboardError: ErrorResponse | null;

  downloadTripsError: ErrorResponse | null;
};

const initialValues: TruckMonitorState = {
  drivers: [],
  dashboards: null,

  loading: false,
  dashboardLoading: false,
  dashboardError: null,

  downloadTripsError: null,
};

export const listDriver = createAsyncThunk<
  Driver[],
  null,
  {
    rejectValue: ErrorResponse;
  }
>("request/listDriver", async (_, { rejectWithValue }) => {
  try {
    const response = await ApiClient.get(LIST_DRIVER);
    return response.data;
  } catch (err) {
    if (err instanceof AxiosError) {
      return rejectWithValue(err.response?.data);
    }
  }
});

export const listDashboard = createAsyncThunk<
  Dashboard,
  SearchParams,
  {
    rejectValue: ErrorResponse;
  }
>("request/dashboard", async (params: any, { rejectWithValue }) => {
  try {
    let headers = {};
    if (params.test_search_error !== null) {
      headers = {
        "X-Test-Error-Flag": `test_search_error=${params.test_search_error}`,
      };
    }

    const response = await ApiClient.post(DASHBOARD, params, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    if (err instanceof AxiosError) {
      return rejectWithValue(err.response?.data);
    }
  }
});

export type SearchParams = {
  dc_code: string;
  truck_plate_number: string;
  sync_status: string;
  store_number: string;
  load: string;
  sla: string;
  truck_type: string;
  do_type_list: string[];
  planned_arrival: string;
  task_progress: string;
  test_search_error: string | null;
  current_time: string;
};

export const searchDriver = createAsyncThunk<
  Driver[],
  SearchParams,
  {
    rejectValue: ErrorResponse;
  }
>("request/searchDriver", async (params: SearchParams, { rejectWithValue }) => {
  try {
    let headers = {};
    if (params.test_search_error !== null) {
      headers = {
        "X-Test-Error-Flag": `test_search_error=${params.test_search_error}`,
      };
    }
    const response = await ApiClient.post(SEARCH_DRIVER, params, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    if (err instanceof AxiosError) {
      return rejectWithValue(err.response?.data);
    }
  }
});

export const downloadTrips = createAsyncThunk<
  any,
  SearchParams,
  {
    rejectValue: ErrorResponse;
  }
>("request/downloadTrips", async (params: SearchParams, { rejectWithValue }) => {
  try {
    const response = await ApiClient.post(DOWNLOAD_TRIPS, { ...params }, { responseType: "blob" });
    const filename = response.headers["content-disposition"].split(";")[1].split("=")[1];

    const link = document.createElement("a");
    link.target = "_blank";
    link.href = URL.createObjectURL(response.data);
    link.setAttribute("download", decodeURI(filename));
    link.click();
    return null;
  } catch (err) {
    if (err instanceof AxiosError) {
      const errorResponse: ErrorResponse = {
        status: err.response?.status || 500,
        message: err.response?.data,
      };
      return rejectWithValue(errorResponse);
    }
  }
});

const driverSlice = createSlice({
  name: "driver",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listDriver.pending, (state) => {
      state.loading = true;
      state.drivers = [];
    });

    builder.addCase(listDriver.fulfilled, (state, action) => {
      state.loading = false;
      state.drivers = action.payload;
    });

    builder.addCase(listDriver.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(searchDriver.pending, (state) => {
      state.loading = true;
      state.drivers = [];
    });

    builder.addCase(searchDriver.fulfilled, (state, action) => {
      state.loading = false;
      state.drivers = action.payload;
    });

    builder.addCase(searchDriver.rejected, (state) => {
      state.loading = false;
    });

    // Dashboard
    builder.addCase(listDashboard.pending, (state) => {
      state.dashboardLoading = true;
      state.dashboards = null;
      state.dashboardError = null;
    });
    builder.addCase(listDashboard.fulfilled, (state, action) => {
      state.dashboardLoading = false;
      state.dashboards = action.payload;
      state.dashboardError = null;
    });
    builder.addCase(listDashboard.rejected, (state, action) => {
      state.dashboardLoading = false;
      state.dashboardError = action.payload ? action.payload : null;
    });

    // Download Trips
    builder.addCase(downloadTrips.pending, (state) => {
      state.downloadTripsError = null;
    });
    builder.addCase(downloadTrips.fulfilled, (state) => {
      state.downloadTripsError = null;
    });
    builder.addCase(downloadTrips.rejected, (state, action) => {
      state.downloadTripsError = action.payload ? action.payload : null;
    });
  },
});

export const storeProgress = (trip_id: string): Promise<StoreProgress> => {
  return ApiClient.get<StoreProgress>(STORE_PROGRESS(trip_id)).then(({ data }) => data);
};

export const checkTomTomApiKey = (
  tomtom_key: string,
): Promise<{ formatVersion: string; copyrightsCaption: string }> => {
  return axios
    .get<{ formatVersion: string; copyrightsCaption: string }>(
      `https://api.tomtom.com/map/2/copyrights/caption.json?key=${tomtom_key}`,
    )
    .then(({ data }) => data);
};

export const drivers = (store: RootState) => store.truckMonitor.drivers;
export const driversLoading = (store: RootState) => store.truckMonitor.loading;
export const dashboards = (store: RootState) => store.truckMonitor.dashboards;
export const dashboardDataLoading = (store: RootState) => store.truckMonitor.dashboardLoading;
export const dashboardError = (store: RootState) => store.truckMonitor.dashboardError;
export const downloadTripsErrorResponse = (store: RootState) => store.truckMonitor.downloadTripsError;
export default driverSlice.reducer;
