import * as ApiEndPoint from "../../shared/api/ApiEndPoint";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiClient from "../../shared/api/ApiClient";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../shared/type/ErrorType";
import { RootState } from "../../shared/store/store";

type DownloadEtaState = {
  downloadEtaError: ErrorResponse | null;
  downloadLatestEtaError: ErrorResponse | null;
};

const initialState: DownloadEtaState = {
  downloadEtaError: null,
  downloadLatestEtaError: null,
};

export const downloadEta = createAsyncThunk<any, string, { rejectValue: ErrorResponse }>(
  "request/downloadEta",
  async (dcCode: string, { rejectWithValue }) => {
    try {
      const response = await ApiClient.get(`${ApiEndPoint.DOWNLOAD_ETA}/${dcCode}/download`, {
        responseType: "blob",
      });
      const filename = response.headers["content-disposition"].split(";")[1].split("=")[1];

      const link = document.createElement("a");
      link.target = "_blank";
      link.href = URL.createObjectURL(response.data);
      link.setAttribute("download", decodeURI(filename));
      link.click();
      return null;
    } catch (err) {
      if (err instanceof AxiosError) {
        return rejectWithValue(err.response?.data);
      }
    }
  },
);

export const downloadLatestEta = createAsyncThunk<any, string, { rejectValue: ErrorResponse }>(
  "request/downloadLatestEta",
  async (dcCode: string, { rejectWithValue }) => {
    try {
      const response = await ApiClient.get(`${ApiEndPoint.DOWNLOAD_ETA}/${dcCode}/download/latest`, {
        responseType: "blob",
      });
      const filename = response.headers["content-disposition"].split(";")[1].split("=")[1];

      const link = document.createElement("a");
      link.target = "_blank";
      link.href = URL.createObjectURL(response.data);
      link.setAttribute("download", decodeURI(filename));
      link.click();
      return null;
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorResponse: ErrorResponse = {
          status: err.response?.status || 500,
          message: err.response?.data,
        };
        return rejectWithValue(errorResponse);
      }
    }
  },
);

const downloadEtaSlice = createSlice({
  name: "downloadEta",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(downloadEta.pending, (state) => {
      state.downloadEtaError = null;
    });
    builder.addCase(downloadEta.fulfilled, (state) => {
      state.downloadEtaError = null;
    });
    builder.addCase(downloadEta.rejected, (state, action) => {
      state.downloadEtaError = action.payload ? action.payload : null;
    });

    builder.addCase(downloadLatestEta.pending, (state) => {
      state.downloadLatestEtaError = null;
    });
    builder.addCase(downloadLatestEta.fulfilled, (state) => {
      state.downloadLatestEtaError = null;
    });
    builder.addCase(downloadLatestEta.rejected, (state, action) => {
      state.downloadLatestEtaError = action.payload ? action.payload : null;
    });
  },
});

export default downloadEtaSlice.reducer;
export const downloadEtaError = (store: RootState) => store.etaDownload.downloadEtaError;
export const downloadLatestEtaError = (store: RootState) => store.etaDownload.downloadLatestEtaError;
