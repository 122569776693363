import React from "react";
import { Modal } from "antd";
import { ButtonDs } from "design-system";

interface Props {
  title: React.ReactNode;
  onClickConfirmCancel: () => void;
  onClickConfirmOK: () => void;
  open: boolean;
  loading?: boolean;
  children: React.ReactNode;
  closeIcon?: boolean;
  footer?: React.ReactNode;
}
const ConfirmModal = ({
  title,
  onClickConfirmCancel,
  onClickConfirmOK,
  open,
  children,
  loading,
  closeIcon,
  footer,
}: Props) => {
  return (
    <Modal
      maskClosable={false}
      centered
      closeIcon={closeIcon}
      open={open}
      title={title}
      onCancel={onClickConfirmCancel}
      footer={
        footer ? (
          footer
        ) : (
          <>
            <ButtonDs type="default" onClick={onClickConfirmCancel} disabled={loading}>
              ยกเลิก
            </ButtonDs>
            <ButtonDs htmlType="submit" type="primary" onClick={onClickConfirmOK} loading={loading}>
              ยืนยัน
            </ButtonDs>
          </>
        )
      }
    >
      {children}
    </Modal>
  );
};

export default ConfirmModal;
