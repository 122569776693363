import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

interface SpinnerProps {
  spinning: boolean;
  children: React.ReactNode;
}

const SpinComponent = ({ spinning, children }: SpinnerProps) => (
  <Spin
    spinning={spinning}
    wrapperClassName={spinning ? "spinner-component" : ""}
    tip={
      <span>
        <p style={{ color: "white", textShadow: "none", paddingTop: "10px", marginBottom: "4px" }}>
          กำลังโหลดข้อมูล...
        </p>
        <p style={{ color: "#A3A9B3", textShadow: "none" }}>กรุณารอสักครู่</p>
      </span>
    }
    indicator={<LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />}
  >
    {children}
  </Spin>
);

export default SpinComponent;
