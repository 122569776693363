import { QuestionCircleOutlined } from "@ant-design/icons";
import DesignSystemTheme from "../../shared/component/DesignSystemTheme";
import { Alert, Card, Col, Layout, Row, Space, notification } from "antd";
import "./style.scss";
import dayjs from "dayjs";
import MessagingStatusFormSearch from "./components/MessagingStatusFormSearch";
import { ButtonDs } from "design-system";
import { NotificationInfo } from "./models/messaging-status.model";
import { useEffect, useState } from "react";
import MessagingStatusDataTable from "./components/MessagingStatusDataTable";
import MessageingStatusService from "./services/messaging-status-service";
import ConfirmModal from "../../shared/component/ConfirmModal";
import TooltipComponent from "../../shared/component/TooltipComponent";
import { NotificationComponent } from "../../shared/component/NotificationComponent";
import LocalstorageService from "../../shared/service/Localstorage.service";

const { Content } = Layout;

const MessagingStatus = () => {
  const userRole = LocalstorageService.getCurrentRole();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState<NotificationInfo[]>([]);
  const [dataDisplay, setDataDisplay] = useState<NotificationInfo[]>([]);
  const [selectedRows, setSelectedRows] = useState<NotificationInfo[]>([]);
  const [isConfirmSendMessageModalOpen, setIsConfirmSendMessageModalOpen] = useState(false);
  const [notificationComponent, contextHolderNoti] = notification.useNotification();
  const [messageError, setMessageError] = useState<string | null>(null);

  useEffect(() => {
    getNotificationsData();
  }, []);

  const getNotificationsData = () => {
    setIsLoading(true);
    MessageingStatusService.getNotifications().then(
      (res) => {
        if (res) {
          setDataSource(res);
          setDataDisplay(res);
        }
        setIsLoading(false);
      },
      (err) => {
        console.log(err);
        setIsLoading(false);
      },
    );
  };

  const onResendNotifications = () => {
    const storeNumbers = selectedRows.map((record) => record.store_number);
    setIsLoading(true);
    MessageingStatusService.resendNotifications(storeNumbers).then(
      () => {
        setSelectedRows([]);
        openNotiSuccess("ส่งข้อความสำเร็จ");
        setIsLoading(false);
        getNotificationsData();
        setIsConfirmSendMessageModalOpen(false);
      },
      (err) => {
        console.log(err);
        setSelectedRows([]);
        setMessageError("เกิดข้อผิดพลาดในการส่งข้อความ");
        setIsLoading(false);
      },
    );
  };

  const openNotiSuccess = (message: string) => {
    NotificationComponent({
      notification: notificationComponent,
      type: "success",
      message: message,
    });
  };

  return (
    <DesignSystemTheme>
      <Content className="messaging-status-content">
        <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
          <h1>Messaging Status</h1>
          <TooltipComponent title="ตรวสอบสถานะการส่งข้อความแจ้งเตือนการจัดส่งทีละรายการ" />
        </div>
        <Card>
          <Row gutter={[12, 12]}>
            <Col span={24} style={{ display: "flex", alignItems: "baseline" }}>
              <h3>รายการสถานะการส่งข้อความ</h3>
              <div className="data-date-text">
                ข้อมูลจัดส่งของวันที่ : {dayjs(new Date()).format("DD-MM-YYYY")}
              </div>
            </Col>
            <Col span={24}>
              <MessagingStatusFormSearch
                dataSource={dataSource}
                isLoading={isLoading}
                onSearch={(records) => {
                  setSelectedRows([]);
                  setIsLoading(true);
                  setTimeout(() => {
                    setDataDisplay(records);
                    setIsLoading(false);
                  }, 500);
                }}
                onReset={() => {
                  setIsLoading(true);
                  setTimeout(() => {
                    setDataDisplay(dataSource);
                    setIsLoading(false);
                  }, 500);
                }}
              />
            </Col>
            {userRole && !userRole.includes("planner") && (
              <>
                <Col span={24}>
                  <ButtonDs
                    type="primary"
                    disabled={selectedRows.length === 0}
                    onClick={() => setIsConfirmSendMessageModalOpen(true)}
                  >
                    Resend Message
                  </ButtonDs>
                </Col>
                <Col span={24}>
                  <div className="data-total-text">
                    เลือกทั้งหมด {selectedRows.length} จาก {dataDisplay.length} รายการ
                  </div>
                </Col>
              </>
            )}
            <Col span={24}>
              <MessagingStatusDataTable
                dataSource={dataDisplay}
                isLoading={isLoading}
                onClickRow={(records) => {
                  setSelectedRows(records);
                }}
              />
            </Col>
          </Row>
        </Card>
      </Content>
      <ConfirmModal
        open={isConfirmSendMessageModalOpen}
        closeIcon={true}
        title={
          <Space>
            <QuestionCircleOutlined /> ยืนยันการส่งข้อความหรือไม่ ?
          </Space>
        }
        onClickConfirmCancel={() => {
          setMessageError(null);
          setIsConfirmSendMessageModalOpen(false);
        }}
        onClickConfirmOK={onResendNotifications}
        loading={isLoading}
      >
        <p>
          ต้องการส่งข้อความแจ้งเตือนการจัดส่ง อีกครั้งหรือไม่ <br />
          กรุณายืนยัน
        </p>
        {messageError && (
          <div style={{ margin: "12px" }}>
            <Alert
              message={<h3>{messageError}</h3>}
              description={
                <div>
                  ไม่สามารถส่งข้อความได้
                  <br />
                  กรุณาลองใหม่อีกครั้ง
                </div>
              }
              type="error"
              showIcon
            />
          </div>
        )}
      </ConfirmModal>
      {contextHolderNoti}
    </DesignSystemTheme>
  );
};

export default MessagingStatus;
