import "../style.scss";
import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { HistoryOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { FormatDate } from "../../../utils/GetDateNow";
import React from "react";
import { ReportIssueRequest } from "../Model/ReportIssueRequest";
import { epodCancelIssue, epodReportIssue } from "../Slice";
import { Issue } from "../Model/ReportResponse";
import { useSearchParams } from "react-router-dom";

interface ReportIssueProps {
  defaultValue: Issue[] | null;
  issue: Issue;
  taskId: string;
}

const ReportIssue = ({ defaultValue, issue, taskId }: ReportIssueProps) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [topic, setTopic] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState<boolean>(false);
  const [reportHistory, setReportHistory] = useState<Issue[]>([]);
  const [isShowButtonEdit, setIsShowButtonEdit] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [queryParams] = useSearchParams();

  useEffect(() => {
    if (defaultValue !== null) {
      setReportHistory(defaultValue);
    } else {
      setReportHistory([]);
      setIsShowButtonEdit(true);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (reportHistory !== undefined) {
      setIsShowButtonEdit(true);
      setDefaultValue();
    } else {
      setTopic(undefined);
      setIsShowButtonEdit(false);
    }
  }, [reportHistory]);

  const setDefaultValue = () => {
    if (issue) {
      form.setFieldsValue({
        topic: issue.topic,
        reason: issue.reason,
      });
      setTopic(issue.topic);
    } else {
      form.resetFields();
      setTopic(undefined);
      setIsShowButtonEdit(false);
    }
  };

  const onFinish = (values: any) => {
    const data: ReportIssueRequest = {
      issue: {
        topic: values.topic,
        reason: values.reason,
      },
    };
    setIsLoading(true);
    if (reportHistory.length === 0 || values["topic"] !== reportHistory[0].topic) {
      epodReportIssue(taskId, data)
        .then(() => {
          setIsLoading(false);
          setIsShowButtonEdit(true);
          window.location.reload();
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setIsShowButtonEdit(true);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const clearForm = () => {
    if (reportHistory.length > 0) {
      setIsShowButtonEdit(true);
    } else {
      form.resetFields();
    }

    setDefaultValue();
  };

  const renderButton = () => {
    return (
      <>
        {reportHistory.length > 0 && !isShowButtonEdit && !issue && (
          <Button className="mr-8" onClick={() => setIsModalOpen(true)}>
            <HistoryOutlined />
            ประวัติ
          </Button>
        )}
        {reportHistory.length > 0 && !isShowButtonEdit && issue && (
          <Button className="mr-8" onClick={clearForm}>
            ยกเลิก
          </Button>
        )}
        {!isShowButtonEdit && (
          <Button type="primary" htmlType="submit" disabled={isViewMode()} loading={isLoading}>
            รายงานปัญหา
          </Button>
        )}
      </>
    );
  };

  const handleOk = () => {
    setIsLoadingCancel(true);
    epodCancelIssue(taskId)
      .then(() => {
        handleCancel();
        window.location.reload();
      })
      .catch(() => {
        handleCancel();
        messageApi.error("ยกเลิกรายงานปัญหาไม่สำเร็จ");
      });
  };

  const handleCancel = () => {
    setIsLoadingCancel(false);
    setIsConfirmModalOpen(false);
  };

  const isViewMode = () => {
    return queryParams.get("viewmode") === "true";
  };

  const renderEditButton = () => {
    return (
      <>
        {reportHistory.length > 0 && isShowButtonEdit && (
          <div style={{ display: "flex", marginTop: "16px" }}>
            <div style={{ marginRight: "auto" }}>
              <Button
                className="mr-8"
                danger
                type="primary"
                disabled={isViewMode()}
                onClick={() => setIsConfirmModalOpen(true)}
              >
                ยกเลิกการรายงาน
              </Button>
            </div>
            <Modal
              title={<p className="modal-title">ยืนยันการยกเลิกรายงานปัญหา</p>}
              open={isConfirmModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              okText="ยืนยัน"
              cancelText="ยกเลิก"
              okButtonProps={{ loading: isLoadingCancel }}
            >
              <p className="modal-body" style={{ marginBottom: "0px" }}>
                กรุณายืนยันการยกเลิกรายงานปัญหา
              </p>
              <p className="modal-body">ข้อมูลจะถูกจัดเก็บไว้ในประวัติการทำรายการ</p>
            </Modal>
            <Button className="mr-8" onClick={() => setIsModalOpen(true)}>
              <HistoryOutlined />
              ประวัติ
            </Button>
            <Button
              className="mr-8"
              type="primary"
              disabled={isViewMode()}
              onClick={() => setIsShowButtonEdit(false)}
            >
              แก้ไข
            </Button>
          </div>
        )}
      </>
    );
  };

  const renderReportHistory = (history: Issue) => {
    return (
      <div className="report-issue-modal-body">
        <div className="buttom-line">
          <div style={{ fontSize: "16px", padding: "8px 0" }}>
            Update time : {FormatDate(history.timestamp, "DD-MM-YYYY HH:mm")}
          </div>
          <div className="flex-8">
            <div>การตรวจสอบรายงาน :</div>
            <div>{history.topic}</div>
          </div>
          {history.topic === "อื่นๆ" && (
            <div className="flex-8">
              <div>เหตุผล :</div>
              <div>{history.reason}</div>
            </div>
          )}
          <div className="flex-8">
            <div>รายงานโดย :</div>
            <div>{history.reporter}</div>
          </div>
        </div>
      </div>
    );
  };

  const selectTopicList = [
    {
      label: "รูปภาพซ้ำ",
    },
    {
      label: "รูปภาพเอกสารไม่ถูกต้อง",
    },
    {
      label: "รูปภาพสาขาไม่ถูกต้อง",
    },
    {
      label: "รูปภาพสินค้าไม่ถูกต้อง",
    },
    {
      label: "อื่นๆ",
    },
  ];

  return (
    <>
      <div className="report-issue-box">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              labelAlign="left"
            >
              <Form.Item
                label={<span className="text-label-issue">การตรวจสอบรายงาน</span>}
                name="topic"
                rules={[{ required: true, message: "กรุณาเลือกการตรวจสอบรายงาน" }]}
              >
                {isViewMode() && issue?.topic ? (
                  issue.topic
                ) : (
                  <Select
                    placeholder="กรุณาเลือก"
                    onChange={(e) => setTopic(e)}
                    value={topic}
                    disabled={(reportHistory.length > 0 && isShowButtonEdit) || isViewMode()}
                  >
                    {selectTopicList.map((x, dataIndex) => {
                      return x.label !== topic ? (
                        <Select.Option key={dataIndex} value={x.label}>
                          {x.label}
                        </Select.Option>
                      ) : (
                        <></>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
              {topic === "อื่นๆ" && (
                <Form.Item
                  label={<span className="text-label-issue">เหตุผล</span>}
                  name="reason"
                  rules={[{ required: true, message: "กรุณากรอกเหตุผล" }]}
                >
                  {isViewMode() && issue?.reason ? (
                    issue.reason
                  ) : (
                    <Input disabled={reportHistory.length > 0 && isShowButtonEdit} />
                  )}
                </Form.Item>
              )}
              <div className="space-between">
                <div style={{ width: "50%", height: "40px", display: "flex", alignItems: "flex-end" }}>
                  <div className="text-issue">รายงานปัญหาล่าสุด : &nbsp;</div>
                  <div className="text-issue">
                    {defaultValue && defaultValue.length > 0
                      ? FormatDate(defaultValue[0].timestamp, "DD-MM-YYYY HH:mm")
                      : "-"}
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  {renderButton()}
                </div>
              </div>
              <div>{renderEditButton()}</div>
            </Form>
          </Col>
        </Row>
        <Modal
          title={<div className="report-issue-modal-title">ประวัติการรายงานปัญหา</div>}
          centered
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={null}
          width={580}
        >
          {reportHistory.length > 0 &&
            reportHistory.map((item, index) => (
              <React.Fragment key={index}>{renderReportHistory(item)}</React.Fragment>
            ))}
        </Modal>
      </div>
      {contextHolderMessage}
    </>
  );
};

export default ReportIssue;
