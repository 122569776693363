import { Button, Form, Modal, Radio, Select } from "antd";
import { DOStoreListUpdate } from "../Driver";
import TextArea from "antd/es/input/TextArea";
import { Status } from "../GpsTracking";

type ModalFormProps = {
  updateStoreDetail: DOStoreListUpdate;
  open: boolean;
  onCancel: () => void;
  onSubmit: (values: any) => void;
  onChangeReason: (value: any) => void;
  onChangeStatus: (value: Status) => void;
  onSubmitConfirm: () => void;
  statusValue: Status | null;
  reasonValue: string;
  otherValue: string;

  openConfirm: boolean;
  loadingUpdateStatus: boolean;
};

const ModalChangeStatus: React.FC<ModalFormProps> = ({
  updateStoreDetail,
  open,
  onCancel,
  onSubmit,
  onChangeReason,
  onChangeStatus,
  onSubmitConfirm,
  statusValue,
  reasonValue,
  otherValue,

  openConfirm,
  loadingUpdateStatus,
}) => {
  const arrStatus: Status[] = [Status.SUCCESS, Status.IN_TRANSIT, Status.WAITING];
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then((value) => {
      onSubmit(value);
      form.resetFields();
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <>
      <Modal forceRender title="อัปเดตสถานะ" open={open} onOk={handleOk} onCancel={handleCancel}>
        <div className="update-status-detail">
          <p>รายละเอียด</p>
          <p>
            Stop: <span>{updateStoreDetail.stop}</span>
          </p>
          <p>
            ชื่อสโตร์: <span>{updateStoreDetail.storeName}</span>
          </p>
          <p>
            หมายเลขสโตร์: <span>{updateStoreDetail.storeNumber}</span>
          </p>
          <p>
            สถานะเดิม: <span>{updateStoreDetail.currentStatus}</span>
          </p>
        </div>
        <Form
          form={form}
          name="change-status"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            label="เปลี่ยนสถานะ"
            name="status"
            rules={[
              {
                required: true,
                message: "กรุณาเลือกสถานะ",
              },
            ]}
          >
            <Select placeholder="เลือกสถานะ" onChange={onChangeStatus}>
              {arrStatus
                .filter((s) => s.toUpperCase() !== updateStoreDetail.currentStatus.toUpperCase())
                .map((y, i) => (
                  <Select.Option value={y} key={i}>
                    <span>{y}</span>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {statusValue !== null && (
            <Form.Item
              label="ระบุสาเหตุการเปลี่ยนสถานะ"
              name="reason"
              className="form-update-status-wrap-reason"
              rules={[
                {
                  required: true,
                  message: "กรุณาระบุสาเหตุการเปลี่ยนสถานะ",
                },
              ]}
            >
              {statusValue === Status.SUCCESS ? (
                <Radio.Group onChange={onChangeReason}>
                  <Radio value={"not_close_job"}>พขร. ไม่ได้กดปิดงาน</Radio>
                  <Radio value={"internet_issue"}>พขร. ติดปัญหาการใช้งานอินเตอร์เน็ต</Radio>
                  <Radio value={"application_issue"}>พขร. ติดปัญหาการใช้งานแอปพลิเคชั่น</Radio>
                  <Radio value={"other"}>
                    {"อื่นๆ (กรุณาระบุเหตุผล)"}
                    {reasonValue === "other" ? (
                      <Form.Item
                        name="note"
                        rules={[
                          {
                            required: reasonValue === "other" ? true : false,
                            message: "กรุณาระบบุเหตุผล",
                          },
                        ]}
                      >
                        <TextArea rows={4} maxLength={256} showCount />
                      </Form.Item>
                    ) : null}
                  </Radio>
                </Radio.Group>
              ) : statusValue === Status.IN_TRANSIT || statusValue === Status.WAITING ? (
                <Radio.Group onChange={onChangeReason}>
                  <Radio value={"close_wrong_job"}>พขร. กดปิดงานผิดร้าน</Radio>
                  <Radio value={"application_issue"}>พขร. ติดปัญหาการใช้งานแอป</Radio>
                  <Radio value={"officer_close_job"}>เจ้าหน้าที่ กดปิดงานผิดร้าน</Radio>
                  <Radio value={"other"}>
                    {"อื่นๆ (กรุณาระบุเหตุผล)"}
                    {reasonValue === "other" ? (
                      <Form.Item
                        name="note"
                        rules={[
                          {
                            required: reasonValue === "other" ? true : false,
                            message: "กรุณาระบุเหตุผล",
                          },
                        ]}
                      >
                        <TextArea rows={4} maxLength={256} showCount />
                      </Form.Item>
                    ) : null}
                  </Radio>
                </Radio.Group>
              ) : null}
            </Form.Item>
          )}
        </Form>
      </Modal>
      <Modal
        forceRender
        className="modal-confirm-last-step"
        open={openConfirm}
        onCancel={onCancel}
        title={
          <p className="modal-title-last">
            ยืนยันการเปลี่ยนสถานะจาก <span>{updateStoreDetail.currentStatus}</span> เป็น{" "}
            <span> {statusValue}</span>
          </p>
        }
        footer={[
          <Button key="cencel" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loadingUpdateStatus} onClick={onSubmitConfirm}>
            OK
          </Button>,
        ]}
      >
        <div className="confirm-message-update-status">
          <p>
            Stop: <span>{updateStoreDetail.stop}</span>
          </p>
          <p>
            ชื่อสโตร์: <span>{updateStoreDetail.storeName}</span>
          </p>
          <p>
            หมายเลขสโตร์: <span>{updateStoreDetail.storeNumber}</span>
          </p>
          <p>
            สาเหตุการเปลี่ยนสถานะ:{" "}
            <span>
              {reasonValue === "not_close_job"
                ? "พขร. ไม่ได้กดปิดงาน"
                : reasonValue === "internet_issue"
                ? "พขร. ติดปัญหาการใช้งานอินเตอร์เน็ต"
                : reasonValue === "application_issue"
                ? "พขร. ติดปัญหาการใช้งานแอปพลิเคชั่น"
                : reasonValue === "close_wrong_job"
                ? "พขร. กดปิดงานผิดร้าน"
                : reasonValue === "officer_close_job"
                ? "เจ้าหน้าที่ กดปิดงานผิดร้าน"
                : `(อื่นๆ) ${otherValue}`}
            </span>
          </p>
        </div>
      </Modal>
    </>
  );
};

export default ModalChangeStatus;
