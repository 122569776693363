import { Tooltip } from "antd";
import DesignSystemTheme from "./DesignSystemTheme";
import { InfoCircleOutlined } from "@ant-design/icons";

interface Props {
  children?: React.ReactNode;
  title: string | React.ReactNode;
  placement?: "top" | "left" | "right" | "bottom" | "topLeft" | "topRight" | "bottomLeft" | "bottomRight";
}
const TooltipComponent = ({ children, title, placement }: Props) => {
  return (
    <DesignSystemTheme>
      <Tooltip
        placement={placement ?? "right"}
        trigger={["hover"]}
        title={title}
        color="#ffffff"
        overlayInnerStyle={{ color: "#000", padding: "8px 12px", width: "max-content" }}
      >
        {children ? children : <InfoCircleOutlined style={{ marginLeft: "8px", color: "#757575" }} />}
      </Tooltip>
    </DesignSystemTheme>
  );
};

export default TooltipComponent;
