import "./style.scss";

import { Button, Modal } from "antd";

import { CheckCircleTwoTone } from "@ant-design/icons";

type ModalEvent = {
  open: boolean;
  onOk: () => void;
};

export default function ModalComplete({ open, onOk }: ModalEvent) {
  const customFooter = (
    <div className="wrap-modal-footer">
      <Button type="primary" onClick={onOk} data-testid="forgot-password-complete-btn">
        OK
      </Button>
    </div>
  );

  return (
    <Modal
      className="modal-forgot-password-complele"
      centered
      closable={false}
      open={open}
      onOk={onOk}
      footer={customFooter}
      width={500}
    >
      <div className="wrap-modal-content">
        <CheckCircleTwoTone twoToneColor="#52c41a" rev="" />
        <p>ระบบได้ทำการส่งอีเมลสำหรับการ Reset Password เรียบร้อยแล้ว</p>
      </div>
    </Modal>
  );
}
