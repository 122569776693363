export interface Eta {
  dc_code: string;
  uploaded_date: string;
  filename: string;
  uploaded_by: string;
  uploaded_time: string;
  total: number;
  completed_count: number;
  file_path: string;
  sent_notification_by: string;
  sent_notification_time: string;
  record_uploaded: number;
}

export interface Cell {
  code: string;
  col: number;
  data: string;
  row: number;
}

export interface ErrorHeader {
  code: string;
  message: string;
}

export interface Sync {
  dc_code: string;
  last_sync: string;
  total_task: number;
  sent_by: string;
  sent_time: string;
}

export enum Mode {
  SYNC,
  UPLOAD,
}
