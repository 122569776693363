import { Space, Table, TableProps, Tag } from "antd";
import { User } from "../models/user";
import { useEffect, useState } from "react";
import { ButtonDs } from "design-system";
import { useNavigate } from "react-router-dom";
import { FormatDate } from "../../../utils/GetDateNow";
import LocalstorageService from "../../../shared/service/Localstorage.service";
import TooltipComponent from "../../../shared/component/TooltipComponent";

interface Props {
  dataSource: User[] | undefined;
  isLoading: boolean;
}

const DataTable = ({ dataSource, isLoading }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const userRole = LocalstorageService.getCurrentRole();

  useEffect(() => {
    setCurrentPage(1);
  }, [isLoading]);

  const capitalize = (input: string) => {
    return `${input.charAt(0).toUpperCase()}${input.slice(1)}`;
  };

  const columns: TableProps<User>["columns"] = [
    {
      title: "No",
      key: "no",
      render: (_, __, index) => <>{(currentPage - 1) * 15 + (index + 1)}</>,
      width: 50,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render(value, record) {
        return (
          <Space>
            <div>{`${record.firstname} ${record.lastname}`}</div>
            {record.is_new_user && (
              <Tag color="blue" style={{ fontSize: 12, height: 22 }}>
                New
              </Tag>
            )}
            {record.is_update_user && (
              <Tag color="default" style={{ fontSize: 12, height: 22, color: "blue" }}>
                Update
              </Tag>
            )}
          </Space>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (_, record) => {
        return <>{capitalize(record.role.replace("_", " "))}</>;
      },
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "status",
      render: (_, record) => {
        const colors: { [key: string]: string } = {
          active: "green",
          inactive: "red",
        };
        return (
          <>
            {colors[record.status] && (
              <Tag bordered={false} color={colors[record.status]}>
                {capitalize(record.status)}
              </Tag>
            )}
            {!colors[record.status] && (
              <TooltipComponent title={`ผู้ใช้งานยังไม่ได้ตั้งรหัสผ่าน`} placement="top">
                <Tag bordered={false} color="default">
                  {capitalize(record.status)}
                </Tag>
              </TooltipComponent>
            )}
          </>
        );
      },
    },
    {
      title: "Last Login",
      dataIndex: "last_login_at",
      key: "last_login_at",
      render: (_, record) => {
        if (record.last_login_at) {
          return FormatDate(record.last_login_at, "DD-MM-YYYY, HH:mm");
        }
        return "-";
      },
    },
    userRole && userRole.includes("manager")
      ? {}
      : {
          title: "Action",
          key: "action",
          fixed: "right",
          render: (_, record) => (
            <ButtonDs
              size="middle"
              type="default"
              onClick={() => {
                navigate(`/user-management/${record.uid}`);
              }}
            >
              รายละเอียด
            </ButtonDs>
          ),
          width: 150,
        },
  ];

  return (
    <Table
      scroll={{ x: 1200 }}
      loading={isLoading}
      rowKey={(row) => row.uid}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        pageSize: 15,
        current: currentPage,
        onChange(page) {
          setCurrentPage(page);
        },
      }}
    />
  );
};

export default DataTable;
