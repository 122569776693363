import { initializeApp as initializeFirebaseApp } from "firebase/app";

const firebaseConfigAppTD = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_TD,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_TD,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_TD,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_TD,
};

const firebaseAppTD = initializeFirebaseApp(firebaseConfigAppTD, "TD");

export const getApp = () => {
  return firebaseAppTD;
};
