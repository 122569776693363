import { Card, Col, Input, notification, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import "./style.scss";
import { CopyOutlined } from "@ant-design/icons";
import { NotificationComponent } from "../../../shared/component/NotificationComponent";
import ApiClient from "../../../shared/api/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ButtonDs } from "design-system";
import DesignSystemTheme from "../../../shared/component/DesignSystemTheme";

const ExternalAPIKey = () => {
  const [notificationComponent, contextHolderNoti] = notification.useNotification();

  const { data, error } = useQuery({
    queryKey: ["getExternalAccessToken"],
    queryFn: () => getAccessToken(),
    retry: 1,
    refetchOnWindowFocus: false,
  });

  const diaplayNotification = (type: "success" | "error", message: string, topic?: string) => {
    return NotificationComponent({
      notification: notificationComponent,
      type,
      topic: topic,
      message: message,
    });
  };

  const getAccessToken = async () => {
    const response = await ApiClient.get("/external/api-key");
    // const key = "asdasdasdlkjkwjdlajljdlkjalwdkjlkaklj";

    return response.data.key;
  };

  if (error) {
    diaplayNotification("error", `${error.message}`, "เกิดข้อผิดพลาด");
  }

  const copyToClipBoard = async (responseText: string) => {
    await navigator.clipboard
      .writeText(responseText)
      .then(() => {
        diaplayNotification("success", `คัดลอกสำเร็จ`);
      })
      .catch(() => {
        diaplayNotification("error", `คัดลอกไม่สำเร็จ`);
      });
  };

  return (
    <DesignSystemTheme>
      <Content className="external-access-token-content">
        <Card className="card">
          <h3>API Key</h3>
          <p className="description-text" style={{ margin: "10px 0" }}>
            The API secret key is used to authenticate requests. You must copy and keep it secure.
          </p>
          <Row className="api-key-container" wrap={false}>
            <Col flex="auto">
              <Input.Password
                className="input-api-key"
                readOnly={true}
                placeholder="Your API Key"
                variant="filled"
                value={data}
              />
            </Col>
            <Col flex="none">
              <ButtonDs
                data-testid="copy-button"
                type="default"
                size="large"
                onClick={() => copyToClipBoard(data)}
              >
                <CopyOutlined />
              </ButtonDs>
              {/* <ButtonDs type="primary" size="large">
                <UndoOutlined />
                Roll Key
              </ButtonDs> */}
            </Col>
          </Row>
          {/* <p className="text-caption" style={{ margin: "10px 0 0 0" }}>
            Generated at {}
          </p> */}
          {/* <p className="text-caption" style={{ margin: "30px 0 0 0" }}>
            ** Rolling key will generate a new API key and the previous key will be expired in 60 minutes.
          </p> */}
        </Card>
      </Content>
      {contextHolderNoti}
    </DesignSystemTheme>
  );
};

export default ExternalAPIKey;
