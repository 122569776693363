import "./style.scss";
import { Content } from "antd/es/layout/layout";
import TooltipComponent from "../../shared/component/TooltipComponent";
import { Card, Col, Row } from "antd";
import DataTable from "./components/DropMonitoringTable";
import { useEffect, useState } from "react";
import { TaskMonitoring } from "./models/task";
import DropMonitoringService from "./services/drop-monitior-service";

const DropMonitoring = () => {
  const [dataSource, setDataSource] = useState<TaskMonitoring[]>([]);
  const [pageSize, setPageSize] = useState<number>(15);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [isToggleMapActive, setIsToggleMapActive] = useState<boolean>(false);

  // const toggles = LocalstorageService.getConfigToggleDropMonitoring();

  useEffect(() => {
    // if (toggles) {
    //   setIsToggleMapActive(toggles.isShowMap);
    // }
    setIsLoading(true);
    DropMonitoringService.getTask()
      .then((data) => {
        setDataSource(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Content className="drop-monitoring-content">
        <div className="drop-monitoring-header">
          <h1>Drop Monitoring</h1>
          <TooltipComponent title="Drop Monitoring" />
        </div>
        {/* <Card className="drop-monitoring-map" styles={{ body: { padding: 0 } }}>
          <CollapseComponent
            activeKey={isToggleMapActive ? ["map"] : []}
            defaultActiveKey={toggles.isShowMap ? ["map"] : []}
            items={[
              {
                key: "map",
                label: (
                  <span className="drop-monitoring-map-label">
                    <h3>แผนที่</h3>
                    <p>(วันที่อัพเดทล่าสุด: {GetDateNowBeforeFetchNew()})</p>
                  </span>
                ),
                children: <DropMonitoringMap isLoading={isLoading} />,
              },
            ]}
            onChange={(key) => {
              const isToggleActive = key.length > 0;
              setIsToggleMapActive(isToggleActive);

              LocalstorageService.setConfigToggleDropMonitoring({
                isShowMap: isToggleActive,
              });
            }}
          />
        </Card> */}
        <Card>
          <Row>
            <Col className="table-title-action" span={24}>
              <p>รายการทั้งหมด {dataSource.length} รายการ</p>
            </Col>
            <Col span={24}>
              <DataTable
                dataSource={dataSource}
                isLoading={isLoading}
                onChangePage={(page: number, pageSize: number) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                }}
                currentPage={currentPage}
                pageSize={pageSize}
              />
            </Col>
          </Row>
        </Card>
      </Content>
    </>
  );
};

export default DropMonitoring;
