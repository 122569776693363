import { ENABELED_ONE_FRONT, USER_PERMISSIONS } from "../../shared/api/ApiEndPoint";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiClient from "../../shared/api/ApiClient";
import { LoginData } from "./LoginInterface";
import { RootState } from "../../shared/store/store";
import { UserAuthInfo } from "../../shared/type/UserAuthInfo";
import { getAuth } from "../../shared/api/FirebaseAuth";
import { message } from "antd";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Company } from "../../shared/type/Company";

type LoginState = {
  loading: boolean;
  isLoginSession: boolean;
  currentUser?: LoginUser;
  currentCompany: Company | undefined | null;
  isOpenOneFront: boolean;
};

type LoginUser = {
  email: string | null | undefined;
  role: string;
  userAuthInfo?: UserAuthInfo;
};

const initialValues: LoginState = {
  loading: false,
  isLoginSession: false,
  currentCompany: undefined,
  isOpenOneFront: false,
};

export const getEnabledOneFront = (): Promise<{ one_front_on: boolean }> => {
  return ApiClient.get<{ one_front_on: boolean }>(ENABELED_ONE_FRONT).then(({ data }) => data);
};

export const getUserAuthInfo = (uid: string): Promise<UserAuthInfo> => {
  return ApiClient.get<UserAuthInfo>(USER_PERMISSIONS(uid)).then(({ data }) => data);
};

export const fetchLogin = createAsyncThunk(
  "request/login",
  async (dataLogin: LoginData, { rejectWithValue }) => {
    try {
      const userCredential = await signInWithEmailAndPassword(getAuth(), dataLogin.email, dataLogin.password);
      const user = userCredential.user;
      const tokenResult = await user.getIdTokenResult(true);
      const roles: any = tokenResult.claims.role || "";
      const loginUser: LoginUser = {
        email: user.email,
        role: (roles[0] as string) || "",
      };

      if (loginUser.email && loginUser.role) {
        message.success("Login completed");
      }
      return loginUser;
    } catch (err) {
      if (err instanceof Error) {
        message.error(err.message);
        return rejectWithValue(err.message);
      }
    }
  },
);

const login = createSlice({
  name: "login",
  initialState: initialValues,
  reducers: {
    loggedIn: (state: LoginState) => {
      state.isLoginSession = true;
    },
    updateCurrentUser: (state: LoginState, action: PayloadAction<LoginUser | undefined>) => {
      state.currentUser = action.payload;
      if (state.currentUser === undefined) {
        state.isLoginSession = false;
      } else {
        state.isLoginSession = true;
      }
    },
    updateIsOpenOneFront: (state: LoginState, action: PayloadAction<boolean>) => {
      state.isOpenOneFront = action.payload;
    },
    updateCurrentCompany: (state: LoginState, action: PayloadAction<Company | undefined | null>) => {
      state.currentCompany = action.payload;
    },
    logout: (state: LoginState) => {
      state.isLoginSession = false;
      state.currentUser = undefined;
      state.currentCompany = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
      state.isLoginSession = true;
    });

    builder.addCase(fetchLogin.rejected, (state) => {
      state.loading = false;
      state.isLoginSession = false;
      state.currentUser = undefined;
    });

    builder.addCase(fetchLogin.pending, (state) => {
      state.loading = true;
      state.currentUser = undefined;
      state.isLoginSession = false;
    });
  },
});

export const { loggedIn, updateCurrentUser, logout, updateCurrentCompany, updateIsOpenOneFront } =
  login.actions;
export type { LoginUser };
export const isLoginSession = (store: RootState) => store.login.isLoginSession;
export const currentUserState = (store: RootState) => store.login.currentUser;
export const currentUserRole = (store: RootState) => store.login.currentUser?.role;
export const isLoadingLoginProcess = (store: RootState) => store.login.loading;
export const userAuthInfo = (store: RootState) => store.login.currentUser?.userAuthInfo;
export const currentCompany = (store: RootState) => store.login.currentCompany;
export const isOpenOneFront = (store: RootState) => {
  return store.login.isOpenOneFront;
};
export default login.reducer;
