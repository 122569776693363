import { Table, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { NotificationInfo } from "../models/messaging-status.model";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import LocalstorageService from "../../../shared/service/Localstorage.service";

interface DataTableProps {
  dataSource: NotificationInfo[];
  isLoading: boolean;
  onClickRow: (records: NotificationInfo[]) => void;
}

const MessagingStatusDataTable = ({ dataSource, isLoading, onClickRow }: DataTableProps) => {
  const userRole = LocalstorageService.getCurrentRole();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const pageSize = useRef(15);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!isLoading) {
      setSelectedRowKeys([]);
    } else {
      setCurrentPage(1);
    }
  }, [isLoading]);

  const columns: ColumnsType<NotificationInfo> = [
    {
      title: "รหัสร้านค้า",
      dataIndex: "store_number",
      key: "store_number",
      fixed: "left",
    },
    {
      title: "ชื่อร้านค้า",
      dataIndex: "store_name",
      key: "store_name",
      fixed: "left",
    },
    {
      title: "ศูนย์กระจายสินค้า",
      dataIndex: "dc_code",
      key: "dc_code",
    },
    {
      title: "จังหวัด",
      dataIndex: "province",
      key: "province",
    },
    {
      title: "เบอร์โทร",
      dataIndex: "mobile_phone",
      key: "mobile_phone",
    },
    {
      title: "SLA",
      dataIndex: "sla",
      key: "sla",
    },
    {
      title: "ประทับเวลาส่งข้อความ",
      dataIndex: "notification_at",
      key: "notification_at",
      render: (text: string) => {
        return <>{text && text.length > 0 ? dayjs(text).format("DD-MM-YYYY, HH:mm:ss") : "-"}</>;
      },
    },
    {
      title: "สถานะการส่งข้อความ",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <>
          {record.status.length === 0 ? (
            "-"
          ) : (
            <>
              {record.status.toLowerCase() === "success" ? (
                <Tag color="success">Success</Tag>
              ) : (
                <Tag color="error">Fail</Tag>
              )}
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <Table
      className="table-custom-design-system"
      loading={isLoading}
      rowSelection={
        userRole && !userRole.includes("planner")
          ? {
              selectedRowKeys: selectedRowKeys,
              type: "checkbox",
              onChange: (_: React.Key[], selectedRows: NotificationInfo[]) => {
                setSelectedRowKeys(selectedRows.map((item) => item.store_number));
                onClickRow(selectedRows);
              },
              getCheckboxProps: (record: NotificationInfo) => ({
                disabled: record.status.toLowerCase() === "success" || record.status.length === 0,
              }),
            }
          : undefined
      }
      columns={columns}
      dataSource={dataSource}
      pagination={{
        defaultCurrent: 1,
        defaultPageSize: pageSize.current,
        total: dataSource.length,
        current: currentPage,
        onChange(page) {
          setCurrentPage(page);
        },
      }}
      rowKey={"store_number"}
      scroll={{ x: 1200 }}
    />
  );
};

export default MessagingStatusDataTable;
