import { Auth, connectAuthEmulator, getAuth as getFirebaseAuth } from "firebase/auth";

import { getApp } from "./FirebaseApp";

const mockFirebaseAuth = {
  onAuthStateChanged: function () {
    // do nothing.
  },
};

type Config = {
  testMode: boolean;
  auth: Auth;
};

const config: Config = {
  testMode: false,
  auth: mockFirebaseAuth as unknown as Auth,
};

export const getNewIdToken = async () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      if (user) {
        resolve(user.getIdToken(true));
      } else {
        reject("No User Found");
      }
      unsubscribe();
    });
  });
};

export const currentToken = async (): Promise<string | null> => {
  if (config.testMode === true) {
    return null;
  }

  const auth = getAuth();

  if (auth.currentUser == null) {
    const newToken = await getNewIdToken();
    return newToken as string;
  }

  const token = await auth.currentUser.getIdToken(true);

  return token;
};

export const enableTestMode = () => {
  config.testMode = true;
};

export const disableTestMode = () => {
  config.testMode = false;
};

async function setupEmulators(auth: any) {
  const authUrl = "http://localhost:9099";
  await fetch(authUrl);
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
}

export const getAuth = (): Auth => {
  if (config.testMode === true) {
    return mockFirebaseAuth as unknown as Auth;
  }

  const auth = getFirebaseAuth(getApp());
  if (process.env.REACT_APP_EMU === "true") {
    setupEmulators(auth);
  }

  return auth as Auth;
};
