import { useNavigate, useParams } from "react-router-dom";
import DesignSystemTheme from "../../shared/component/DesignSystemTheme";
import { Breadcrumb, Card, Col, Layout, Row, Space, Switch } from "antd";
import { DeleteOutlined, FileDoneOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { ButtonDs, Form, FormDs, InputDs, SelectDs } from "design-system";
import { useEffect, useState } from "react";
import UserManagementService from "./services/user-management-service";
import ModalPermissionDetail from "./components/ModalPermissionDetail";
import { UserInfo } from "./models/user";
import ConfirmModal from "./components/ConfirmModal";
import AlertMessage from "../../shared/component/AlertMessage";
import LocalstorageService from "../../shared/service/Localstorage.service";

const { Content } = Layout;

const UpdateUser = () => {
  const { user_id } = useParams();
  const [form] = Form.useForm();
  const [isModalPermissionDetailOpen, setIsModalPermissionDetailOpen] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isUserInfoLoading, setIsUserInfoLoading] = useState<boolean>(false);
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const [isUpdateUserLoading, setIsUpdateUserLoading] = useState<boolean>(false);
  const [isDeleteUserLoading, setIsDeleteUserLoading] = useState<boolean>(false);
  const [alertMessageOpen, setAlertMessageOpen] = useState<boolean>(false);
  const [alertDeleteErr, setAlertDeleteErr] = useState<boolean>(false);
  const navigate = useNavigate();
  const currentCompany = LocalstorageService.getCompany();

  useEffect(() => {
    if (user_id) {
      setIsUserInfoLoading(true);
      UserManagementService.getUserInfo(user_id).then(
        (res) => {
          setUserInfo(res);
          setIsActive(res.active);
          setIsUserInfoLoading(false);
        },
        (err) => {
          console.log("err", err);
          setIsUserInfoLoading(false);
        },
      );
    }
  }, []);

  const onFinish = (values: any) => {
    console.log("Success:", values);
    setIsConfirmModalOpen(true);
  };

  const onUpdateUser = () => {
    const firstname = form.getFieldValue("firstname").trim();
    const lastname = form.getFieldValue("lastname").trim();
    const role = form.getFieldValue("role");

    const dataUpdate: UserInfo = {
      firstname: firstname,
      lastname: lastname,
      email: userInfo?.email as string,
      active: isActive,
      role: role,
    };

    setIsUpdateUserLoading(true);
    UserManagementService.updateUser(user_id as string, dataUpdate).then(
      () => {
        UserManagementService.userUpdated.next(dataUpdate);
        setIsUpdateUserLoading(false);
        setIsConfirmModalOpen(false);
        navigate("/user-management");
      },
      (err) => {
        console.log("err", err);
        UserManagementService.userUpdated.next(null);
        setIsUpdateUserLoading(false);
        setAlertMessageOpen(true);
        setIsConfirmModalOpen(false);
      },
    );
  };

  const onDeleteUser = () => {
    setIsDeleteUserLoading(true);
    UserManagementService.deleteUser(user_id as string).then(
      () => {
        UserManagementService.userDeleted.next(userInfo);
        setIsDeleteUserLoading(false);
        setIsConfirmDeleteModalOpen(false);
        navigate("/user-management");
      },
      (err) => {
        console.log("err", err);
        UserManagementService.userDeleted.next(null);
        setIsDeleteUserLoading(false);
        setAlertDeleteErr(true);
        setIsConfirmDeleteModalOpen(false);
      },
    );
  };

  return (
    <DesignSystemTheme>
      <Content className="user-management-content">
        <div>
          <Breadcrumb
            separator=">"
            items={[
              {
                title: "User management",
                href: "/user-management",
              },
              {
                title: "ข้อมูลผู้ใช้งาน",
              },
            ]}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
          <h2>ข้อมูลผู้ใช้งาน</h2>
        </div>
        {isUserInfoLoading && <Card loading={isUserInfoLoading}>Loading...</Card>}
        {userInfo && !isUserInfoLoading && (
          <FormDs
            form={form}
            initialValues={{
              status: userInfo?.active,
              firstname: userInfo?.firstname,
              lastname: userInfo?.lastname,
              email: userInfo?.email,
              role: userInfo?.role,
            }}
            onFinish={onFinish}
            autoComplete="off"
            requiredMark={false}
          >
            <Card>
              <Row gutter={[16, 16]}>
                {alertMessageOpen && (
                  <Col span={24}>
                    <AlertMessage
                      type="error"
                      message="เกิดข้อผิดพลาด"
                      description={`เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง`}
                      margin="0 0 16px 0"
                    />
                  </Col>
                )}
                <Col span={24}>
                  <h3>รายละเอียด</h3>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name={"status"}
                    label={
                      <Space>
                        <label>สถานะ</label>
                      </Space>
                    }
                  >
                    <Space>
                      <Switch defaultChecked={isActive} onChange={(x) => setIsActive(x)} />{" "}
                      {isActive ? "Active" : "Inactive"}
                    </Space>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Card style={{ padding: "16px" }}>
                    <h3>ข้อมูลทั่วไป</h3>
                    <Row>
                      <Col span={24} className="col-form-email">
                        <Form.Item
                          label="Email"
                          style={{ margin: "0" }}
                          rules={[
                            {
                              type: "email",
                              required: true,
                            },
                          ]}
                        >
                          <InputDs placeholder="กรุณากรอก Email" hidden></InputDs>
                          <p>{userInfo?.email}</p>
                        </Form.Item>
                      </Col>
                      <Col span={24} style={{ display: "flex" }}>
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <Form.Item
                              name={"firstname"}
                              help="ระบุชื่อ, ไม่มีตัวเลขหรืออักขระพิเศษ"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <InputDs label="ชื่อ" placeholder="กรุณากรอกชื่อ"></InputDs>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name={"lastname"}
                              className="form-lastname"
                              help="ระบุนามสกุล, ไม่มีตัวเลขหรืออักขระพิเศษ"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <InputDs label="นามสกุล" placeholder="กรุณากรอกนามสกุล"></InputDs>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card className="card-section-user-role" style={{ padding: "16px" }}>
                    <div className="section-user-role">
                      <h3>สิทธิ์และการเข้าถึง</h3>
                      {currentCompany !== "TDM" && (
                        <a onClick={() => setIsModalPermissionDetailOpen(true)}>
                          <Space>
                            <FileDoneOutlined />
                            ดูสิทธิ์ทั้งหมด
                          </Space>
                        </a>
                      )}
                    </div>
                    <Row gutter={16}>
                      <Col span={12}>
                        <div className="section-permission">
                          <Form.Item name="role" label="สิทธิการเข้าถึง" style={{ margin: "0" }}>
                            <SelectDs
                              options={
                                currentCompany === "TDM"
                                  ? [
                                      {
                                        label: "Admin",
                                        value: "admin",
                                      },
                                    ]
                                  : UserManagementService.roles
                              }
                              style={{ width: 328 }}
                              placeholder="กรุณาเลือก"
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <div style={{ marginTop: "16px", display: "flex", justifyContent: "flex-end" }}>
                <Space>
                  <ButtonDs
                    type="default"
                    onClick={() => {
                      setIsConfirmCancelModalOpen(true);
                    }}
                  >
                    ยกเลิก
                  </ButtonDs>
                  <ButtonDs type="primary" htmlType="submit">
                    บันทึก
                  </ButtonDs>
                </Space>
              </div>
            </Card>
            <div style={{ marginTop: "8px" }}>
              <Card>
                {alertDeleteErr && (
                  <AlertMessage
                    type="error"
                    message="เกิดข้อผิดพลาด"
                    description={"เกิดข้อผิดพลาดในการลบผู้ใช้งาน กรุณาลองใหม่อีกครั้ง"}
                    margin="0 0 16px 0"
                  />
                )}
                <h3>ลบผู้ใช้งาน</h3>
                <div className="section-delete-detail">
                  หากลบผู้ใช้งาน สิทธิ์ทั้งหมดจะถูกลบและไม่สามารถกู้คืนได้
                </div>
                <div style={{ paddingTop: "16px" }}>
                  <ButtonDs type="primary" danger onClick={() => setIsConfirmDeleteModalOpen(true)}>
                    <DeleteOutlined />
                    ลบผู้ใช้งาน
                  </ButtonDs>
                </div>
              </Card>
            </div>
          </FormDs>
        )}
      </Content>
      <ModalPermissionDetail
        open={isModalPermissionDetailOpen}
        onCancel={() => setIsModalPermissionDetailOpen(false)}
      />
      <ConfirmModal
        open={isConfirmCancelModalOpen}
        title={
          <Space>
            <QuestionCircleOutlined /> ออกจากหน้านี้หรือไม่ ?
          </Space>
        }
        onClickConfirmCancel={() => setIsConfirmCancelModalOpen(false)}
        onClickConfirmOK={() => {
          setIsConfirmCancelModalOpen(false);
          navigate("/user-management");
        }}
      >
        <p>หากออกจากหน้านี้ ข้อมูลทั้งหมดจะไม่ถูกบันทึก ต้องการออกจากหน้านี้หรือไม่ ?</p>
      </ConfirmModal>
      <ConfirmModal
        open={isConfirmModalOpen}
        title={
          <Space>
            <QuestionCircleOutlined /> ยืนยันการเปลี่ยนแปลงข้อมูล
          </Space>
        }
        onClickConfirmCancel={() => setIsConfirmModalOpen(false)}
        onClickConfirmOK={onUpdateUser}
        loading={isUpdateUserLoading}
      >
        <div className="confirm-create-user-modal-content">
          <p>ยืนยันการเปลี่ยนแปลงข้อมูลของบัญชี</p>
          <p>{userInfo?.email} หรือไม่ ?</p>
        </div>
      </ConfirmModal>
      <ConfirmModal
        open={isConfirmDeleteModalOpen}
        title={
          <Space>
            <QuestionCircleOutlined /> ยืนยันการลบบัญชีผู้ใช้
          </Space>
        }
        onClickConfirmCancel={() => setIsConfirmDeleteModalOpen(false)}
        onClickConfirmOK={onDeleteUser}
        loading={isDeleteUserLoading}
      >
        <div className="confirm-create-user-modal-content">
          <p>ยืนยันการลบบัญชี {userInfo?.email} หรือไม่ ? </p>
          <p>หากลบผู้ใช้งาน ข้อมูลทั้งหมดจะถูกลบและไม่สามารถกู้คืนได้</p>
        </div>
      </ConfirmModal>
    </DesignSystemTheme>
  );
};

export default UpdateUser;
